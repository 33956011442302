import styled from "styled-components/macro";

export default styled.div`
  .footer {
    background-color: #424242;
  }
  .footer-container {
    width: 100%;
    display: grid;
    background-color: #424242;
    color: #ffffff;
    align-items: center;
    padding: 2rem 2rem 3rem 2rem;
  }

  .footer-container > div {
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
  }

  .footer-container > div > a {
    text-decoration: none;
    color: #ffffff;
  }

  .search-links {
    margin-top: 20px;
  }

  .footer-logo {
    width: 14%;
    height: 76px;
    float: left;
    padding: 2rem;
  }

  @media only screen and (max-width: 768px) {
    .footer-container {
      width: 100%;
    }
    .footer-container > div {
  
    }
    .footer-logo {
      width: 20%;
    }
  }

  @media only screen and (max-width: 480px) {
    .footer-container {
      width: 100%;
      padding-top: 1rem;
      margin: 0;
    }
    .footer-container > div {
      margin: 0;
    }
    .footer-logo {
      width: 50%;
      text-align: center;
      padding: 1rem;
      padding: 20px 40px;
      margin-left: 20%;
      margin-bottom: 20px;
    }
  }
  .lang-links a {
    color: #FACE00 !important;      
    font-wwight: 700 !important;
    padding: 0 15px;
  }
`;
