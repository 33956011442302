import {GET_FAQS_REQUEST, GET_FAQS_SUCCESS, GET_FAQS_ERROR} from '../actions/type';

const initialState = {
    loading: null,
    error: null,
    data: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
    switch(action.type) {
        case GET_FAQS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FAQS_SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null,
                loading: false,
                lastUpdated: action.lastUpdated
            };
        case GET_FAQS_ERROR:
            return {
                ...state,
                error: action.error,
                data: null,
                loading: false,
                lastUpdated: action.lastUpdated
            }
        default: 
        return initialState;
    }

}