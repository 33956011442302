import React, {useEffect} from 'react';
import Styled from './styles';
import TeachingResponsibilities from './TeachingResponsibilities';
import Community  from './Community';
import Publications from './Publications';
import * as actions from '../../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
const ProfessionalActivities = () => {
    const dispatch = useDispatch();

    const {state} = useLocation();
    const { physicianId } =state;
    const professionalActivities = useSelector(({professionalActivities}) => professionalActivities?.data?.data);
    const logger = useSelector(({ logger }) => logger?.data);
    const noDataMessage = useSelector(({physicianProfileInfo}) => physicianProfileInfo?.dataMessage);
   
    useEffect(() => {
        if (!logger?.sessionId) {
          dispatch(actions.fetchLogger());
        } else {
          dispatch(
            actions.fetchProfessionalActivities(
              { physicianId: physicianId },
              { sessionId: logger?.sessionId }
            )
          );
        }
      }, [dispatch, logger?.sessionId, physicianId]);


    return (
        <Styled>
            <TeachingResponsibilities medschool={professionalActivities?.medschool} noDataMessage={noDataMessage} />
            <Community community={professionalActivities?.community} noDataMessage={noDataMessage} />
            <Publications publication={professionalActivities?.publication} noDataMessage={noDataMessage} />
        </Styled>
    );
}

export default ProfessionalActivities;