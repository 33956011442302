import {GET_PROFESSIONAL_ACTIVITIES_REQUEST, 
    GET_PROFESSIONAL_ACTIVITIES_SUCCESS, 
    GET_PROFESSIONAL_ACTIVITIES_ERROR } from '../actions/type';


const fetchProfessionalActivities = (formData={},headers={}) => async (dispatch, getState, api) => {
    dispatch(fetchProfessionalActivitiesRequest());

    return api.fetchProfessionalActivities(formData,headers).then(data => {
        dispatch(fetchProfessionalActivitiesSuccess(data));
    }).catch(err => {
        dispatch(fetchProfessionalActivitiesError(err));
    })
}

export default fetchProfessionalActivities;

const fetchProfessionalActivitiesRequest = () => ({type: GET_PROFESSIONAL_ACTIVITIES_REQUEST});

const fetchProfessionalActivitiesSuccess = (response) => ({
    type: GET_PROFESSIONAL_ACTIVITIES_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const fetchProfessionalActivitiesError = (error) => ({
    type: GET_PROFESSIONAL_ACTIVITIES_ERROR,
    error,
    lastUpdated: Date.now(),
});