import "./App.css";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Physicians from "./Components/Pages/Physicians";
import Dictionary from './Components/Pages/Dictionary';
import FAQs from "./Components/Pages/FAQs";
import SearchTips from "./Components/Pages/SearchTips";
import Contact from "./Components/Pages/Contact";
import Feedback from "./Components/Pages/Feedback";
import PhysicianProfile from "./Components/Pages/PhysicianProfile";
import AdvancedSearch from "./Components/Pages/AdvancedSearch";
import SelectToPrint from "./Components/Pages/SelectToPrint";
import PrinterFriendlyPage from "./Components/Pages/PrinterFriendlyPage";
import HospitalList from "./Components/Pages/AdvancedSearch/MapSelect/HospitalList"; 
import MapSelect from "./Components/Pages/AdvancedSearch/MapSelect"; 
import LanguageAssistance from "./Components/Pages/LanguageAssistance";
import Analytics from "./Components/Pages/Analytics";

function App() {
  return (
    <>
    <Analytics />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="/physicians/:lastName" element={<Physicians />} />
        <Route path="/physicians/:lastName/:firstName" element={<Physicians />} />
        <Route path="/physicians" element={<Physicians />} />

        <Route path="/search-tips" element={<SearchTips />} />
        <Route path="/dictionary" element={<Dictionary />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/physician-profile" element={<PhysicianProfile />} />
        <Route path="/select-items-to-print" element={<SelectToPrint />} />
        <Route path="/printer-friendly" element={<PrinterFriendlyPage />} />
        <Route path="/language-assistance" element={<LanguageAssistance />} />
        
        <Route path="/advanced-search" element={<AdvancedSearch />} />
        <Route path="/advanced-search/hospitals" element={<HospitalList />} />
        <Route path="/advanced-search/county-map" element={<MapSelect />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
