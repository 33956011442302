// Using es5 for max compatibility, transpilers and extending builtin errors don't always play nice
function ApiError(message, errorTextKey, originalError, isNetworkError = false) {
  this.message = message;
  this.errorTextKey = errorTextKey;
  this.originalError = originalError;
  this.isNetworkError = isNetworkError;
  this.status = originalError?.response?.status;
  this.shouldLogOut = message !== 'Handled backend error' && this.status === 403;
}
ApiError.prototype = new Error();

export default ApiError;
