import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const HealthPrivileges = ({admittingPrivilege=[], noDataMessage}) => {
    const columns = [
        {name: 'hospitalName', label: '', width: '100'},
    ]

    return (
        <Styled>
            <TitleHeader label="Hospital Privileges" lastUpdatedDate={admittingPrivilege[0]?.informationDate} />
            {admittingPrivilege && admittingPrivilege.length > 0 ? (
                    <Table columns={columns} data={admittingPrivilege.map(p => {
                        let hospitalName = p.hospitalName;
                        if(!hospitalName) {
                            if(!p.responseIndc) {
                                hospitalName = 'The physician did not supply this information';
                            } else if(p.responseIndc === 'N') {
                                hospitalName = 'None';
                            } else {
                                hospitalName = noDataMessage;
                            }
                        }
                        return {...p, hospitalName: hospitalName};
                    })} />
            ) : (<p className='no-content'>{noDataMessage ? noDataMessage : 'None reported'}</p>)}
            <hr />
            {admittingPrivilege && admittingPrivilege.length > 0 && (<p>
                        <strong>Note:</strong> Call your health plan to see which hospitals you can use.
                    </p>)}
            
        </Styled>
    );
}

export default HealthPrivileges;