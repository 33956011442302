import React from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { MdOutlineArrowBack, MdOutlinePrint } from "react-icons/md";
import GoBackButton from "../GoBackButton";
import { Button } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import {
  navigateWithDataHandler,
  convertTagsToJSX,
} from "../../../../utils/Utils";
const Profile = ({ showHeaderButtons = true }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { physicianId } = state;
  const physicianInfo = useSelector(
    ({ physicianProfileInfo }) => physicianProfileInfo
  );

  const getPracticeLocation = () => {
    if (state?.statusCode !== "A"&&state?.statusCode) {
      return physicianInfo?.dataMessage;
    }
    const pracLocation =
      physicianInfo?.practiceLocations &&
      Array.isArray(physicianInfo?.practiceLocations)
        ? physicianInfo?.practiceLocations?.map(({ state, city }) => {
            const locaStr = city + ", " + state;
            return convertTagsToJSX(locaStr);
          })
        : null;
    if (pracLocation?.length < 1) {
      return "None reported";
    } else {
      return pracLocation;
    }
  };

  return (
    <Styled>
      <div className="header-block">
        <h1 className="title">{physicianInfo?.formattedFullName}</h1>
        {showHeaderButtons && (
          <div>
            <GoBackButton
              buttonContent={
                <>
                  <MdOutlineArrowBack />
                  <span>Back to Search Results</span>
                </>
              }
            />
            <Button
              onClick={navigateWithDataHandler(
                { physicianId: physicianId },
                navigate,
                "/select-items-to-print"
              )}
              className="btn-link font16px textTransform physician-profile-btn"
            >
              <span>Select Items to Print</span> &nbsp;
              <MdOutlinePrint />
            </Button>
          </div>
        )}
      </div>

      <div className="profile-detail paddingTop30px">
        <div>
          <div>
            <p>
              <strong>NYS License Number:</strong>
            </p>
            <p>{physicianInfo?.licenseNumber}</p>
          </div>
          <div>
            <p>
              <strong>Date of NYS Licensure:</strong>
            </p>
            <p>
              {physicianInfo?.licenseDate
                ? physicianInfo?.licenseDate.replace(/[-]/g, "/")
                : ""}
            </p>
          </div>
          <div>
            <p>
              <strong>National Provider ID:</strong>
            </p>
            <p>{physicianInfo?.nationalProviderId}</p>
          </div>
        </div>
        <div>
          <div>
            <p>
              <strong>Office Locations:</strong>
            </p>
            <p>{getPracticeLocation()}</p>
          </div>
          <div>
            <p>
              <strong>Last profile update:</strong>
            </p>
            <p>
              {physicianInfo?.lastUpdated
                ? physicianInfo?.lastUpdated.replace(/[-]/g, "/")
                : ""}
            </p>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Profile;
