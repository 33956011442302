import {FEEDBACK_REQUEST, 
    FEEDBACK_SUCCESS, 
    FEEDBACK_ERROR, 
    FEEDBACK_LIST_REQUEST, 
    FEEDBACK_LIST_SUCCESS, 
    FEEDBACK_LIST_ERROR,
    FEEDBACK_MASTER_REQUEST, 
    FEEDBACK_MASTER_SUCCESS, 
    FEEDBACK_MASTER_ERROR,
    FEEDBACK_FORM_REQUEST,} from '../actions/type';

const initialState = {
    loading: null,
    error: null,
    feedbackList: null,
    masterList: null,
    
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
    switch(action.type) {
        case FEEDBACK_FORM_REQUEST:
            return {
                ...state,
                loading: false,
                formData: {...action.data}
            }
        case FEEDBACK_REQUEST:
        case FEEDBACK_LIST_REQUEST:
        case FEEDBACK_MASTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                formData: null,
                response: {...action.data}
            }
        case FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feedbackList: action.data
            }
        case FEEDBACK_MASTER_SUCCESS:
            return {
                    ...state,
                    loading: false,
                    masterList: action.data
                }
        case FEEDBACK_ERROR:
        case FEEDBACK_LIST_ERROR:
        case FEEDBACK_MASTER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}