import Styled from "./styles";
const SubPageHeader = ({ titleBlue, titleBlack }) => {
  return (
    <Styled>
      <h1 className="sub-page-header-container" id="main">
        <div className="sub-headers">
          <div className="title-blue">{titleBlue}</div>
          <div className="title-black">{titleBlack}</div>
        </div>
      </h1>
    </Styled>
  );
};
export default SubPageHeader;
