import React, {useEffect} from 'react';
import DictionaryItem from './DictionaryItem';
// import dictionaries from `${process.env.PUBLIC_URL}/json_data/dictionaryList.json`;
import Styled from './styles';
import * as actions from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';

const DictionaryList = () => {
    const dispatch = useDispatch();
    const {data: dictionariesList} = useSelector(({dictionaries}) => dictionaries)
    const logger= useSelector(({ logger }) => logger?.data);
    
    useEffect(() => {
        if(!logger?.sessionId){
          dispatch(actions.fetchLogger());
        }else{
            dispatch(actions.fetchDictionaries(false,{sessionId:logger?.sessionId}));
        }
      }, [dispatch,logger?.sessionId]);
    

    return <Styled>
        {(dictionariesList || []).map(dict => <DictionaryItem key={dict.id} id={dict.id} title={dict.work} description={dict.description} />)}
    </Styled>
}

export default DictionaryList;