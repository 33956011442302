import styled from "styled-components/macro";

export default styled.div`
  label.field-label {
    padding-top: 10px;
    font-size:20px;
    color:#000000;
    margin-bottom: 5px;
  }
  .td-underline {
    text-decoration: underline !important;
  }

  .input {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .select {
    width: 50%;
    margin-top: 40px;
    border-radius: 4px;
    box-shadow: 4px 4px 6px 0 rgba(170, 167, 167, 0.5);
  }
  .label {
    width: 100%;
    font-size: 1.5rem;
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .textarea {
    width: 100%;
    margin: 20px 0;
    margin-left: 10px;
    box-shadow: 4px 4px 6px 0 rgba(170, 167, 167, 0.5);
    margin-bottom: 3px;
  }

  .menu-list.Mui-selected {
    background-color: #487ba4;
  }

  .MuiSelect-icon {
    color: #000000;
    width: 2rem;
    height: 2rem;
    top: calc(50% - 15px);
  }
  .text-margin {
    margin-top: 5px;
    margin-left: 10px;
  }
  .text-information {
    margin-left: 10px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .button-container > div {
    text-align: center;
  }

  .button {
    padding: 12px;
    color: #000000;
    width: 20%;
    font-size: 1.5rem;
    font-weight: 700;
    &.MuiButton-outlined {
      border: 2px solid #000000;
      border-radius: 0;
    }
    &.MuiButton-root {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .MuiButton-root {
    text-transform: none;
  }
  .form-group {
    margin-top: 20px;
  }

  .MuiIconButton-root {
    border-radius: none;
  }
  .MuiIconButton-label {
    color: #979797;
  }
  .MuiCheckbox-root {
    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .text-submit {
    color: #235d86;
  }
  .form-group,
  .checkbox-label > .MuiTypography-root {
    color: #000000;
    font-size: 20px;
  }
  .checkbox-info {
    padding-top: 10px;
  }

  @media only screen and (max-width: 971px) {
    .feedback-container {
      width: 100%;
    }
    .text-information,
    .textfield-container {
      width: 70%;
    }
    .text-margin {
      text-align: left;
    }
    .button {
      width: 40%;
    }
    .input-text {
      width: 100%;
    }
    .MuiFormControl-root {
        width: 80%;
      }
  }

  @media only screen and (max-width: 480px) {
    .MuiFormControl-root {
        width: 100%;
      }
    .feedback-container {
      width: 100%;
      margin: 10%;
    }
    .intro-text,
    .select,
    .text-information,
    .textfield-container,
    .button {
      width: 80%;
    }
    .intro-text {
      margin-left: 12%;
    }
    .select {
      margin-top: 50px;
    }
    .checkbox-info {
      margin-top: 10px;
    }
    .form-group {
      width: 80%;
    }
  }
  @media only screen and (min-width: 972px) {
    .input-text,
    .MuiFormControl-root {
      width: 80%;
    }
  }
  
  select {
    padding: 10.5px 10.5px;
  }
  .form-group {
    width: 80%;
  }
  .MuiInputLabel-outlined {
    -webkit-transform: translate(14px, 14px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    -webkit-transform: translate(14px, -6px) scale(0.75);
  }
`;
