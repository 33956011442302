import * as client from '../axios';
import endpoint from '../endpoint';
import { API_PROFILE_PATH } from '../../utils/Config';

async function fetchEducationInfo(formData={},headers={}) {

    const options = {
        params: {
          ...formData
        },
        headers:{
          ...headers
        }
      };
    const response = await client.get(`${API_PROFILE_PATH}${formData.physicianId}?sections=EDUCATIONALL`,options);
    return response.data;
}

export default endpoint(fetchEducationInfo, 'fetchEducationInfo');