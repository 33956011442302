import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Styled from "./styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import Modal from "../../../Common/Modal";
import * as actions from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
// import { TiTick } from 'react-icons/ti';

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#487BA4",
    },
  },
  margin: {
    // margin: theme.spacing(1),
  },
  selected: {
    backgroundColor: "#487BA4 !important",
    color: "#FFFFFF",
  },
}));

const FeedbackForm = () => {
  const dispatch = useDispatch();
  // const feedbackListData = useSelector(
  //   (state) => state?.feedback?.feedbackList
  // );
  const masterListData = useSelector((state) => state?.feedback?.masterList);
  const logger = useSelector(({ logger }) => logger?.data);

  useEffect(() => {
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      dispatch(
        actions.getFeedbackList(false, { sessionId: logger?.sessionId })
      );
      dispatch(
        actions.getFeedbackMasterList(false, { sessionId: logger?.sessionId })
      );
    }
  }, [dispatch, logger?.sessionId]);

  const classes = useStyles();
  const [feedbackFormData, setFeedbackFormData] = React.useState({});
  const [inactiveFeedbackFormData, setInactiveFeedbackFormData] = React.useState({});
  const [activeSection, setActiveSection] = React.useState(0);
  const [isConsumerTypeOther, setIsConsumerTypeOther] = React.useState(false);
  const [isHowLocatedOther, setIsHowLocatedOther] = React.useState(false);
  const [isPrimaryReasonOther, setIsPrimaryReasonOther] = React.useState(false);
  const [isWhatInformationOther, setIsWhatInformationOther] = React.useState(false);
  const [isWhereInformationOther, setIsWhereInformationOther] = React.useState(false);
  const [isHowSuccessfulOther, setIsHowSuccessfulOther] = React.useState(false);
  const [isPhysicianPrimaryReasonOther, setIsPhysicianPrimaryReasonOther] = React.useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const type = event.target.type;
    // let value = '';
    let formData = "";
    let checkboxArray = [];
    if (type === "checkbox") {

      if (!Array.isArray(feedbackFormData[name])) {
        checkboxArray = [];
      }
      else {
        checkboxArray = [...feedbackFormData[name]];
      }

      let index = checkboxArray.indexOf(event.target.value);
      if (index === -1) {
        checkboxArray.push(event.target.value);
      }
      else {
        checkboxArray.splice(index, 1);
      }
      setFeedbackFormData({
        ...feedbackFormData,
        [name]: [...checkboxArray],
      });

    } else {
      formData = { ...feedbackFormData, [name]: event.target.value };
      setFeedbackFormData({ ...feedbackFormData, [name]: event.target.value });
    }

    // selection of section based on consumer type selected
    if (name === "consumerType" && (["CNSADVPA", "NYEMP", "OT"]?.indexOf(event.target.value) > -1)) {
      setActiveSection(1);
      setInactiveFeedbackFormData({ ...feedbackFormData, [name]: event.target.value });
      setFeedbackFormData({ ...inactiveFeedbackFormData, [name]: event.target.value });
    }
    else if (name === "consumerType" && (["NYLPHY"]?.indexOf(event.target.value) > -1)) {
      setActiveSection(2);
      setInactiveFeedbackFormData({ ...feedbackFormData, [name]: event.target.value });
      setFeedbackFormData({ ...inactiveFeedbackFormData, [name]: event.target.value });
    }
    else if (name === "consumerType" && event.target.value === "-1") {
      setActiveSection(0);
      setInactiveFeedbackFormData({});
      setFeedbackFormData({});
    }

    // If 'Other' is selected and textbox needs to be open
    // consumer type
    if (name === "consumerType" && event.target.value === "OT" && isConsumerTypeOther === false) {
      setIsConsumerTypeOther(true);
    }
    else if (name === "consumerType" && event.target.value !== "OT") {
      setIsConsumerTypeOther(false);
    }

    // howlocated - isHowLocatedOther
    if (name === "referType" && event.target.value === "OT" && isHowLocatedOther === false) {
      setIsHowLocatedOther(true);
    }
    else if (name === "referType" && event.target.value !== "OT") {
      setIsHowLocatedOther(false);
    }

    // primaryReason - isPrimaryReasonOther
    if (name === "primaryReason" && event.target.value === "OT" && isPrimaryReasonOther === false) {
      setIsPrimaryReasonOther(true);
    }
    else if (name === "primaryReason" && event.target.value !== "OT") {
      setIsPrimaryReasonOther(false);
    }

    // whatInformation - isWhatInformationOther
    if (name === "whatInformation" && event.target.value === "OT" && isWhatInformationOther === false) {
      setIsWhatInformationOther(true);
    }
    else if (name === "whatInformation" && (checkboxArray.indexOf("OT") <= -1)) {
      setIsWhatInformationOther(false);
    }

    // whereInformation - isWhereInformationOther
    if (name === "whereInformation" && event.target.value === "OT" && isWhereInformationOther === false) {
      console.log("where information true");
      setIsWhereInformationOther(true);
    }
    else if (name === "whereInformation" && (checkboxArray.indexOf("OT") <= -1)) {
      console.log("where information false");
      setIsWhereInformationOther(false);
    }

    // howSuccessful - isHowSuccessfulOther - NOTSUCC
    if (name === "howSuccessful" && (event.target.value === "NOTSUCC" || event.target.value === "PARSUCC") && isHowSuccessfulOther === false) {
      setIsHowSuccessfulOther(true);
    }
    else if (name === "howSuccessful" && (event.target.value !== "PARSUCC" && event.target.value !== "NOTSUCC") && isHowSuccessfulOther === true) {
      setIsHowSuccessfulOther(false);
    }

    // physicianPrimaryReason - isPhysicianPrimaryReasonOther
    if (name === "physicianPrimaryReason" && event.target.value === "OT" && isPhysicianPrimaryReasonOther === false) {
      setIsPhysicianPrimaryReasonOther(true);
    }
    else if (name === "physicianPrimaryReason" && event.target.value !== "OT") {
      setIsPhysicianPrimaryReasonOther(false);
    }

    dispatch(actions.saveFeedbackForm(formData));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // setShowModal(true);
    handleClickOpen();

    dispatch(actions.saveFeedback(feedbackFormData));
  };

  return (
    <Styled>
      <div className="feedback-containers">
        <div className="intro-text">
          {open && (
            <Modal
              open={open}
              handleClose={handleClose} 
              textData={{
                dialogText: "Submitted!",
                modalText: "Thank you for your feedback.",
              }}
              modalBtn={{ btnText: "HOME", path: "/" }}
            />
          )}
          <div>
            We would appreciate your feedback regarding the clarity and
            organization of the profiles website, as well as your ability
            to find the doctor's information on-line.
          </div>
          <div>
            After filling in your responses, please click on the{" "}
            <span className="text-submit">Submit </span> button below to
            complete the questionnaire.
          </div>
        </div>

        {/* Q1 */}
        <div className="input paddingTop30px">
          <FormControl className={classes.margin} fullWidth>
            <InputLabel
              className="label"
              for="consumer-type-label"
              shrink="false"
            >
              Please select the category of web site user that best describes
              you.
            </InputLabel>

            <Select
              name="consumerType"
              labelId="consumerType"
              id="consumerType"
              type="dropdown"
              defaultValue={"Please Select"}
              value={feedbackFormData?.consumerType || "-1"}
              onChange={handleChange}
              input={<BootstrapInput id="consumer-type-label" />}
              className="select"
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem
                className="menu-list"
                value="-1"
                classes={{ selected: classes.selected }}
              >
                Please Select
              </MenuItem>
              {getQuestionOptions(masterListData?.surveyQuestions, "USER_CATEGORY")?.map((item) => (
                <MenuItem
                  selected={
                    feedbackFormData?.consumerType === item.categoryCode
                  }
                  classes={{ selected: classes.selected }}
                  value={item.code}
                  key={item.code}
                >
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {
          isConsumerTypeOther && <div className="input paddingTop30px">
            <FormControl className={classes.margin}>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                Please specify the category
              </InputLabel>

              <TextField
                for="comments"
                name="userCategoryOtherText"
                className="textarea"
                variant="outlined"
                placeholder="Other Consumer Type"
                type="text"
                value={feedbackFormData?.userCategoryOtherText || ""}
                onChange={handleChange}
                inputProps={{ maxLength: 1000 }}
              />
            </FormControl>
          </div>
        }
        {/* end Q1 */}

        {activeSection === 1 && <>

          {/* Q2 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="first-visit-label"
                shrink="false"
              >
                Is this your first visit to the New York State Physician Profile?
              </InputLabel>

              <Select
                name="firstVisit"
                labelId="firstVisit"
                id="firstVisit"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.firstVisit || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="first-visit-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "FIRST_VISIT")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.consumerType === item.categoryCode
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* end Q2 */}

          {/* Q3 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="how-located-label"
                shrink="false"
              >
                How did you find out about this website?
              </InputLabel>

              <Select
                name="referType"
                labelId="referType"
                id="howLocated"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.referType || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="how-located-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "HOW_LOCATED")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.referType === item.categoryCode
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {
            isHowLocatedOther && <div className="input paddingTop30px">
              <FormControl className={classes.margin}>
                <InputLabel
                  className="label"
                  for="consumer-type-label"
                  shrink="false"
                >
                  Please specify
                </InputLabel>

                <TextField
                  for="comments"
                  name="locationOtherText"
                  className="textarea"
                  variant="outlined"
                  placeholder="Other"
                  type="text"
                  value={feedbackFormData?.locationOtherText || ""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
            </div>
          }
          {/* end Q3 */}

          {/* Q4 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="county-fips-code-label"
                shrink="false"
              >
                What county or borough do you live in?
              </InputLabel>

              <Select
                name="countyFipsCode"
                labelId="countyFipsCode"
                id="countyFipsCode"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.countyFipsCode || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="county-fips-code-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {masterListData?.county?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.consumerType === item.categoryCode
                    }
                    classes={{ selected: classes.selected }}
                    value={item.countyFipsCode}
                    key={item.countyFipsCode}
                  >
                    {item.countyNameText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* end Q4 */}

          {/* Q5 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                What is the primary reason for your visit today?
              </InputLabel>

              <Select
                name="primaryReason"
                labelId="primaryReason"
                id="primaryReason"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.primaryReason || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="primary-reason-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "PRIMARY_REASON")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.consumerType === item.categoryCode
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {
            isPrimaryReasonOther && <div className="input paddingTop30px">
              <FormControl className={classes.margin}>
                <InputLabel
                  className="label"
                  for="consumer-type-label"
                  shrink="false"
                >
                  Please specify
                </InputLabel>

                <TextField
                  for="comments"
                  name="primaryReasonOtherText"
                  className="textarea"
                  variant="outlined"
                  placeholder="Other Primary Reason"
                  type="text"
                  value={feedbackFormData?.primaryReasonOtherText || ""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
            </div>
          }
          {/* end Q5 */}

          {/* Q6 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                When searching for a physician, what information is important to you in making a decision?
              </InputLabel>

              <FormGroup className="form-group">
                <div className="checkbox-info">
                  <i>Please check all that apply</i>
                </div>
                {getQuestionOptions(masterListData?.surveyQuestions, "WHAT_INFORMATION")?.map((item) => (
                  <FormControlLabel
                    className="checkbox-label"
                    type="checkbox"
                    control={
                      <Checkbox
                        checked={feedbackFormData["whatInformation"]?.indexOf(item.code) > -1 || false}
                        onChange={handleChange}
                        name={"whatInformation"}
                      />
                    }
                    value={item?.code}
                    label={item?.value}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          {
            isWhatInformationOther && <div className="input paddingTop30px">
              <FormControl className={classes.margin}>
                <InputLabel
                  className="label"
                  for="consumer-type-label"
                  shrink="false"
                >
                  Please specify
                </InputLabel>

                <TextField
                  for="comments"
                  name="whatInformationOtherText"
                  className="textarea"
                  variant="outlined"
                  placeholder="Other Information"
                  type="text"
                  value={feedbackFormData?.whatInformationOtherText || ""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
            </div>
          }
          {/* end Q6 */}

          {/* Q7 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                Where do you usually get this information? 
              </InputLabel>

              <FormGroup className="form-group">
                <div className="checkbox-info">
                  <i>Please check all that apply</i>
                </div>
                {getQuestionOptions(masterListData?.surveyQuestions, "WHERE_INFORMATION")?.map((item) => (
                  <FormControlLabel
                    className="checkbox-label"
                    type="checkbox"
                    control={
                      <Checkbox
                        checked={feedbackFormData["whereInformation"]?.indexOf(item.code) > -1 || false}
                        onChange={handleChange}
                        name={"whereInformation"}
                      />
                    }
                    value={item?.code}
                    label={item?.value}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          {
            isWhereInformationOther && <div className="input paddingTop30px">
              <FormControl className={classes.margin}>
                <InputLabel
                  className="label"
                  for="consumer-type-label"
                  shrink="false"
                >
                  Please specify
                </InputLabel>

                <TextField
                  for="comments"
                  name="whereInformationOtherText"
                  className="textarea"
                  variant="outlined"
                  placeholder="Other Information"
                  type="text"
                  value={feedbackFormData?.whereInformationOtherText || ""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
            </div>
          }
          {/* end Q7 */}

          {/* Q8 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="how-successful-label"
                shrink="false"
              >
                How successful were you in finding the information you were looking for?
              </InputLabel>

              <Select
                name="howSuccessful"
                labelId="howSuccessful"
                id="howSuccessful"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.howSuccessful || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="how-successful-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "HOW_SUCCESSFUL")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.howSuccessful === item.code
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {
            isHowSuccessfulOther && <div className="input paddingTop30px">
              <FormControl className={classes.margin}>
                <InputLabel
                  className="label"
                  for="consumer-type-label"
                  shrink="false"
                >
                  Please specify
                </InputLabel>

                <TextField
                  for="comments"
                  name="successExplainText"
                  className="textarea"
                  variant="outlined"
                  placeholder="Reason for Not/Partial successful"
                  type="text"
                  value={feedbackFormData?.successExplainText || ""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
            </div>
          }
          {/* end Q8 */}

          {/* Q9 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
              >
                Please rate your experience with the Physician Profile in each of the following areas:
              </InputLabel>
            </FormControl>
          </div>
          {/* 9.1 */}
          <div>
            <FormControl className={classes.margin} fullWidth>
              <div className="checkbox-info">
                <i>Ease of searching for a physician</i>
              </div>

              <Select
                name="easeSearchingPhysician"
                labelId="easeSearchingPhysician"
                id="easeSearchingPhysician"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.easeSearchingPhysician || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {[1, 2, 3, 4, 5]?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.easeSearchingPhysician === item
                    }
                    classes={{ selected: classes.selected }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* 9.2 */}
          <div>
            <FormControl className={classes.margin} fullWidth>
              <div className="checkbox-info">
                <i>Ease of navigating the site</i>
              </div>

              <Select
                name="easeNavigatingSite"
                labelId="easeNavigatingSite"
                id="easeNavigatingSite"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.easeNavigatingSite || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {[1, 2, 3, 4, 5]?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.easeNavigatingSite === item
                    }
                    classes={{ selected: classes.selected }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* 9.3 */}
          <div>
            <FormControl className={classes.margin} fullWidth>
              <div className="checkbox-info">
                <i>Quality of the content</i>
              </div>

              <Select
                name="qualityContent"
                labelId="qualityContent"
                id="qualityContent"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.qualityContent || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {[1, 2, 3, 4, 5]?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.qualityContent === item
                    }
                    classes={{ selected: classes.selected }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* 9.4 */}
          <div>
            <FormControl className={classes.margin} fullWidth>
              <div className="checkbox-info">
                <i>Amount of content</i>
              </div>

              <Select
                name="amountContent"
                labelId="amountContent"
                id="amountContent"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.amountContent || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {[1, 2, 3, 4, 5]?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.amountContent === item
                    }
                    classes={{ selected: classes.selected }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* 9.5 */}
          <div>
            <FormControl className={classes.margin} fullWidth>
              <div className="checkbox-info">
                <i>Overall Experience</i>
              </div>

              <Select
                name="overallExperience"
                labelId="overallExperience"
                id="overallExperience"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.overallExperience || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {[1, 2, 3, 4, 5]?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.overallExperience === item
                    }
                    classes={{ selected: classes.selected }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* end Q9 */}

          {/* Q10 */}
          <div className="input paddingTop30px">
            <div className="text-information">
              Please use the space below to provide any additional comments or suggestions for the NYS Physician Profile.  Please include an email address or telephone number if you would like a direct response to your comments.  We thank you for your feedback, it is invaluable in assisting us to improve the public website.
            </div>
            <div className="textfield-container">
              <TextField
                for="comments"
                name="comments"
                className="textarea"
                variant="outlined"
                id="comments"
                label="Additional comments"
                placeholder=""
                multiline
                rows={4}
                type="text"
                value={feedbackFormData.comments || ""}
                onChange={handleChange}
                inputProps={{ maxLength: 1000 }}
              />
              <br />
              <span className="text-margin">
                <b>{feedbackFormData.comments ? feedbackFormData.comments.length : 0}/1000 characters</b>
              </span>
            </div>
          </div>
          {/* end Q10 */}
          {/* end activesection 1 */}
        </>}

        {activeSection === 2 && <>
          {/* Q11 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                What is the primary reason for your visit today?
              </InputLabel>

              <Select
                name="physicianPrimaryReason"
                labelId="physicianPrimaryReason"
                id="physicianPrimaryReason"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.physicianPrimaryReason || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "PHYSICIAN_PRIMARY_REASON")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.physicianPrimaryReason === item.code
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {
            isPhysicianPrimaryReasonOther && <div className="input paddingTop30px">
              <FormControl className={classes.margin}>
                <InputLabel
                  className="label"
                  for="consumer-type-label"
                  shrink="false"
                >
                  Please specify
                </InputLabel>

                <TextField
                  for="comments"
                  name="physicianPrimaryReasonOtherText"
                  className="textarea"
                  variant="outlined"
                  label="Category"
                  placeholder=""
                  type="text"
                  value={feedbackFormData?.physicianPrimaryReasonOtherText || ""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
            </div>
          }
          {/* end Q11 */}

          {/* Q12 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                Do you recommend this site to your patients for more information on a referral/recommendation?
              </InputLabel>

              <Select
                name="recommendSite"
                labelId="recommendSite"
                id="recommendSite"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.recommendSite || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "FIRST_VISIT")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.recommendSite === item.code
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* end Q12 */}

          {/* Q13 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin}>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                Do you have any recommendations for the Department of Health to reduce the burden of maintaining your profile?
              </InputLabel>

              <TextField
                for="comments"
                name="recommendationsReducePhysicianBurden"
                className="textarea"
                variant="outlined"
                placeholder="recommendations"
                type="text"
                value={feedbackFormData?.recommendationsReducePhysicianBurden || ""}
                onChange={handleChange}
                inputProps={{ maxLength: 1000 }}
              />
            </FormControl>
          </div>
          {/* end Q13 */}

          {/* Q14 */}
          <div className="input paddingTop30px">
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                className="label"
                for="consumer-type-label"
                shrink="false"
              >
                Do you think it would be helpful if the Department of Health included health plan participation information on your profile?
              </InputLabel>

              <Select
                name="healthPlanInfoHelpful"
                labelId="healthPlanInfoHelpful"
                id="healthPlanInfoHelpful"
                type="dropdown"
                defaultValue={"Please Select"}
                value={feedbackFormData?.healthPlanInfoHelpful || "-1"}
                onChange={handleChange}
                input={<BootstrapInput id="consumer-type-label" />}
                className="select"
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem
                  className="menu-list"
                  value="-1"
                  classes={{ selected: classes.selected }}
                >
                  Please Select
                </MenuItem>
                {getQuestionOptions(masterListData?.surveyQuestions, "HEALTH_PLAN_INFO_HELPFUL")?.map((item) => (
                  <MenuItem
                    selected={
                      feedbackFormData?.healthPlanInfoHelpful === item.code
                    }
                    classes={{ selected: classes.selected }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* end Q14 */}

          {/* Q15 */}
          <div className="input paddingTop30px">
            <div className="text-information">
              Please use the space below to provide any additional comments or suggestions for the NYS Physician Profile.  Please include an email address or telephone number if you would like a direct response to your comments.  We thank you for your feedback, it is invaluable in assisting us to improve the public website.
            </div>
            <div className="textfield-container">
              <TextField
                for="comments"
                name="physicianComments"
                className="textarea"
                variant="outlined"
                id="comments"
                label="Additional comments"
                placeholder=""
                multiline
                rows={4}
                type="text"
                value={feedbackFormData?.physicianComments || ""}
                onChange={handleChange}
                inputProps={{ maxLength: 1000 }}
              />
              <br />
              <span className="text-margin">
                <b>{feedbackFormData?.physicianComments ? feedbackFormData?.physicianComments.length : 0}/1000 characters</b>
              </span>
            </div>
          </div>
          {/* end Q15 */}
          {/* end activesection 2 */}
        </>}

        <div className="button-container">
          <Button
            className="button"
            size="large"
            variant="outlined"
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </Styled>
  );
};

export default FeedbackForm;

// Helpers
const getQuestionOptions = (questions = [], typeCode = '') => {
  if (questions.length < 1) return [];
  return (questions.filter(q => q.typeCode === typeCode))[0]?.surveyQuestionOptions;
}
