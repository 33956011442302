import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";
import Table from "../../Table";

const OutOfStateActions = ({ outStateInfo = [], noDataMessage }) => {
  const columns = [
    { name: "actionDate", label: "Date" },
    { name: "stateCode", label: "State" },
    { name: "finalActionText", label: "Action" },
  ];
  let summaryMsg='';

  // const checkIfNoContent = (data) => {
  //     let noActionMessage = '';

  //     if(!data.actionDate && !data.stateCode && !data.finalActionText && !data.actionSummary) {
  //         if(!data.responseIndc) {
  //             noActionMessage = 'The physician did not supply this information';
  //         } else if(data.responseIndc === 'N') {
  //             noActionMessage = 'None';
  //         } else {
  //             noActionMessage = noDataMessage ? noDataMessage : 'None reported';
  //         }
  //         return [false, noActionMessage];
  //     } else {
  //         return [true, noActionMessage];
  //     }
  // }
  const getOutStateInfoData = () => {
    return outStateInfo.map((data,i) => {
      let noActionMessage = "";

      if (
        !data.actionDate &&
        !data.stateCode &&
        !data.finalActionText &&
        !data.actionSummary
      ) {
        if (!data.responseIndc) {
          noActionMessage = "The physician did not supply this information";
        } else if (data.responseIndc === "N") {
          noActionMessage = "None";
        } else {
          noActionMessage = noDataMessage ? noDataMessage : "None reported";
        }
        return noActionMessage;
      }
      if(i===0){
        summaryMsg=data?.actionSummary;
      }
      
      return { ...data};
    });
  };
  const outStateInfoData = getOutStateInfoData();
  return (
    <Styled>
      <TitleHeader label="Out-of-State Actions" lastUpdatedDate={outStateInfo[0]?.informationDate} />
      {outStateInfoData && outStateInfoData.length > 0 ? (
        typeof outStateInfoData[0] === "string" ? (
          <p className="no-content">{outStateInfoData[0]}</p>
        ) : (
          <>
            <Table columns={columns} data={outStateInfoData} />
          </>
        )
      ) : (
        <p className="no-content">
          {noDataMessage ? noDataMessage : "None reported"}
        </p>
      )}
      <hr />
      {summaryMsg&&summaryMsg!==''&&
        (<p>
          <strong>Summary: </strong> {summaryMsg}
        </p>)}
    </Styled>
  );
};

export default OutOfStateActions;
