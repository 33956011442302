import React from 'react';
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import HeroImage from "./HeroImage";
import PhysicianSearch from './PhysicianSearch';
import AboutPhysician from './AboutPhysician'
import LowerBar from "../../Common/LowerBar";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styles from './styles'
const Home = () => {


  return (
    <Styles>
      <Header />
      <HeroImage />
      <PhysicianSearch />
      <AboutPhysician />
      <LowerBar />
      <Footer />
    </Styles>
  );
};
export default ScrollToTop(Home);
