import styled from "styled-components/macro";

export default styled.div`
  .call-us {
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    line-height: 35px;
    padding-top: 15px;
  }
  .call-us > a {
    color: #235d86 !important;
  }
  .call-time {
    padding: 30px 0 25px 0;
    font-size: 24px;
  }
`;
