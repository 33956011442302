import styled from "styled-components/macro";

export default styled.div`
  .section-container {
    padding: 38px 83px 49px 83px;
  }
  p.info-text {
    font-size: 18px;
  }
  div.search-tip-container{
    padding: 48px 0 0 0;
  }
  div.search-term{
    font-size: 24px;
    color: #235D86;
    font-weight: 600;
  }
  div.search-sol{
    font-size: 18px;
    color:#000000;
  }
  div.search-sol ul{
    padding: 10px 60px;
  }
  div.search-sol ul>li:before {
    content:"· ";
    font-size: 40px;
    vertical-align: text-top;
    line-height:20px;
    margin-left: -20px;
}
  div.search-sol ul>li{
    padding: 5px 0;
    list-style:none;
  }
  div.search-tip-list-div{
    padding-bottom: 25px
  }
  @media only screen and (max-width: 991px) {
    .section-container {
      padding: 38px 50px 49px 50px;
    }
  }
`;
