const initialCheck = [
  {
    type: "checkbox",
    name: "cbMedicaid",
    value: "N",
    label: "Medicaid",
  },
  {
    type: "checkbox",
    name: "cbMedicare",
    value: "N",
    label: "Medicare",
  },
  {
    type: "checkbox",
    name: "cbHiv",
    value: "N",
    label: "HIV Services",
  },
];

export const initialState = {
  restrictChkStateChange: 0,
  counties: null,
  specialities: null,
  subSpecialities: null,
  modalOpen:false,
  //form handler states
  firstName: null,
  lastName: null,
  licenseNumber: null,
  hospital: null,
  city: null,
  selectedCounty: null,
  selectedSpeciality: null,
  selectedSubSpeciality: null,
  serviceSearchCbList: initialCheck
 
};
export function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_FIELD":
      if (action?.fieldData?.name === "speciality") {
        return { ...state, selectedSpeciality: action?.fieldData?.value };
      } else if (action?.fieldData?.name === "subSpeciality") {
        return { ...state, selectedSubSpeciality: action?.fieldData?.value };
      } else if (action?.fieldData?.name === "county") {
        return { ...state, selectedCounty: action?.fieldData?.value };
      } else {
        return {
          ...state,
          [action?.fieldData?.name]: action?.fieldData?.value,
        };
      }

    case "UPDATE_CHECKBOX_FIELD":
      return { ...state, serviceSearchCbList: action?.updatedArray };
    case "RETURN_PAGE_STATE_UPDATE":
      return { ...action?.returnState };

    case "UPDATE_STATE_WITH_BASIC_SEARCH":
      return { ...state, ...action?.returnState };

    case "RESTRICT_CHECKBOX":
      return { ...state, restrictChkStateChange: action?.flag };
    case "GET_API_DATA":
      return { ...state, [action?.dataType]: action?.data };
      case "MODAL_OPEN_CLOSE":
        return { ...state, modalOpen: !state?.modalOpen };
    case "RESET_FORM":
      return {
        ...state,
        firstName: null,
        lastName: null,
        hospital: null,
        city: null,
        licenseNumber: null,
        selectedCounty: null,
        selectedSpeciality: null,
        selectedSubSpeciality: null,
        serviceSearchCbList: initialCheck,
      };

    default:
      return state;
  }
}
