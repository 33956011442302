import React, { useEffect } from "react";
import Styled from "./styles";
import MedicalInfo from "./MedicalInfo";
import GraduateMedicalSchool from "./GraduateMedicalSchool";
import BoardCertification from "./BoardCertification";
import ProfessionalMembership from "./ProfessionalMembership";
import * as actions from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const EducationalInfo = () => {
  const dispatch = useDispatch();
  const {state} = useLocation();
  const { physicianId } =state;
  const educationInfo = useSelector(
    ({ educationInfo }) => educationInfo?.data?.data
  );
  const logger = useSelector(({ logger }) => logger?.data);

  const noDataMessage = useSelector(({physicianProfileInfo}) => physicianProfileInfo?.dataMessage);
  

  useEffect(() => {
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      dispatch(
        actions.fetchEducationInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
    }
  }, [dispatch, logger?.sessionId, physicianId]);
  return (
    <Styled>
      <MedicalInfo medicalInfo={educationInfo?.medSchools} noDataMessage={noDataMessage} />
      <GraduateMedicalSchool gmeSchools={educationInfo?.gmeSchools} noDataMessage={noDataMessage} />
      <BoardCertification
        boardCertification={educationInfo?.boardCertification}
        boardSubcertifications={educationInfo?.boardSubcertifications}
        noDataMessage={noDataMessage}
      />
      <ProfessionalMembership profMember={educationInfo?.profMember} noDataMessage={noDataMessage} />
    </Styled>
  );
};

export default EducationalInfo;
