import styled from "styled-components/macro";

export default styled.div`
.lang-as hr {
    height: 1px;
    background-color: rgb(151, 151, 151);
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 490px;
  }
  @media only screen and (max-width: 415px) {
    .lang-as img {
      width: 100%;
      height: auto;
    }
  }
 
`;
