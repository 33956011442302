import React from 'react';
import Styled from './styles';
import TitleHeader from '../TitleHeader';
import Table from '../../Table';

const GraduateMedicalSchool = ({gmeSchools=[], noDataMessage}) => {
    const columns = [
        {name: 'amahospital', label: 'Sponsor', width: '40', linkType: "internal", linkPath: "/dictionary#21"},
        {name: 'gmeStartDate', label: 'Start Date', width: '15'},
        {name: 'gmedate', label: 'Completion Date', width: '15'},
        {name: 'completionIndc', label: 'Completed', width: '15'},
        {name: 'specialty', label: 'Speciality', width: '15'}
    ]

    return (
        <Styled>
            <TitleHeader label="Graduate Medical Education" linkType="internal" linkPath="/dictionary#11" lastUpdatedDate={gmeSchools[0]?.informationDate}/>
            {gmeSchools && gmeSchools.length > 0 ? <Table columns={columns} data={gmeSchools} /> : 
            <p className='no-content'>{noDataMessage ? noDataMessage : 'No Data'}</p>}
            <hr />
            {gmeSchools != null && (<p>
                <strong>Note:</strong> This information cannot be used by healthcare organizations to meet their credentialing requirements as set forth by accrediting bodies such as the Joint Commission or National Committee for Quality Assurance (NCQA).
            </p>)}
        </Styled>
    );
}

export default GraduateMedicalSchool;