import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";
import Styled from "./styles";
import { convertTagsToJSX } from "../../../utils/Utils";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function Modal({ open, handleClose, textData = {}, modalBtn = {} }) {
  const navigate = useNavigate();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
      fullWidth
    >
      <Styled className="dialog-container">
        <DialogTitle
          id="dialog-title"
          onClose={handleClose}
          className="dialog-title"
        >
          {textData?.dialogText}
        </DialogTitle>
        <DialogContent className="dialog-content">
          <div className="modal-container">
            <Typography gutterBottom className="colorBlack font18px">
              {convertTagsToJSX(textData?.modalText)}
            </Typography>
            {modalBtn?.btnText && (
              <div className="button-container">
                <Button
                  className="button"
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    navigate(modalBtn?.path);
                  }}
                >
                  {modalBtn?.btnText}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Styled>
    </Dialog>
  );
}

export default Modal;
