import {SET_SELECTED_ITEMS_TO_PRINT} from '../actions/type';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=null, action) => {
    switch(action.type) {
        case SET_SELECTED_ITEMS_TO_PRINT:
            return {
                ...action.selectedItems
            }
        default:
            return state;
    }
}