import Styled from "./styles.js";
import AccordionItem from "./Accordion";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import React, { useEffect, useState } from "react";
import BackToTop from "./BackToTop";
const FAQs = () => {
  const [faqs, setFAQs] = useState([]);
  const properties = {
    titleBlack: "Questions",
    titleBlue: "FREQUENTLY ASKED",
  };
  useEffect(() => {
    fetch("/json_data/faqs.json").then((data) => {
      data.json().then((jsonData) => {
        setFAQs(jsonData);
      });
    });
  }, []);
  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          <AccordionItem />
          <h2 className="colorBlue font24px paddingTop20px fontW600">Notes</h2>
          <div className="paddingTop20px">
            {faqs.map(({ key, note, description }) => (
              <div key={key}>
                <h3 className="colorBlue font24px fontW400">{note}</h3>
                <p className="font18px paddingBot20px">{description}</p>
              </div>
            ))}
          </div>
          <BackToTop />
        </BodyContent>
      </Styled>
    </SubPage>
  );
};
export default ScrollToTop(FAQs);
