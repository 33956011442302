import React, { useEffect, useReducer, useRef } from "react";
import {
  TextField,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import Styled from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { onSubmitHandlerCommon } from "../../../../utils/Utils";
import { fetchDataCommon } from "../../../../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";
import { reducer, initialState } from "./reducer";
import ReturnToPage from "../../../Common/ReturnToPage";
import Modal from "../../../Common/Modal";
let modalText='';
const AdvancedSearchForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const mapRef = useRef(null);

  const dispatch = useDispatch();

  const logger = useSelector(({ logger }) => logger?.data);
  const [advancedSState, dispatchState] = useReducer(reducer, initialState);
  const toggleModal = () => {
    dispatchState({ type: "MODAL_OPEN_CLOSE" });
  };
  const onSubmitHandler = (e) => {
    const { uri, emptySearch } = onSubmitHandlerCommon(e);
    if (emptySearch) {
      const { firstName } = advancedSState;
      if (firstName) {
           modalText="To search for physicians by name do not search on first name only.  A last name must also be entered.  Searching on first name only would return too many results.";
      
          } else {
            modalText="Advanced search requires one or more fields: <br> Last Name, Specialty, Hospital, License Number, County, City, or Services.";
      }
      toggleModal();
    } else {
      navigate(`/physicians/${uri}`, {
        state: {
          prevPageState: advancedSState,
          prevPath: window.location.pathname,
        },
      });
    }
  };
  const handleChangeChkBox = (event, index) => {
    const newArray = [];
    for (let i = 0; i < advancedSState?.serviceSearchCbList.length; i++) {
      if (i === index) {
        newArray.push({
          ...advancedSState?.serviceSearchCbList[i],
          value: event.target.checked ? "Y" : "N",
        });
      } else {
        newArray.push(advancedSState?.serviceSearchCbList[i]);
      }
    }
    dispatchState({ type: "UPDATE_CHECKBOX_FIELD", updatedArray: newArray });
  };

  useEffect(() => {
    const getAdvSearchApiDatas = async (parmaData) => {
      const response = await fetchDataCommon(
        "/advanced-search-params",
        parmaData,
        { sessionId: logger?.sessionId }
      );
      if (!response?.data) {
        throw new Error("Data coud not be fetched!");
      } else {
        switch (parmaData?.paramType) {
          case "counties":
            dispatchState({
              type: "GET_API_DATA",
              data: response?.data,
              dataType: "counties",
            });
            break;
          case "speciality":
            dispatchState({
              type: "GET_API_DATA",
              data: response?.data,
              dataType: "specialities",
            });
            break;
          case "subSpeciality":
            dispatchState({
              type: "GET_API_DATA",
              data: response?.data,
              dataType: "subSpecialities",
            });
            break;
          default:
            break;
        }
      }
    };
    // get specialities & sub specialities
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      if (advancedSState?.selectedSpeciality) {
        getAdvSearchApiDatas({
          paramType: "subSpeciality",
          speciality: advancedSState?.selectedSpeciality,
        });
      } else {
        getAdvSearchApiDatas({ paramType: "counties" });
        getAdvSearchApiDatas({ paramType: "speciality" });
      }
    }

    dispatchState({ type: "RESTRICT_CHECKBOX", flag: 1 });
  }, [dispatch, logger?.sessionId, advancedSState?.selectedSpeciality]);
  useEffect(() => {
    const newArray = [];
    if (advancedSState?.restrictChkStateChange === 0) {
      for (let i = 0; i < advancedSState?.serviceSearchCbList.length; i++) {
        if (
          advancedSState?.serviceSearchCbList[i]?.value !==
          state?.[advancedSState?.serviceSearchCbList[i]?.name]
        ) {
          newArray.push({
            ...advancedSState?.serviceSearchCbList[i],
            value: state?.[advancedSState?.serviceSearchCbList[i]?.name],
          });
        } else {
          newArray.push(advancedSState?.serviceSearchCbList[i]);
        }
      }
      dispatchState({ type: "UPDATE_CHECKBOX_FIELD", updatedArray: newArray });
    }
  }, [
    state,
    advancedSState?.serviceSearchCbList,
    advancedSState?.restrictChkStateChange,
  ]);
  const onResetClick = () => {
    dispatchState({ type: "RESET_FORM" });
  };

  const onClickMapOrListHandler = (type, listView = false) => {
    if (listView === true) {
      navigate("hospitals", { state: { prevPageState: advancedSState } });
    } else {
      navigate("county-map", {
        state: { prevPageState: advancedSState, pageType: type },
      });
    }
  };

  useEffect(() => {
    if (state?.updateState) {
      if (state?.fromPage === "basic-search") {
        dispatchState({
          type: "UPDATE_STATE_WITH_BASIC_SEARCH",
          returnState: state?.["prevPageState"],
        });
      } else {
        dispatchState({
          type: "RETURN_PAGE_STATE_UPDATE",
          returnState: state?.["prevPageState"],
        });
      }
    }
  }, [state]);

  const onChangeHandler = (e) => {
    dispatchState({ type: "UPDATE_FIELD", fieldData: e.target });
  };
  return (
    <Styled ref={mapRef}>
      {advancedSState?.modalOpen && (
        <Modal
          open={advancedSState?.modalOpen}
          handleClose={toggleModal}
          textData={{
            dialogText: "More information needed!",
            modalText:modalText
              ,
          }}
        />
      )}
      <form onSubmit={onSubmitHandler}>
        <div className="colorBlue font24px fontW700">
          Physician Profile information continues to be collected and reviewed.
        </div>
        <p className="font20px">
          {" "}
          Fill in at least one of the following. You may fill in as many as you
          like. <br></br> Click on the <strong>Search</strong> button to see
          results. (For help, see{" "}
          <a href="/search-tips" className="colorBlue fontW700">
            Search Tips.
          </a>
          )
        </p>
        <div className="paddingTop30px"></div>
        <p className="font20px">
          <strong>Note:</strong> If you search by county or borough or city, you
          may not get a complete list of doctors, as some doctors did not report
          their office locations.
        </p>

        <div className="form-container">
          <div className="paddingTop30px colorBlue font24px fontW700">
            Physician Search Criteria
          </div>
          <div className="paddingTop20px"></div>
          <Grid container spacing={0} id="#search">
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  <InputLabel className="field-label" for="lastName">
                    Last Name
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <TextField
                    id="lastName"
                    variant="outlined"
                    className="input-text"
                    name="lastName"
                    size="small"
                    value={advancedSState?.lastName}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop20px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  <InputLabel className="field-label" for="firstName">
                    First Name
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <TextField
                    id="firstName"
                    variant="outlined"
                    className="input-text"
                    name="firstName"
                    size="small"
                    value={advancedSState?.firstName}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop20px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  <InputLabel className="field-label">Specialty</InputLabel>
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <FormControl variant="outlined" label="Specialty">
                    <Select
                      className="colorGrey"
                      native
                      name= "speciality"
                      onChange={onChangeHandler}
                      inputProps={{ 'aria-label': 'Specialty' }}

                    >
                      <option aria-label="None" value="000">
                        Any
                      </option>
                      {advancedSState?.specialities?.map(
                        ({ value, description }) => (
                          <option
                            aria-label="None"
                            value={value}
                            selected={
                              advancedSState?.selectedSpeciality === value
                                ? "selected"
                                : false
                            }
                          >
                            {description}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          {advancedSState?.subSpecialities &&
            advancedSState?.subSpecialities?.length !== 0 && (
              <>
                <div className="paddingTop20px"></div>
                <Grid container spacing={0}>
                  <Grid item xs={12} lg={12} sm={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} lg={2} sm={3} className="font20px">
                        <InputLabel className="field-label">
                          Subspecialty (optional)
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} lg={8} sm={9}>
                        <FormControl variant="outlined" label="Subspecialty (optional)">
                          <Select
                            className="colorGrey"
                            native
                            name="subSpeciality"
                            onChange={onChangeHandler}
                            inputProps={{ 'aria-label': 'Subspecialty (optional)' }}
                          >
                            <option aria-label="None" value="000">
                              Any
                            </option>
                            {advancedSState?.subSpecialities?.map(
                              ({ value, description }) => (
                                <option
                                  aria-label="None"
                                  value={value}
                                  selected={
                                    advancedSState?.selectedSubSpeciality ===
                                    value
                                      ? "selected"
                                      : false
                                  }
                                >
                                  {description}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={2}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

          <div className="paddingTop20px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  <InputLabel className="field-label" for="hospital">
                    Hospital{" "}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <TextField
                    id="hospital"
                    variant="outlined"
                    className="input-text"
                    name="hospital"
                    size="small"
                    value={advancedSState?.hospital}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop10px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px"></Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <div className="font20px">
                    Select a hospital from a{" "}
                    <Button
                      className="colorBlue font20px btn-link textUnderlined"
                      onClick={() => onClickMapOrListHandler("hospital", true)}
                    >
                      list
                    </Button>
                    ,{" "}
                    <Button
                      className="colorBlue font20px btn-link textUnderlined"
                      onClick={() => onClickMapOrListHandler("hospital")}
                    >
                      map
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop20px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  <InputLabel className="field-label" for="licenseNumber">
                    License Number{" "}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <TextField
                    id="licenseNumber"
                    variant="outlined"
                    className="input-text"
                    name="licenseNumber"
                    size="small"
                    value={advancedSState?.licenseNumber}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop30px colorBlue font24px fontW700">
            Office Location Search Criteria
          </div>
          <div className="paddingTop20px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  County / Borough
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <FormControl variant="outlined" label="County / Borough">
                    <Select
                      className="colorGrey"
                      native
                      name= "county"
                      onChange={onChangeHandler}
                      inputProps={{ 'aria-label': 'County / Borough' }}
                    >
                      <option aria-label="None" value="000">
                        Any
                      </option>
                      {advancedSState?.counties?.map(
                        ({ value, description }) => (
                          <option
                            aria-label="None"
                            value={value}
                            selected={
                              advancedSState?.selectedCounty === value
                                ? "selected"
                                : false
                            }
                          >
                            {description}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop10px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px"></Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <div className="font20px">
                    Select a county from a{" "}
                    <Button
                      className="colorBlue font20px btn-link textUnderlined"
                      onClick={() => onClickMapOrListHandler("county")}
                    >
                      map
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop20px"></div>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={2} sm={3} className="font20px">
                  <InputLabel className="field-label" for="city">
                    City
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={8} sm={9}>
                  <TextField
                    id="city"
                    variant="outlined"
                    className="input-text"
                    name="city"
                    size="small"
                    value={advancedSState?.city}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="paddingTop30px colorBlue font24px fontW700">
            Services Search Criteria
          </div>
          <FormGroup className="form-group">
            {advancedSState?.serviceSearchCbList?.map((item, index) => (
              <FormControlLabel
                className="checkbox-label"
                type="checkbox"
                control={
                  <Checkbox
                    name={item?.name}
                    checked={item?.value === "Y" ? true : false}
                    onChange={(e) => handleChangeChkBox(e, index)}
                  />
                }
                value={item?.value}
                label={item?.label}
              />
            ))}
          </FormGroup>
          <div className="paddingTop30px">
            <Button
              className="colorBlue btn-link"
              type="reset"
              onClick={onResetClick}
            >
              Clear Search
            </Button>
          </div>
          <div className="button-container paddingTop30px">
            <Button
              className="button"
              size="large"
              variant="outlined"
              type="submit"
            >
              SEARCH
            </Button>
          </div>
          <div className="paddingTop30px">
            <ReturnToPage
              linkPath={"/"}
              linkText={"Go Back to Previous Page"}
              stateData={{
                prevPageState: {
                  firstName: advancedSState?.firstName,
                  lastName: advancedSState?.lastName,
                },
                updateState: true,
              }}
            />
          </div>
        </div>
      </form>
    </Styled>
  );
};
export default AdvancedSearchForm;
