import styled from "styled-components/macro";

/* 
  This file was created to have generic styling for all of the Physician Profile fields.
  These styles are redfined in PrinterFriendlyPage/styles.js under the .items-to-print class.
  NOTE: There are a lot of empty style.js files but we cannot delete them because some of these 
  fields need their own styles. If we delete some but not all, it will disrupt this flow of 
  CSS child selectors.

  -Andrew 10/7/2022
*/

export default styled.div`
  .no-content {
    background-color: #eaeae8;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
  }

  > div > div > hr {
    height: 1px;
    background-color: #979797;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > div > div > p {
    font-size: 0.85rem;
    padding: 0 15px;
    margin-top: 10px;
  }

  > div > div {
    margin-bottom: 30px;
  }
`;
