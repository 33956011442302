import * as client from '../axios';
import endpoint from '../endpoint';
import { API_PATH } from '../../utils/Config';
async function feedback(formData={},headers={}) {
    // const options = {
    //     params: {
    //       ...formData
    //     },
    //     headers:{
    //         'content-type':'application/json',
    //       ...headers
    //     }
    //   };
      const options = {
        headers:{
            'content-type':'application/json',
          ...headers
        }
      };
      console.log("calling feedback api",`${API_PATH}/feedback`, formData, headers);
     const response = await client.post(`${API_PATH}/feedback`,formData,options);
     // const response = {
    //     "status": 200,
    //     "message": null,
    //     "errors": null,
    //     "backend": "NYPP",
    //     "data": {
    //             "consumerType": "CNSR",
    //             "referType": "DOHW",
    //             "countyFipsCode": "002",
    //             "cbSelect": "Y",
    //             "cbReview": null,
    //             "cbOther": null,
    //             "rbUseful": "V",
    //             "rbLookingfor": "Y",
    //             "cbEducation": "Y",
    //             "cbBoard": null,
    //             "cbLocation": null,
    //             "cbMalpractice": null,
    //             "cbLicense": null,
    //             "cbConvications": null,
    //             "cbOther1": null,
    //             "rbDesign": "V",
    //             "comments": "test"
    //         }
    //     };
    
    return response;
}

export default endpoint(feedback, 'feedback');