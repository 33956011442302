import React, { useEffect, useState } from "react";
import SubPage from "../../../../Common/SubPage";
import BodyContent from "../../../../Common/BodyContent";
import ScrollToTop from "../../../../Common/HOC/ScrollToTop";
import Styled from "./styles";
import { fetchDataCommon } from "../../../../../api/endpoints";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "../../../../../redux/actions";
import HospitalLinks from "./HospitalLinks";
import ReturnToPage from "../../../../Common/ReturnToPage";
import ProgressLoader from "../../../../Common/Loading/ProgressLoader";

const HospitalList = () => {
  const properties = {
    titleBlack: "Search",
    titleBlue: "ADVANCED",
  };
  const { state } = useLocation();
  const [hospitalList, setHospitalList] = useState();
  const logger = useSelector(({ logger }) => logger?.data);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getHospitalListData = async () => {
      const params = {
        paramType: "hospitals",
      };
      if (state?.dataObj?.CountyFipsCodeParam) {
        params["county"] = state?.dataObj?.CountyFipsCodeParam;
      }

      setShowLoader(true);
      const response = await fetchDataCommon(
        "/advanced-search-params",
        params,
        { sessionId: logger?.sessionId }
      );
      if (!response?.data) {
        setShowLoader(false);
        throw new Error("Data coud not be fetched!");
      } else {
        setShowLoader(false);
        return response;
      }
    };
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      getHospitalListData()
        .then((res) => {
          setHospitalList(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [logger?.sessionId, dispatch, state]);
  const handlerNavigation = (hospitalObj) => {
    navigate("/advanced-search", {
      state: {
        prevPageState: {
          ...state?.["prevPageState"],
          hospital: hospitalObj?.hospitalName,
        },
        updateState: true,
      },
    });
  };

  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          {showLoader ? (
            <ProgressLoader size={60} />
          ) : (
            <>
              <div className="form-heading">
                <HospitalLinks
                  hospitalList={hospitalList}
                  county={state?.dataObj?.CountyFipsCodeParam}
                  handlerNavigation={handlerNavigation}
                />
              </div>
              <div className="paddingTop30px"></div>
              <ReturnToPage
                linkPath={"/advanced-search"}
                linkText={"Return to Search"}
                stateData={{ prevPageState: state?.prevPageState,updateState:true }}
              />
              <div className="paddingTop20px"></div>
              <ReturnToPage
                linkText={"Return to Map"}
                linkPath={"/advanced-search/county-map"}
                stateData={{
                  prevPageState: state?.prevPageState,
                  pageType: "hospital",
                }}
              />
            </>
          )}
        </BodyContent>
      </Styled>
    </SubPage>
  );
};
export default ScrollToTop(HospitalList);
