import { LOGGER_REQUEST, LOGGER_SUCCESS, LOGGER_ERROR } from "./type";
import { uniqueToken } from "../../utils/Utils";
const fetchLogger = () => async (dispatch, getState, api) => {
  dispatch(fetchLoggerRequest());
     const token=uniqueToken();
     try{
        if(token){
            dispatch(fetchLoggerSuccess(token));
           }
     }catch(err){
        dispatch(fetchLoggerError(err));
     }
};

export default fetchLogger;

const fetchLoggerRequest = () => ({ type: LOGGER_REQUEST });

const fetchLoggerSuccess = (token) => ({
  type: LOGGER_SUCCESS,
  data: { sessionId: token },
  lastUpdated: Date.now(),
});

const fetchLoggerError = (error) => ({
  type: LOGGER_ERROR,
  error,
  lastUpdated: Date.now(),
});
