import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const Publications = ({publication=[], noDataMessage}) => {
    const columns = [
        {name: 'articleTitleText', label: 'Article', width: '50'},
        {name: 'publicationText', label: 'Journal', width: '40'},
        {name: 'publicationDate', label: 'Date', width: '10'},
    ];

    let updatedDataMessage = noDataMessage;

    if(!updatedDataMessage || !(updatedDataMessage === 'Profile under Development' || updatedDataMessage === "Dr.'s survey not received")) {
        updatedDataMessage= 'The physician did not supply this information';
    }

    return (
        <Styled>
            <TitleHeader label="Publications (Optional)" lastUpdatedDate={publication[0]?.informationDate} />
            {
                publication && publication.length > 0 ? (
                      <Table columns={columns} data={publication.map(p => 
                        ({...p, publicationDate: p.publicationDate ? 
                            p.publicationDate.slice(p.publicationDate.length-4) : p.publicationDate}))} />
                ) : (
                    <p className="no-content">{updatedDataMessage}</p>
                )
            }
            
            <hr />
        </Styled>
    );
}

export default Publications;