import { format, subYears } from "date-fns";
export function getEffectiveDateFrom(dateFormat = false) {
  let defFormat = "MM/dd/yyyy";
  return format(subYears(new Date(), 10), defFormat);
}
export function convertTagsToJSX(contentWithHtmlTags) {
  return <div dangerouslySetInnerHTML={{ __html: contentWithHtmlTags }} />;
}
export function uniqueToken() {
  const dateStr = Date.now();
  let r = (Math.random() + 1).toString(36).substring(7);
  return r + dateStr;
}
export function onSubmitHandlerCommon(e) {
  e.preventDefault();
  const formData = new FormData(e.target);
  const formObj = {};
  let formStr = "";
  let emptySearch = true;
  const selectInp = ["county", "speciality", "subSpeciality"];
  for (const [key, value] of formData) {
    if (selectInp.includes(key)) {
      if (value !== "000") {
        emptySearch = false;
      }
    } else {
      if (value) {
        emptySearch = false;
      }
    }
    formObj[key] = value;
    const andOp = formStr === "" ? "" : "&";
    formStr +=
      key === "firstName" || key === "lastName"
        ? ""
        : `${andOp}${key}=${value}`;
  }
  let uriSegments = "";
  if (emptySearch === false) {
    if (formObj["firstName"] && !formObj["lastName"]) {
      emptySearch = true;
      return { emptySearch: emptySearch };
    }
  }
  if (formObj["firstName"]) {
    uriSegments = `${formObj["lastName"]}/${formObj["firstName"]}`;
  } else {
    uriSegments = `${formObj["lastName"]}`;
  }
  const finalUri = `${uriSegments}?${formStr}`;

  return { uri: finalUri, emptySearch: emptySearch };
}
export function throttle(func, timeFrame) {
  let lastTime = 0;
  return function (...args) {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}
export function navigateWithDataHandler(JSONdata, navigate, path) {
  return () => {
    navigate(path, { state: JSONdata });
  };
}
export function seoTool() {
  if (window.location.pathname === "/") {
    document.title = "New York State Physician Profile";
  } else {
    const pathSegments = window.location.pathname.split("/");
    const titleArray = [];
    function capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    for (let i = 0; i < pathSegments.length; i++) {
      if (pathSegments[i] !== "") {
        titleArray.push(pathSegments[i].split("-").map(capitalize).join(" "));
      }
    }
    document.title = titleArray.join(" | ");
  }
}
