import React from "react";
import Styled from "./styles";
import TitleHeader from "../TitleHeader";

const MedicalInfo = ({ medicalInfo = [], noDataMessage }) => {
  return (
    <Styled>
      <TitleHeader label="Medical School" lastUpdatedDate={medicalInfo[0]?.informationDate} />
      {medicalInfo && medicalInfo.length > 0 ? (
        <p classname="uni">
          {medicalInfo?.map((medSchool, i) => (
            <h5
              key={`key-${i}`}
            >{`${medSchool?.schoolName}, ${medSchool?.gradDate}`}</h5>
          ))}
        </p>
      ) : (
        <p className="no-content">
          {noDataMessage
            ? noDataMessage
            : `No medical schools reported for this physician.`}
        </p>
      )}
      <hr />
      {medicalInfo != null && (
        <p>
          <strong>Note:</strong> This information cannot be used by healthcare
          organizations to meet their credentialing requirements as set forth by
          accrediting bodies such as the Joint Commission or National Committee
          for Quality Assurance (NCQA).
        </p>
      )}
    </Styled>
  );
};

export default MedicalInfo;
