import * as client from '../axios';
import endpoint from '../endpoint';
import { API_PATH } from '../../utils/Config';

async function masterList(formData={},headers={}) {
    const options = {
        params: {
          ...formData
        },
        headers:{
          ...headers
        }
      }; 
    // /api/v1/feedback/masterlist
    const response = await client.get(`${API_PATH}/feedback/questionnaire`,options);

    return response.data;
}

export default endpoint(masterList, 'masterList');