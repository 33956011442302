import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";
import Table from "../../Table";

const CurrentLimitations = ({ currentLimitationsInfo = [], noDataMessage }) => {
  const columns = [
    { name: "stateCode", label: "State", width: "10" },
    { name: "effectiveDate", label: "Effective Date", width: "15" },
    { name: "limitationDescription", label: "Limitation", width: "75" },
  ];

  // const checkIfNoContent = (data) => {
  //     let limitationMessage = data.limitationDescription;
  //     if(!data.stateCode && !data.effectiveDate && !data.limitationDescription) {
  //         if(!data.responseIndc) {
  //             limitationMessage = 'The physician did not supply this information';
  //         } else if(data.responseIndc === 'N') {
  //             limitationMessage = 'None';
  //         } else {
  //             limitationMessage = noDataMessage ? noDataMessage : 'None reported';
  //         }
  //         return [false, limitationMessage];
  //     } else {
  //         return [true, limitationMessage];
  //     }
  // }
  const getCurrLimitInfo = () => {
    return currentLimitationsInfo.map((data) => {
      let limitationMessage = data.limitationDescription;
      if (
        !data.stateCode &&
        !data.effectiveDate &&
        !data.limitationDescription
      ) {
        if (!data.responseIndc) {
          limitationMessage = "The physician did not supply this information";
        } else if (data.responseIndc === "N") {
          limitationMessage = "None";
        } else {
          limitationMessage = noDataMessage ? noDataMessage : "None reported";
        }
        return limitationMessage;
      }
      return { ...data, limitationDescription: limitationMessage };
    });
  };
  const currLimitInfo = getCurrLimitInfo();
  return (
    <Styled>
      <TitleHeader label="Current Limitations or Conditions" lastUpdatedDate={currentLimitationsInfo[0]?.informationDate} />
      {currLimitInfo && currLimitInfo.length > 0 ? (
        typeof currLimitInfo[0] === "string" ? (
          <p className="no-content">{currLimitInfo[0]}</p>
        ) : (
          <>
            <Table columns={columns} data={currLimitInfo} />
          </>
        )
      ) : (
        <p className="no-content">
          {noDataMessage ? noDataMessage : "None reported"}
        </p>
      )}
      <hr />
    </Styled>
  );
};

export default CurrentLimitations;
