import styled from "styled-components/macro";

/* 
  There are a lot of empty style.js files but we cannot delete them because some of these 
  fields need their own styles (like this one). If we delete some but not all, it will disrupt this flow of 
  CSS child selectors. Generic styles were moved to AdditionalInfo/styles.js.

  -Andrew 10/7/2022
*/

export default styled.div`
  .address-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px 15px;
    font-size: 0.85rem;

    &:nth-of-type(even) {
      background-color: #eaeae8;
    }
  }
`;
