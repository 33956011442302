import styled from "styled-components/macro";

export default styled.div`
margin-bottom: 40px;
    .MuiTabs-root {
        background-color: #235D86;
        color: #FFFFFF;
    }

    .MuiTabs-indicator {
        background-color: #FACE00;
        height: 7px;
    }
`;