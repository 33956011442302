import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const InsuranceProgram = ({insuranceProgram=[], noDataMessage}) => {
    const columns = [
        {name: 'programName', label: 'Program', width: '50'},
        {name: 'participtionIndc', label: 'Does the doctor accept this insurance?', width: '50'},
    ]
    return (
        <Styled>
            <TitleHeader label="Medicaid and Other Government Insurance Programs" lastUpdatedDate={insuranceProgram[0]?.informationDate} />
            {insuranceProgram && insuranceProgram.length > 0 ? (
                    <Table columns={columns} data={insuranceProgram.map(p => {
                        let programName = p.otherProgramName ? p.otherProgramName : p.programName;
                        let participtionIndc = p.participtionIndc;
                        if(participtionIndc === 'Y') {
                            participtionIndc = 'At all locations';
                        } else if(participtionIndc === 'S') {
                            participtionIndc = 'At some locations';
                        } else if(participtionIndc === 'N') {
                            participtionIndc = 'At no locations';
                        }
                        return {...p, programName: programName, participtionIndc: participtionIndc};
                    })} />
            ): <p className='no-content'>{noDataMessage ? noDataMessage : 'None reported'}</p>}
            <hr />
            {insuranceProgram && insuranceProgram.length > 0 && <p>
                        <strong>Note: </strong> Contact the doctor's office to see if this information has changed.
                    </p>}
            
        </Styled>
    );
}

export default InsuranceProgram;