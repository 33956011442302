import {GET_DICTIONARY_LIST_REQUEST, 
    GET_DICTIONARY_LIST_SUCCESS, 
    GET_DICTIONARY_LIST_ERROR} from '../actions/type';



const fetchDictionaryList = (formData={},headers={}) => async (dispatch, getState, api) => {

    dispatch(fetchDictionaryListRequest());
    return api.fetchDictionaries(formData,headers).then(data => {
        dispatch(fetchDictionaryListSuccess(data));
    }).catch(err => {
        dispatch(fetchDictionaryError(err));
    })
}
export default fetchDictionaryList;

const fetchDictionaryListRequest = () => ({type: GET_DICTIONARY_LIST_REQUEST});

const fetchDictionaryListSuccess = (response) => ({
    type: GET_DICTIONARY_LIST_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const fetchDictionaryError = (error) => ({
    type: GET_DICTIONARY_LIST_ERROR,
    error,
    lastUpdated: Date.now(),
});