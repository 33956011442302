import "./Styles.css";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const AboutPhysician = () => {
  return (
    <div className="about-physician-container">
      <div className="text-about">ABOUT THE</div>
      <div className="text-state-physician">
        New York State Physician Profile
      </div>
      <div className="text-description">
        This website was created by the New York Patient Health Information and
        Quality Improvement Act of 2000 to enable citizens of New York to access
        information about doctors who practice medicine in the state.
      </div>
      <div className="about-physician-grid">
        <div>
          <div className="grid-header">
            Information Available <br /> about All Doctors
          </div>
          <div className="grid-text">
            The following information is required to be available in every
            doctor’s profile:
          </div>
          <div className="grid-text">
            <ul>
              <li>
                The doctor’s <Link to="/dictionary#14">medical education</Link>
              </li>
              <li>Translation services available at the doctor’s office</li>
              <li>Legal actions that have been taken against the doctor</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="grid-header">Optional Information</div>
          <div className="grid-text">
            Doctors may offer the following additional information in their
            profiles:
          </div>
          <div className="grid-text">
            <ul>
              <li>Practice name, address and phone number of listed offices</li>
              <li>The names of other doctors in the practice</li>
              <li>
                A list of the doctor’s published articles and research papers
              </li>
              <li>
                A list of professional and community service activities and
                awards
              </li>
              <li>A list of health plans in which the doctor participates</li>
              <li>
                A personal statement about information in the doctor’s profile
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="grid-header">
            Do You Have a Complaint <br /> about a Doctor?
          </div>
          <div className="grid-text">
            To file a complaint, contact the Office of Professional Medical
            Conduct at <br /> 1-800-663-6114 (Monday through Friday, 8:00 a.m.
            to 5:00 p.m. Eastern Time) or click on the box below to obtain a complaint form.
          </div>
          <div className="complaint-button-container">
            <Button
              className="complaint-button"
              size="large"
              variant="outlined"
              href="https://www.health.ny.gov/professionals/doctors/conduct/file_a_complaint.htm"
              target="_blank"
              rel="noreferrer"
            >
              COMPLAINT FORM
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutPhysician;
