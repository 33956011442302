import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const FieldOfMedicine = ({medicalPractice=[], noDataMessage}) => {
    const columns1 = [
        {name: 'practiceAreaText', label: 'Field', width: '50'},
        {name: 'primary', label: 'Primary Field of Practice', width: '50'},
    ]

    return (
        <Styled>
            <TitleHeader label="Field of Medicine" lastUpdatedDate={medicalPractice[0]?.informationDate} />
            {medicalPractice && medicalPractice.length > 0 ? 
            <Table columns={columns1} data={medicalPractice.map
            (m => ({...m, primary: m.primary === 'N' ? 'No' : 'Yes'}))} /> : 
            <p className='no-content'>{noDataMessage ? noDataMessage : 'None reported'}</p>}
            <hr />
            
        </Styled>
    );
}

export default FieldOfMedicine;