import { post } from "../axios";
import endpoint from "../endpoint";
import { API_PATH } from '../../utils/Config';

async function fetchPhysicians(formData,headers) {
  let response;
  const options = {
    headers:{
      'content-type':'application/json',
      ...headers
    }
  };
    response = await post(`${API_PATH}/physicians`,formData,options);
  
  return response?.data;
}

export default endpoint(fetchPhysicians, "fetchPhysicians");
