import {SET_PHYSICIAN_PROFILE_DATA} from '../actions/type';

const initialState = {
    loading: null,
    error: null,
    data: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
    switch(action.type) {
        case SET_PHYSICIAN_PROFILE_DATA:
            return {
                ...(action.data || {})
                
            }
        default:
            return state;
    }
}