import React, { useEffect } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineArrowBack } from "react-icons/md";
import GoBackButton from "../../GoBackButton";
import * as actions from "../../../../../redux/actions";
import { useLocation } from "react-router-dom";
import { getEffectiveDateFrom } from "../../../../../utils/Utils";

const InActiveProfile = ({ showHeaderButtons = true }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { physicianId } = state;
  const {
    terminationMessage,
    terminationType,
    formattedFullName,
    licenseNumber,
    licenseDate,
    lastName,
  } = useSelector(({ physicianProfileInfo }) => physicianProfileInfo);
  const logger = useSelector(({ logger }) => logger?.data);

  useEffect(() => {
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      dispatch(
        actions.fetchEducationInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
    }
  }, [dispatch, logger?.sessionId, physicianId]);

  const effectiveDateFrom = getEffectiveDateFrom();
  return (
    <Styled>
      <div className="header-block">
        <h1 className="title">{formattedFullName}</h1>
        {showHeaderButtons && (
          <div>
            <GoBackButton
              buttonContent={
                <>
                  <MdOutlineArrowBack />
                  <span>Back to Search Results</span>
                </>
              }
            />
          </div>
        )}
      </div>

      <div className="profile-detail paddingTop30px">
        <div>
          <div>
            <p className="headname">
              <strong>NYS License Number:</strong>
            </p>
            <p>{licenseNumber}</p>
          </div>
          <br></br>
          <div>
            <p className="headname">
              <strong>Date of NYS Licensure:</strong>
            </p>
            <p>{licenseDate ? licenseDate.replace(/[-]/g, "/") : ""}</p>
          </div>
          <div className="paddingTop30px">
            <p className="headname">
              <strong>{terminationType}:</strong>
            </p>
            <p>
              {terminationMessage}
              <br></br>
              For additional detail, click on the Office of Professional Medical
              Conduct link below <br></br>
              <a
                className="colorBlue"
                href={`https://apps.health.ny.gov/pubdoh/professionals/doctors/conduct/factions/ByPhysician?SearchView&Query=(FIELD+PhysicianName=${lastName}+AND+FIELD+WebLicense=${licenseNumber}+AND+FIELD+EffectiveDateFrom=${effectiveDateFrom})`}
                target="opmc_window"
              >
                OPMC
              </a>
            </p>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default InActiveProfile;
