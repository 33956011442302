import styled from "styled-components/macro";

export default styled.div`

    > div: first-of-type {
        margin-bottom: 50px;
    }

    .printer-friendly-btn {
        outline: none;
        background: none;
        border: 2px solid #000000;
        padding: 10px 20px;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
    }

    > .content {
        max-width: 850px;
        margin-bottom: 40px;
        > p: first-of-type {
            margin-bottom: 10px;
        }

        .colored-text {
            color: #235D86;
        }
    }
`;