import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";
import Table from "../../Table";

const CriminalConvictions = ({
  criminalConvictionInfo = [],
  noDataMessage,
}) => {
  const columns = [
    { name: "convictionDate", label: "Date", width: "15" },
    { name: "offense", label: "Offense", width: "85" },
  ];

  let someContent =true;
  //   criminalConvictionInfo && criminalConvictionInfo.length > 0
  //     ? criminalConvictionInfo.some((c) => c.convictionDate || c.offense)
  //     : null;
  const getUpdCCInfo = () => {
    return criminalConvictionInfo.map((data) => {
      let convictionMessage = data.offense;
      if (!data.convictionDate && !data.offense) {
        someContent = false;
        if (!data.responseIndc) {
          convictionMessage = "The physician did not supply this information";
        } else if (data.responseIndc === "N") {
          convictionMessage = "None";
        } else {
          convictionMessage = noDataMessage ? noDataMessage : "None reported";
        }
        return convictionMessage;
      } else {
        return data;
      }
    });
  };
  const updCCInfo = getUpdCCInfo();

  return (
    <Styled>
      <TitleHeader label="Criminal Convictions" lastUpdatedDate={criminalConvictionInfo[0]?.informationDate} />
      {updCCInfo && updCCInfo.length > 0 ? (
        typeof updCCInfo[0] === "string" ? (
          <p className="no-content">{updCCInfo[0]}</p>
        ) : (
          <>
            <Table columns={columns} data={updCCInfo} />
          </>
        )
      ) : (
        <p className="no-content">
          {noDataMessage ? noDataMessage : "None reported"}
        </p>
      )}
      <hr />
      {someContent && (
        <p>
          <strong>Note:</strong> Contact the physician's office for more
          information.
        </p>
      )}
    </Styled>
  );
};

export default CriminalConvictions;
