import {LOGGER_REQUEST, 
    LOGGER_SUCCESS, 
    LOGGER_ERROR} from '../actions/type';

const initialState = {
    loading: null,
    error: null,
    data: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
    switch(action.type) {
        case LOGGER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOGGER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data
            }
        case LOGGER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}