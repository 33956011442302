import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";
import Table from "../../Table";

const TeachingResponsibilities = ({ medschool = [], noDataMessage }) => {
  const columns = [
    { name: "schoolName", label: "Institution", width: "50" },
    { name: "beginDate", label: "Beginning Date ", width: "25" },
    { name: "endDate", label: "End Date", width: "25" },
  ];

  const getMedSchoolData = () => {
    return medschool.map((data) => {
      let schoolName = data.schoolName;
      if (!schoolName && data.otherSchoolName) {
        schoolName = data.otherSchoolName;
      }

      if (!schoolName || schoolName === "") {
        if (!data.responseIndc) {
          schoolName = "The physician did not supply this information";
        } else if (data.responseIndc === "N") {
          schoolName = "None";
        } else {
          schoolName = noDataMessage ? noDataMessage : "None Reported";
        }
        return schoolName;
      } else {
        return { ...data, schoolName: schoolName };
      }
    });
  };
  const updMedSchData = getMedSchoolData();
  return (
    <Styled>
      <TitleHeader label="Teaching Responsibilities" lastUpdatedDate={medschool[0]?.informationDate} />
      {updMedSchData && updMedSchData.length > 0 ? (
        typeof updMedSchData[0] === "string" ? (
          <p className="no-content">{updMedSchData[0]}</p>
        ) : (
          <>
            <Table columns={columns} data={updMedSchData} />
          </>
        )
      ) : (
        <p className="no-content">
          {noDataMessage ? noDataMessage : "None reported"}
        </p>
      )}

      <hr />
    </Styled>
  );
};

export default TeachingResponsibilities;
