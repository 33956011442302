import styled from "styled-components/macro";

/* 
  There are a lot of empty style.js files but we cannot delete them because some of these 
  fields need their own styles. If we delete some but not all, it will disrupt this flow of 
  CSS child selectors. Generic styles were moved to AdditionalInfo/styles.js.

  -Andrew 10/7/2022
*/

export default styled.div``;
