import Button from "@material-ui/core/Button";
import Styled from "./styles";
import { Link } from "react-router-dom";

const LowerBar = () => {
  return (
    <Styled>
      <div id="physician-profile">
        <div className="sub-header-text">UPDATE YOUR</div>
        <div className="header-text">PHYSICIAN PROFILE</div>
        <div className="button-container">
          <Button
            className="physician-button"
            size="large"
            variant="outlined"
            href="https://commerce.health.state.ny.us/hpn/"
            target="_blank"
            rel="noreferrer"
          >
            HEALTH COMMERCE SYSTEM
          </Button>
        </div>
      </div>
      <div>
        <div className="sub-header-text">WE WANT YOUR</div>
        <div className="header-text">FEEDBACK</div>
        <div className="button-container">
        <Link to="/feedback">
          <Button
            className="physician-button"
            size="large"
            variant="outlined"
            target="_blank"
            rel="noreferrer"
          >
            NYS/DOH PHYSICIAN PROFILE SURVEY
          </Button>
          </Link>
        </div>
      </div>
    </Styled>
  );
};
export default LowerBar;
