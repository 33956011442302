import * as client from '../axios';
import endpoint from '../endpoint';
import { API_PROFILE_PATH } from '../../utils/Config';


async function fetchProfessionalActivities(formData={},headers={}) {

    const options = {
        params: {
          ...formData
        },
        headers:{
          ...headers
        }
      };
    const response = await client.get(`${API_PROFILE_PATH}${formData.physicianId}?sections=PROFACTIVITIESALL`,options);
    return response.data;
}


export default endpoint(fetchProfessionalActivities, 'fetchProfessionalActivities');