import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const HospitalPreveligeRestriction = ({hospitalPreveligeInfo=[], noDataMessage}) => {
    const columns = [
        {name: 'restrictionDate', label: 'Date'},
        {name: 'stateCode', label: 'State'},
        {name: 'facilityNameText', label: 'Facility'}
    ];

    // const checkIfNoContent = (data) => {
    //     let noRestrictionMessage = '';

    //     if(!data.restrictionDate && !data.stateCode && !data.facilityNameText && !data.actionDescriptionText) {
    //         if(!data.responseIndc) {
    //             noRestrictionMessage = 'The physician did not supply this information';
    //         } else if(data.responseIndc === 'N') {
    //             noRestrictionMessage = 'None';
    //         } else {
    //             noRestrictionMessage = noDataMessage ? noDataMessage : 'None reported';
    //         }
    //         return [false, noRestrictionMessage];
    //     } else {
    //         return [true, noRestrictionMessage];
    //     }
    // }
    const getHospPrevData=()=>{
        return hospitalPreveligeInfo.map(data => {
            let noRestrictionMessage = '';

            if(!data.restrictionDate && !data.stateCode && !data.facilityNameText && !data.actionDescriptionText) {
                if(!data.responseIndc) {
                    noRestrictionMessage = 'The physician did not supply this information';
                } else if(data.responseIndc === 'N') {
                    noRestrictionMessage = 'None';
                } else {
                    noRestrictionMessage = noDataMessage ? noDataMessage : 'None reported';
                } 
                return noRestrictionMessage;// return {...data, restrictionDate: noRestrictionMessage}
            }
            return data;
            
        })
    }
  const hospPrevData=getHospPrevData();
    return (<Styled>
        <TitleHeader label="Hospital Privilege Restrictions" lastUpdatedDate={hospitalPreveligeInfo[0]?.informationDate} />

        {hospPrevData && hospPrevData.length > 0 ? (
        typeof hospPrevData[0] === "string" ? (
          <p className="no-content">{hospPrevData[0]}</p>
        ) : (
          <>
            <Table columns={columns} data={hospPrevData} />
          </>
        )
      ) : (
        <p className="no-content">
          {noDataMessage ? noDataMessage : "None reported"}
        </p>
      )}
        <hr />
    </Styled>);
}

export default HospitalPreveligeRestriction;