import styled from "styled-components/macro";

export default styled.div`
  .section-container {
    padding: 38px 83px 49px 83px;
  }

  .physician-found-text {
    margin-right: 40px;
  }

  .MuiInputBase-root {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  .instruction {
    color: #000000;
    font-size: 20px;
  }

  .MuiTableRow-head > .MuiTableCell-root {
    font-size: 20px !important;
    font-weight: 800 !important;
  }

  .physician-name > a,
  .physician-name > button {
    color: #235d86;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    text-align: left;

    @media only screen and (max-width: 480px) {
      .MuiButton-label {
        justify-content: unset;
      }
    }
  }

  .pagination-container {
    font-size: 20px;
    color: #000000;
  }

  .pagination-container button,
  .pagination-container a {
    font-size: 16px;
    color: #235d86 !important;
    font-weight: bold;
    text-decoration: none;
    text-transform: capitalize;
  }
  .pagination-container button > span {
    color: #235d86 !important;
  }
  .pagination-container button {
    padding: 0 0 0 20px;
    background-color: white;
  }
  .MuiTablePagination-selectRoot .MuiSvgIcon-root {
    width: 2.5em !important;
    height: 2.5em !important;
  }
  .MuiTablePagination-selectRoot .MuiSelect-icon {
    top: calc(50% - 20px) !important;
    color: #424242 !important;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 85px;
  }
  .MuiSelect-select.MuiSelect-select option {
    text-align: left;
  }
  @media only screen and (max-width: 991px) {
    .section-container {
      padding: 38px 50px 49px 50px;
    }
  }
  @media only screen and (max-width: 480px) {
    .section-container {
      padding: 38px 10px 49px 10px;
    }
  }
  .pagination-table .MuiSelect-select.MuiSelect-select {
    font-size: 16px;
    border: 1px solid #424242;
    border-radius: 4px;
  }
  @media only screen and (min-width: 992px) {
    .pagination-table
      .MuiTablePagination-toolbar
      > .MuiTablePagination-selectRoot,
    .pagination-table
      .MuiTablePagination-toolbar
      > .MuiTablePagination-caption {
      position: absolute;
    }

    .pagination-table
      .MuiTablePagination-toolbar
      > .MuiTablePagination-selectRoot,
    .pagination-table
      .MuiTablePagination-toolbar
      > .MuiTablePagination-caption {
      position: absolute;
    }

    .pagination-table .MuiTablePagination-toolbar p:nth-of-type(1) {
      left: 0;
    }

    .pagination-table .MuiTablePagination-toolbar p:nth-of-type(2) {
      left: 280px;
    }

    .pagination-table
      .MuiTablePagination-toolbar
      > .MuiTablePagination-selectRoot {
      left: 95px;
    }
  }
  .MuiTableRow-footer
    .MuiTablePagination-toolbar
    > .MuiTablePagination-selectRoot,
  .MuiTableRow-footer
    .MuiTablePagination-toolbar
    > .MuiTablePagination-caption {
    display: none;
  }
  .pagination-table
    .MuiTablePagination-toolbar
    > .MuiTablePagination-selectRoot,
  .pagination-table .MuiTablePagination-toolbar > .MuiTablePagination-caption {
    font-size: 20px !important;
  }
  @media only screen and (max-width: 768px) {
    .main-table .MuiTableHead-root > .MuiTableCell-root,
    .main-table .MuiTableRow-root > .MuiTableCell-root {
      padding: 10px !important;
      font-size: 14px !important;
    }
    .MuiTableRow-root > .MuiTableCell-root {
      width: 20%;
    }
    .MuiTableRow-root > .medicalp {
      width: 60%;
    }
    .pagination-table .MuiTablePagination-toolbar {
      padding-left: 0;
    }
    .physician-name > a,
    .physician-name > button {
      font-size: 14px;
      width: 0px;
      text-align: left;
    }
  }
`;
