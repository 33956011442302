import styled from "styled-components/macro";

export default styled.div`
    .items-to-print {
        margin-top: 50px;
        > div {
            margin-bottom: 30px;
        }

        > div > hr {
            background-color: transparent;
            margin: 0;
            height: 0;
        }

        > div > p {
            font-size: 0.85rem;
            padding: 0 15px;
            margin-top: 10px;
        }

        > div:first-of-type {
            margin-bottom: 60px;
        }

        > div:last-of-type {
            margin-bottom: 0;
        }
    }
    .row:nth-of-type(even) { // Added for NYP-221/222
        background-color: transparent;
    }
    .address-table:nth-of-type(even) {
        background-color: transparent;
    }
    @media only screen and (max-width: 480px) {
        .mobile-row:nth-of-type(even) {
            background-color: transparent;
        }
    }
    @media print{
        .items-to-print {
            margin-top: 10px;
            > div {
                margin-bottom: 0px;
            }

            > div > p {
                font-size: 0.85rem;
                padding: 0 15px;
                margin-top: 0px;
            }

            > div:first-of-type {
                margin-bottom: 0px;
            }
            
            > div:last-of-type {
                margin-bottom: 0;
            }
            .no-content {
                padding-top: 0px;
                padding-bottom: 0px;
            }
            .title {
                color: black;
            }
            a:-webkit-any-link {
                color: black;
            }
            h4 {
                padding: 15px 0px 5px 0px;
            }
            p {
                margin-bottom: 0px;
            }
            .table:first-of-type {
                margin-bottom: 0px;
            }
            .table.subcertification {
                margin-top: 5px;
            }
            .uni {
                padding: 0px 20px;
            }
            .address-table:last-of-type {
                margin-bottom: 0px;
            }
            .lactions {
                margin-top 0px;
            }
            .table {
                margin-top: 0px;
            }
        } 
        .btn-link{
            color: black;
        }
    }
`;
