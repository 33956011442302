import styled from "styled-components/macro";

export default styled.div`
    
    .intro-text {
        width:80%;
        font-size: 1.125rem;
        div {
            margin-bottom: 10px;
        }
    }
    .input {
        margin-bottom: 50px;
    }
    .select {
        width: 30%;
        margin-top: 10px;
        border-radius: 4px;
        box-shadow: 4px 4px 6px 0 rgba(170,167,167,0.5)
    }
    .label {
        display:contents;
        width: 100%;
        font-size: 18px;
        color: #000000;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .textarea {
        width:65%;
        margin: 20px 0;
        box-shadow: 4px 4px 6px 0 rgba(170,167,167,0.5);
        margin-bottom: 3px;
    }

    .menu-list.Mui-selected {
        background-color: #487BA4;
    }

    .MuiSelect-icon {
        color: #000000;
        width: 2rem;
        height: 2rem;
        top: calc(50% - 15px);    
    }
    
    .text-information {
        width:70%;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 400;
    }
    .button-container{
        padding-top: 10px;
        margin-top: 20px;
    }
    
    .button-container > div {
        text-align: center;
    }
    
    .button {
        padding: 12px;
        color: #000000;
        width: 30%;
        font-size: 1.5rem;  
        font-weight: 700;
        &.MuiButton-outlined {
            border: 2px solid #000000;
            border-radius: 0;
        }
        &.MuiButton-root{
            font-weight: 700;
            font-size: 0.8em;
        }
    }
    
    .form-group {
        margin-top: 20px;
    }

    .MuiIconButton-root {
        border-radius: none;
    }
    .MuiIconButton-label {
        color: #979797;
    }
    .MuiCheckbox-root {
        .MuiSvgIcon-root {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .text-submit {
        color: #235D86;
    }
    .form-group, .checkbox-label > .MuiTypography-root {
        color: #424242;
        font-size: 1.125rem;
    }
    .checkbox-info {
        padding-top: 10px;
    }

    @media only screen and (max-width: 992px) {
        .intro-text {
            width: 100%;
        }
        .text-margin {
            text-align: left;
        }
        .intro-text, .text-information,select,.textarea, .textfield-container {
            width: 80%;
        }
    }

    @media only screen and (max-width: 480px) {
         .feedback-containers{
            padding:20px;
         }
        .intro-text, .select, .text-information, .textfield-container, .button {
            width: 100%;
        }
        .select {
            margin-top: 10px;
        }
        .checkbox-info {
            margin-top: 10px;
        }
        .form-group {
            width: 80%;
        }
        .textarea{
            width:100%;
        }
    }
`;