/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";


const ScrollToTop = (Component) => {
    return ({...rest}) => {
        const location = useLocation();
        useEffect(() => {
            if (location.hash) {
              let elem = document.getElementById(location.hash.slice(1));
              if (elem) {
                setTimeout(() => {
                  elem.scrollIntoView({ behavior: "smooth" });
                }, 1)
                
              }
            } else {
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }, 1)
             
            }
          }, [location])

        return <Component {...rest} />
    }
}

export default ScrollToTop;