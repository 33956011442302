import './Styles.css';

const Disclaimer =()=>{

    return (
        <div className="container" id="privacy-policy">
            <div className="website">
                WEBSITE
            </div>
            <div className="disclaimer">
                DISCLAIMER
            </div>
            <div>
                The data collected by the department is accurate to the best of the knowledge of the department,
                based on the information supplied by the physician who is the subject of the data.
            </div>
            <div>
                While the department utilizes a variety of sources of information in checking the accuracy of the data 
                reported, we cannot be sure that all of the information on this website is right, complete or up-to-date,
                and cannot be responsible for any information that is wrong or has been left out.
            </div>
            <div>
                Consumers are encouraged to consult other sources to verify or obtain additional information about 
                a physician. Pending legal actions do not in any way indicate parties' guilt, liability or culpability.
                Cases may be dismissed, withdrawn, or settled without payments to plaintiffs. Any disposition to a case may be subject to appeal.
            </div>
            <div className="providers">
                Providers De-Authorized from Billing New York's No Fault Auto Insurance System
            </div>
            <div>
                As part of a continuing initiative to no-fault abuse, the NYS department of Financial Services(DFS) 
                de-authorized health service providers, including physicians, from billing New York's no-fault 
                auto insurance system. The names of those health services providers and the Orders and Stipulations 
                de-authorizing them are provided on the DFS website which can be accessed via this link: <a  href="https://www.dfs.ny.gov/apps_and_licensing/property_insurers/nofault/deauthorized_billers" target="_blank" rel="noreferrer">De-Authorized Providers </a> 
                The names and status of the respective providers may also be learned by calling 1-844-409-4999.
            </div>
        </div>
    )
}
export default Disclaimer;