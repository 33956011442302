import * as client from '../axios';
import endpoint from '../endpoint';

async function feedbackList(formData={},headers={}) {
    const options = {
        params: {
          ...formData
        },
        headers:{
          ...headers
        }
      };

    const response = await client.get('/json_data/feedbackList.json',options);
    return response.data;
}

export default endpoint(feedbackList, 'feedbackList');