import {GET_LEGAL_ACTION_INFO_REQUEST, 
    GET_LEGAL_ACTION_INFO_SUCCESS, 
    GET_LEGAL_ACTION_INFO_ERROR} from '../actions/type';


const fetchLegalActionInfo = (formData={},headers={}) => async (dispatch, getState, api) => {

    dispatch(fetchLegalActionInfoRequest());

    return api.fetchLegalActionInfo(formData,headers).then(data => {
        dispatch(fetchLegalActionInfoSuccess(data));
    }).catch(err => {
        dispatch(fetchLegalActionInfoError(err));
    })
}

export default fetchLegalActionInfo;

const fetchLegalActionInfoRequest = () => ({type: GET_LEGAL_ACTION_INFO_REQUEST});

const fetchLegalActionInfoSuccess = (response) => ({
    type: GET_LEGAL_ACTION_INFO_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const fetchLegalActionInfoError = (error) => ({
    type: GET_LEGAL_ACTION_INFO_ERROR,
    error,
    lastUpdated: Date.now(),
});