import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import "./Styles.css";
import { Button, Grid } from "@material-ui/core";
import { seoTool } from "../../../utils/Utils";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const linkArrays = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "FAQ",
      path: "/faqs",
    },
    {
      title: "Search Tips",
      path: "/search-tips",
    },
    {
      title: "Dictionary",
      path: "/dictionary",
    },
    {
      title: "Contact Us",
      path: "/contact-us",
    },
    {
      title: "Feedback",
      path: "/feedback",
    },
  ];

  useEffect(() => {
    seoTool();
  },[]);
  const siteLogo = () => {
    return (
      <IconButton
        edge="start"
        className={classes.menuButton + " logo-link"}
        color="inherit"
        aria-label="menu"
        href="https://www.ny.gov/"
        target="_blank"
        rel="noreferrer"
        title="New York State Home Page"
      >
        <img src="/nygov-logo.png" alt="New York State Home Page" />
      </IconButton>
    );
  };
  return (
    <AppBar position="static" className="nav">
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="mobile-menu">
          <List>
            <ListItem>
              <IconButton
                className="mobile-nav-btn"
                aria-label="menu"
                onClick={() => setOpen(false)}
              >
                <MenuIcon />
              </IconButton>
              {siteLogo()}
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem></ListItem>
            {linkArrays.map(({ title, path }) => (
              <ListItem>
                <Link
                  to={path}
                  className={classes.link}
                  onClick={() => setOpen(false)}
                >
                  {title}
                </Link>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem>
              <Button
                className="physical-login-btn"
                href="https://commerce.health.state.ny.us/hpn/"
                target="_blank"
                rel="noreferrer"
              >
                Physician Login
              </Button>
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Toolbar className="nav-toolbar">
        <div className="sections">
          <a href="#main" className="skip-to-main">
            Skip to main content
          </a>
          {siteLogo()}
        </div>
        <div className="sections">
          <Typography className={classes.title + " nav-links"}>
            <Grid container direction="row" alignItems="center">
              {linkArrays.map(({ title, path }) => (
                <Grid item>
                  <Link to={path} className={classes.link}>
                    {title}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Typography>
        </div>

        <Button
          className="sections"
          href="https://commerce.health.state.ny.us/hpn/"
          target="_blank"
          rel="noreferrer"
        >
          Physician Login
        </Button>
      </Toolbar>
      <Toolbar className="nav-toolbar mobile-nav-toolbar">
        <div className="sections">{siteLogo()}</div>

        <IconButton
          className="mobile-nav-btn"
          aria-label="menu"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
