import {FEEDBACK_MASTER_REQUEST, 
    FEEDBACK_MASTER_SUCCESS, 
    FEEDBACK_MASTER_ERROR} from '../actions/type';


const getfeedbackMasterList = (formData={},headers={}) => async (dispatch, getState, api) => {

    dispatch(feedbackMasterRequest());

    return api.masterList(formData,headers).then(data => {
        dispatch(feedbackMasterSuccess(data));
    }).catch(err => {
        dispatch(feedbackMasterError(err));
    })
}

export default getfeedbackMasterList;

const feedbackMasterRequest = () => ({type: FEEDBACK_MASTER_REQUEST});

const feedbackMasterSuccess = (response) => ({
    type: FEEDBACK_MASTER_SUCCESS, 
    data: response.data,
    lastUpdated: Date.now(),
});

const feedbackMasterError = (error) => ({
    type: FEEDBACK_MASTER_ERROR,
    error,
    lastUpdated: Date.now(),
});