import React, {useEffect} from 'react';
import Styled from './styles';
import * as actions from '../../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import DoctorsStatement from './DoctorsStatement';
import { useLocation } from "react-router-dom";

const Statement = () => {
    const dispatch = useDispatch();

    const {state} = useLocation();
    const { physicianId } =state;

    const statementInfo = useSelector(({statementInfo}) => statementInfo?.data?.data);
    const logger = useSelector(({ logger }) => logger?.data);

    const noDataMessage = useSelector(({physicianProfileInfo}) => physicianProfileInfo?.dataMessage);

    useEffect(() => {
        if (!logger?.sessionId) {
            dispatch(actions.fetchLogger());
          } else {
            dispatch(actions.fetchStatementsInfo(
              { physicianId: physicianId },
                { sessionId: logger?.sessionId }
            ));
          }
        }, [dispatch, logger?.sessionId, physicianId]);

    return <Styled>
        <p>Under the Profile law, a doctor is entitled to make a statement about his or her profile information.</p>
        <DoctorsStatement statement={statementInfo?.statement} noDataMessage={noDataMessage} />
    </Styled>
}

export default Statement;