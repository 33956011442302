import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const HIVServices = ({hiv={}, noDataMessage}) => {
    const columns = [
        {name: 'serviceName', label: 'Services', width: '50'},
        {name: 'serviceValue', label: 'Provided', width: '50'},
    ]

    let hivCertified = hiv?.hivCertified;
    let hivReferral = hiv?.hivReferral;
    let hivServices = hiv?.hivServices;

    if(!hivServices) {
        hivServices = 'Not Reported'; //noDataMessage ? noDataMessage : 'Not Reported';
    } else if(hivServices === 'N') {
        hivServices = 'No';
    } else if(hivServices === 'Y') {
        hivServices = 'Yes';
    }

    if(!hivReferral) {
        hivReferral = 'Not Reported'; //noDataMessage ? noDataMessage : 'Not Reported';
    } else if(hivReferral === 'N') {
        hivReferral = 'No';
    } else if(hivReferral === 'Y') {
        hivReferral = 'Yes';
    }

    if(!hivCertified) {
        hivCertified = 'Not Reported';  //noDataMessage ? noDataMessage : 'Not Reported';
    } else if(hivCertified === 'N') {
        hivCertified = 'No';
    } else if(hivCertified === 'Y') {
        hivCertified = 'Yes';
    }
    

    const hivData = [
        {serviceName: 'HIV Services and/or care for patients on ARV', serviceValue: hivServices},
        {serviceName: 'HIV Referral Services Accepted for new patients', serviceValue: hivReferral},
        {serviceName: 'HIV Certified by AAHIVM and/or member of HIVMA', serviceValue: hivCertified }
    ];

    return (
        <Styled>
            <TitleHeader label="HIV Services (optional)" lastUpdatedDate={hiv[0]?.informationDate} />
            <Table columns={columns} data={hivData} />
                
            <hr />
            <p>
                <strong>Note: </strong> Contact the physician's office for more information.
            </p>
        </Styled>
    );
}

export default HIVServices;