import React from "react";
import Styled from "./styles";
import TitleHeader from "../TitleHeader";
import Table from "../../Table";
import { Link } from "react-router-dom";

const BoardCertification = ({
  boardCertification,
  boardSubcertifications,
  noDataMessage,
}) => {
  const columns1 = [
    { name: "boardName", label: "Name of Board", width: "50" },
    { name: "certificationDate", label: "Certification Date", width: "25" },
    { name: "expirationDate", label: "Expiration Date", width: "25" },
  ];

  const columns2 = [
    {
      name: "subspecialtyDescription",
      label: "Board Subcertifications",
      width: "50",
    },
    { name: "subcertificationDate", label: "Certification Date", width: "25" },
    { name: "expirationDate", label: "Expiration Date", width: "25" },
  ];
  
  return (
    <Styled>
      <TitleHeader
        label="Board Certifications"
        linkType="internal"
        linkPath="/dictionary#5"
        lastUpdatedDate={(boardCertification && boardCertification[0]?.informationDate)}
      />
      <Table columns={columns1} data={boardCertification} />
      {(!boardCertification || boardCertification.length <= 0) && (
        <p className="no-content">
          {noDataMessage ? noDataMessage : "No Board Certifications Found."}
        </p>
      )}

      {boardSubcertifications && boardSubcertifications.length > 0 && (
        <Table
          columns={columns2}
          data={boardSubcertifications}
          className="subcertification"
        />
      )}
      <hr />
      {((boardCertification && boardCertification.length > 0) ||
        (boardSubcertifications && boardSubcertifications.length > 0)) && (
        <>
          <p className="notes">
            <strong>1. Note:</strong> The state of New York recognizes the
            specialty boards that are members or components of the{" "}
            <Link to="/dictionary#3">ABMS</Link>,{" "}
            <Link to="/dictionary#4">AOA</Link> or{" "}
            <Link to="/dictionary#20">RCPSC</Link>. Certification by member
            boards or components of these umbrella organizations is the
            responsibility of the member organization.
          </p>
          <p className="notes">
            <strong>2. Note:</strong> For certification dates, a default value
            of "01" appears in the month field if the specific month was not
            provided by ABMS.
          </p>
          <p className="notes">
            *ABMS Copyright 2022 American Board of Medical Specialties. All
            rights reserved.{" "}
          </p>
        </>
      )}
    </Styled>
  );
};

export default BoardCertification;
