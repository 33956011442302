import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const Community = ({community=[], noDataMessage}) => {
    const columns = [
        {name: 'serviceDescriptionText', label: 'Community Service Activity', width: '100'},
    ];

    let updatedDataMessage = noDataMessage;

    if(!updatedDataMessage || !(updatedDataMessage === 'Profile under Development' || updatedDataMessage === "Dr.'s survey not received")) {
        updatedDataMessage= 'The physician did not supply this information';
    }

    return (
        <Styled>
            <TitleHeader label="Professional & Community Service Activities (Optional)" lastUpdatedDate={community[0]?.informationDate} />
            {
                community && community.length > 0 ? (
                      <Table columns={columns} data={community} />
                ) : (
                    <p className="no-content">{updatedDataMessage}</p>
                )
            }
            
            <hr />
        </Styled>
    );
}

export default Community;