import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";

const LanguagesAvailable = ({ translationService = [], noDataMessage }) => {
  let sNoTranslationMsg = "";
  const getLangauage = () => {
    return translationService && translationService.length > 0 ? (
      translationService.map((t, i) => {
        let language = t.languageName;
        if (language == null) {
          language = noDataMessage;
        }

        let otherLang = t.serviceNameText;
        if (otherLang == null) {
          otherLang = "";
        }

        if (t.languageName === "" && t.serviceNameText === "") {
          if (i === 0) {
            if (t.responseIndc === "") {
              sNoTranslationMsg =
                "The physician did not supply this information";
            } else if (t.responseIndc === "N") {
              sNoTranslationMsg = "None";
            } else {
              sNoTranslationMsg = noDataMessage;
            }
          }
          return <p className="no-content">{sNoTranslationMsg}</p>;
        } else {
          return otherLang !== "" ? (
            <p>
              <strong>Other Languages: </strong>
              {otherLang}
            </p>
          ) : (
            <p>{language}</p>
          );
        }
      })
    ) : (
      <p className="no-content">
        {noDataMessage ? noDataMessage : "None reported"}
      </p>
    );
  };

  return (
    <Styled>
      <TitleHeader label="Languages Available" lastUpdatedDate={translationService[0]?.informationDate} />
      {getLangauage()}
      <hr />
      {translationService &&
        translationService.length > 0 &&
        translationService.some((t) => t.languageName || t.serviceNameText) && (
          <p>
            <strong>Note:</strong> Contact the Physician's office for more
            information.
          </p>
        )}
    </Styled>
  );
};

export default LanguagesAvailable;
