import {GET_EDUCATION_INFO_REQUEST, 
    GET_EDUCATION_INFO_SUCCESS, 
    GET_EDUCATION_INFO_ERROR, SET_PHYSICIAN_PROFILE_DATA} from '../actions/type';


const fetchEducationInfo = (formData={},headers={}) => async (dispatch, getState, api) => {

    dispatch(fetchEducationInfoRequest());

    return api.fetchEducationInfo(formData,headers).then(data => {
        dispatch(fetchEducationInfoSuccess(data));
        dispatch({type: SET_PHYSICIAN_PROFILE_DATA, data: data?.data?.phyInfo})
    }).catch(err => {
        dispatch(fetchEducationInfoError(err));
    })
}

export default fetchEducationInfo;

const fetchEducationInfoRequest = () => ({type: GET_EDUCATION_INFO_REQUEST});

const fetchEducationInfoSuccess = (response) => ({
    type: GET_EDUCATION_INFO_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const fetchEducationInfoError = (error) => ({
    type: GET_EDUCATION_INFO_ERROR,
    error,
    lastUpdated: Date.now(),
});