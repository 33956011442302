import React from 'react';
import Styled from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Link} from 'react-router-dom';

/**
 * 
 * @param {*} param0 
 * @returns 
 * columns = [{name: "", label: "", width}]
 */

const Table = ({columns=[], data=[], checkIfNoContent = null, className}) => {
    const isMobileWidth = useMediaQuery(`screen and (max-width: 480px)`);
    return !isMobileWidth ? (
        <Styled className={`table${className ? ' ' + className : ''}`} role='table'>
            <div className='table-header row' role='row'>
                {columns?.map(col => (
                    <div className='col' role='columnheader' key={col.name} style={{flexBasis: col?.width ? col?.width+"%" : (100/columns.length)+"%"}}>{col?.linkType ? col.linkType === 'external' ? (<a target="_blank" rel="noreferrer" href={col.linkPath}>{col.label}</a>) : (<Link to={col.linkPath}>{col.label}</Link>) :col.label}</div>
                ))}
            </div>
            {!checkIfNoContent && data?.map((d, i) => (
                <div className='row' key={i} role='row'>
                    {columns.map(col => (
                        <div className='col' role='cell' key={col.name} style={{flexBasis: col?.width ? col?.width+"%" : (100/columns.length)+"%"}}>{d[col.name]}</div>
                    ))}
                </div>
            ))}
            {checkIfNoContent && data?.map((d, i) => {
                const [ifDataExist] = checkIfNoContent(d);
                return ifDataExist ? <div className='row' key={i} role='row'>
                    {columns.map(col => (
                        <div className='col' role='cell'  key={col.name} style={{flexBasis: col?.width ? col?.width+"%" : (100/columns.length)+"%"}}>{d[col.name]}</div>
                    ))}
                </div> : null
            })}
            
        </Styled>
    ) : (
        <Styled className='mobile-table' role='table'>
            {data?.map((d, i) => (
                <div className='mobile-row' key={i} role='row'>
                    {columns.map(col => (
                        <div className='mobile-col' key={col.name} role='cell'>
                            {col.label&&(<p className='title'>{col.label+':'}</p>)}
                            <p>{d[col.name]}</p>
                        </div>
                    ))}
                </div>
            ))}
            
        </Styled>
    )
}

export default Table;