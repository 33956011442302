
import styled from "styled-components/macro";

export default styled.div`
.nav ul {
    list-style-type: none;
    display: inline-flex;
}

.nav ul li {
    padding: 0 20px;
}

.nav ul li a {
    text-decoration: none;
    color: grey;
}

.nav {
    background-color: white !important;
}

.login-btn {
    background-color: yellow !important;
    color: black !important;
    font-weight: 600;
}

.logo-link {
    color: yellow !important;
}

`;