import styled from "styled-components/macro";

export default styled.div`
    .modal-container {
        text-align: center;
    }
    .MuiPaper-rounded {
        border-radius: 0;
    }
    .dialog-content{
        padding: 40px 20px;
    }
    .dialog-title {
        background-color: #235D86;
        font-size: 1.25rem;
        font-weight: 700;
        color: #FFFFFF;
    }
    .MuiSvgIcon-root{
        color: #FFFFFF;
        height: 1.5rem;
        width: 1.5rem;
    }
    .button-container{
        padding-top: 10px;
        margin-top: 20px;
        margin-left: 10px;
    }
    
    .button-container > div {
        text-align: center;
    }
    
    .button {
        padding: 12px;
        color: #000000;
        width: 30%;
        font-size: 1.5rem;  
        font-weight: 700;
        &.MuiButton-outlined {
            border: 2px solid #000000;
            border-radius: 0;
        }
        &.MuiButton-root{
            font-weight: 700;
            font-size: 0.8em;
        }
    }
    
`;