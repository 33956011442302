import React from "react";
import Styles from "./styles";

export default function HeroImage() {
  return (
    <Styles>
      <div className="banner-container" id="main">
        <div className="banner-box"></div>
        <div className="left-banner-text">
          <h1>
            <div className="welcome-text">WELCOME TO THE </div>
            <div className="ny-state-text">
              New York State <br></br>Physician Profile{" "}
            </div>
          </h1>
          <p>
            The purpose of the New York State Physician Profile website is to
            enable the public to review information about all licensed doctors
            of medicine and doctors of osteopathy who are registered to practice
            medicine in New York State. This information can help you choose a
            doctor or find out more about a doctor you are currently seeing.
          </p>
        </div>
      </div>
    </Styles>
  );
}
