import { create } from 'axios';

const axios = create({
  timeout: 60000,
});

// axios.defaults.headers.common.tenantId = window.INITIAL_STATE.config.tenantId;

/**
 * 
 * @param {*} path 
 * @param {*} options ={headers={}, params={}}
 * @returns 
 */

function get(path, options={}) {
  return axios.get(path, {
    ...options
  });
}

function post(path, requestBody, options={}) {
  return axios.post(path, requestBody, { ...options });
}

function put(path, requestBody, options={}) {
  return axios.put(path, requestBody, { ...options });
}

// eslint-disable-next-line import/prefer-default-export
export { get, post, put, axios };
