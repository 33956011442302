import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';
import { loadState, saveState } from './localStorage';
import { throttle } from '../../utils/Utils';


export default function reduxStore(api, initialState = {}) {
    const middlewareEnhancer = applyMiddleware(thunkMiddleware.withExtraArgument(api));
    const composedEnhancers = composeWithDevTools(middlewareEnhancer);
    const state = { ...initialState, ...loadState() };
    const store = createStore(rootReducer, state, composedEnhancers);


    store.subscribe(
        throttle(() => {
            const {
                educationInfo,
                physicianProfileInfo,
                legalActionInfo,
                practiceInfo,
                professionalActivities,
                statementInfo,
                setSelectedItemsToPrint
            } = store.getState();

            saveState({
                educationInfo,
                physicianProfileInfo,
                legalActionInfo,
                practiceInfo,
                professionalActivities,
                statementInfo,
                setSelectedItemsToPrint
            });

        }, 1000)
    )

    return store;
}