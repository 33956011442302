import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  // FormHelperText,
  // FormControl,
} from "@material-ui/core";
import Styles from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { onSubmitHandlerCommon } from "./../../../../utils/Utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function PhysicianSearch() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [inputData, setInputData] = useState({ firstName: "", lastName: "" });
  const onSubmitHandler = (e) => {
    const { uri } = onSubmitHandlerCommon(e);
    navigate(`/physicians/${uri}`, {
      state: { prevPageState: inputData, prevPath: window.location.pathname },
    });
  };
  const onInputChangeHandler = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const onAdvSrchClick = () => {
    navigate(`/advanced-search`, {
      state: {
        prevPageState: inputData,
        updateState: true,
        fromPage: "basic-search",
      },
    });
  };

  useEffect(() => {
    setInputData(state?.["prevPageState"]);
  }, [state]);
  return (
    <Styles id="search">
      <div className={classes.root + " physician-search"}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={7} className="physician-wrapper">
            <Grid item xs={12} lg={12} sm={12}>
              <div className="search-container">
                <span className="title">Physician Search</span>
                <p>
                  Fill in at least the first character of the last name of the doctor you are searching for.<br/>You can also use Advanced Search to better narrow your results.
                  For help, <a href="/search-tips"> view our search tips.</a>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} lg={12} sm={12}>
              <form
                className={classes.root + " search-form"}
                onSubmit={onSubmitHandler}
              >
                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} lg={4} sm={4}>
                      <TextField
                        id="last-name"
                        for="last-name"
                        label="Last name"
                        variant="outlined"
                        className="input-text"
                        name="lastName"
                        value={inputData?.lastName}
                        onChange={onInputChangeHandler}
                        // error={true}
                        required
                      />
                      {/* <FormHelperText error={true}>Error</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} lg={4} sm={4}>
                      <TextField
                        for="first-name"
                        id="first-name"
                        label="First name"
                        variant="outlined"
                        className="input-text"
                        name="firstName"
                        value={inputData?.firstName}
                        onChange={onInputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4} sm={4}>
                      <Grid container>
                        <Grid item xs={12} lg={12} sm={12}>
                          <Button
                            type="submit"
                            variant="outlined"
                            size="large"
                            color="primary"
                            className="search-btn"
                          >
                            Search
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          sm={12}
                          className="padding10"
                        >
                          <button
                            type="button"
                            className="btn-link colorBlue fontW600 font16px"
                            onClick={onAdvSrchClick}
                          >
                            {" "}
                            Advanced Search
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Styles>
  );
}
