import Styled from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const ReturnToPage = ({ linkText, stateData = null,linkPath = -1 }) => {
  const navigate = useNavigate();
  return (
    <Styled>
      <Button
        className="colorBlue textTransform font16px btn-link"
        onClick={() => navigate(linkPath, { state: stateData })}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        &nbsp; {linkText}
      </Button>
    </Styled>
  );
};
export default ReturnToPage;
