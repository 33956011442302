import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";
import Table from "../../Table";

const HealthPlans = ({ healthPlanContract = [], noDataMessage }) => {
  const columns = [
    { name: "healthPlanName", label: "Health Plan", width: "100" },
  ];

  let updatedDataMessage = noDataMessage;
  if (
    !(
      updatedDataMessage === "Profile under Development" ||
      updatedDataMessage === "Dr.'s survey not received"
    )
  ) {
    updatedDataMessage = "The physician did not supply this information";
  } else if (!updatedDataMessage) {
    updatedDataMessage = "None reported";
  }

  return (
    <Styled>
      <TitleHeader label="Health Plans (Optional)" lastUpdatedDate={healthPlanContract[0]?.informationDate} />
      {healthPlanContract && healthPlanContract.length > 0 ? (
        <Table
          columns={columns}
          data={healthPlanContract.map((p) => {
            let healthPlanName = p.healthPlanName;
            if (!healthPlanName) {
              healthPlanName = updatedDataMessage;
            }
            let otherPlanText = p.otherPlanText;
            if (otherPlanText) {
              healthPlanName = otherPlanText;
            }

            return { ...p, healthPlanName: healthPlanName };
          })}
        />
      ) : (
        <p className="no-content">{updatedDataMessage}</p>
      )}
      <hr />
      {healthPlanContract && healthPlanContract.length > 0 && (
        <p>
          <strong>Note:</strong> Contact the doctor's office to see if this
          doctor is taking new patients.
        </p>
      )}
    </Styled>
  );
};

export default HealthPlans;
