import React from "react";
import Profile from "../../PhysicianProfile/Profile";
import BoardCertification from "../../PhysicianProfile/AdditionalInfo/EducationInfo/BoardCertification";
import GraduateMedicalSchool from "../../PhysicianProfile/AdditionalInfo/EducationInfo/GraduateMedicalSchool";
import MedicalInfo from "../../PhysicianProfile/AdditionalInfo/EducationInfo/MedicalInfo";
import ProfessionalMembership from "../../PhysicianProfile/AdditionalInfo/EducationInfo/ProfessionalMembership";
import FieldOfMedicine from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/FieldOfMedicine";
import HIVServices from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/HIVServices";
import Offices from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/Offices";
import LanguagesAvailable from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/LanguagesAvailable";
import HealthPlans from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/HealthPlans";
import HealthPrivileges from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/HealthPrivileges";
import InsuranceProgram from "../../PhysicianProfile/AdditionalInfo/PracticeInfo/InsuranceProgram";
import Community from "../../PhysicianProfile/AdditionalInfo/ProfessionalActivities/Community";
import Publications from "../../PhysicianProfile/AdditionalInfo/ProfessionalActivities/Publications";
import TeachingResponsibilities from "../../PhysicianProfile/AdditionalInfo/ProfessionalActivities/TeachingResponsibilities";
import Malpractice from "../../PhysicianProfile/AdditionalInfo/LegalActions/Malpractice";
import LiscenseeAction from "../../PhysicianProfile/AdditionalInfo/LegalActions/LiscenseeActions";
import OutOfStateActions from "../../PhysicianProfile/AdditionalInfo/LegalActions/OutOfStateActions";
import CurrentLimitations from "../../PhysicianProfile/AdditionalInfo/LegalActions/CurrentLimitations";
import HospitalPreveligeRestriction from "../../PhysicianProfile/AdditionalInfo/LegalActions/HospitalPreveligeRestriction";
import HospitalFailure from "../../PhysicianProfile/AdditionalInfo/LegalActions/HospitalFaliure";
import CriminalConvictions from "../../PhysicianProfile/AdditionalInfo/LegalActions/CriminalConvictions";
import DoctorsStatement from "../../PhysicianProfile/AdditionalInfo/Statement/DoctorsStatement";

const SelectedItemsToPrint = ({
  setSelectedItemsToPrint,
  educationInfo,
  practiceInfo,
  professionalActivities,
  legalActionInfo,
  statementInfo,
  noDataMessage,
}) => {
  return (
    <div className="items-to-print">
      <Profile showHeaderButtons={false} />
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["medicalSchool"]) && (
        <MedicalInfo
          medicalInfo={educationInfo?.medSchools}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["graduateMedEdu"]) && (
        <GraduateMedicalSchool
          gmeSchools={educationInfo?.gmeSchools}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["boardCertification"]) && (
        <BoardCertification
          boardCertification={educationInfo?.boardCertification}
          boardSubcertifications={educationInfo?.boardSubcertifications}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["professionalMembership"]) && (
        <ProfessionalMembership
          profMember={educationInfo?.profMember}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["fieldOfPractice"]) && (
        <FieldOfMedicine
          medicalPractice={practiceInfo?.medicalPractice}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["hivServices"]) && (
        <HIVServices hiv={practiceInfo?.hiv} />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["offices"] ||
        setSelectedItemsToPrint?.["officeInfoOnly"]) && (
        <Offices
          practiceaddress={practiceInfo?.practiceaddress}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["translationServices"]) && (
        <LanguagesAvailable
          translationService={practiceInfo?.translationService}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["govtInsuranceProg"]) && (
        <InsuranceProgram
          insuranceProgram={practiceInfo?.insuranceProgram}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["healthPlans"]) && (
        <HealthPlans
          healthPlanContract={practiceInfo?.healthPlanContract}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["hospitalPriveleges"]) && (
        <HealthPrivileges
          admittingPrivilege={practiceInfo?.admittingPrivilege}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["malpractice"]) && (
        <Malpractice
          malPracticeInfo={legalActionInfo?.malpractice}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["liscenseeActions"]) && (
        <LiscenseeAction printMessage={true} />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["outOfStateActions"]) && (
        <OutOfStateActions
          outStateInfo={legalActionInfo?.outstate}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["currentLimitations"]) && (
        <CurrentLimitations
          currentLimitationsInfo={legalActionInfo?.limitations}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["hospitalRestictions"]) && (
        <HospitalPreveligeRestriction
          hospitalPreveligeInfo={legalActionInfo?.restriction}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["hospitalFailure"]) && (
        <HospitalFailure
          hospitaFaliureInfo={legalActionInfo?.resign}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["criminalConvictions"]) && (
        <CriminalConvictions
          criminalConvictionInfo={legalActionInfo?.conviction}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["academicAppointments"]) && (
        <TeachingResponsibilities
          medschool={professionalActivities?.medschool}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["communityServiceActivities"]) && (
        <Community
          community={professionalActivities?.community}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["publications"]) && (
        <Publications
          publication={professionalActivities?.publication}
          noDataMessage={noDataMessage}
        />
      )}
      {(setSelectedItemsToPrint?.["allInfo"] ||
        setSelectedItemsToPrint?.["statement"]) && (
        <DoctorsStatement
          statement={statementInfo?.statement}
          noDataMessage={noDataMessage}
        />
      )}
    </div>
  );
};

export default SelectedItemsToPrint;
