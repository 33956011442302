import ApiError from './ApiError';

export default function endpoint(fn, funcName, onError = defaultOnError) {
  // either networkErrors or onError should throw the error forward. onError should always throw.
  const handleErrors = (err) => networkErrors(err).then(onError);

  const checkError = (err) => {
    if (!(err instanceof ApiError)) {
      // eslint-disable-next-line no-console
      console.warn(`${funcName} endpoint: Unexpected error not of ApiError type: `, err);
    }
    // eslint-disable-next-line no-console
    console.error(
      `${funcName} endpoint: ${err.message}: ${err.originalError.name}: ${err.originalError.message}`,
    );
    // eslint-disable-next-line no-console
    console.dir && console.dir(err.originalError || err);
    throw err;
  };

  return (...params) =>
    fn(...params)
      .catch(handleErrors)
      .catch(checkError);
}

function defaultOnError(error) {
  // allow manual throwing ApiErrors inside of endpoint function after network request
  if (error instanceof ApiError) throw error;
  throw new ApiError('Unhandled Error', 'ERROR.UNHANDLED', error);
}

async function networkErrors(error) {
  if (error.response) {
    const { data } = error.response;
    if (data && typeof data.error === 'string' && data.error.indexOf('ERROR.') === 0)
      throw new ApiError(
        data.errorMessage || data.message || 'Handled backend error',
        data.error,
        error,
      );
    if (error.response.status < 100)
      throw new ApiError(
        'Invalid status code / network error',
        'ERROR.NETWORK_MISSING',
        error,
        true,
      );
    if (error.response.status > 499)
      throw new ApiError('Internal server error', 'ERROR.SERVER_ERROR', error, true);
    if (error.response.status === 404)
      throw new ApiError('Not found', 'ERROR.NOT_FOUND', error, true);
    throw new ApiError('Unhandled Network Error', 'ERROR.UNHANDLED', error, true);
  } else if (error.request) {
    if (!error.request.status)
      throw new ApiError('Unable to reach server', 'ERROR.NETWORK_MISSING', error, true);
  }
  return error;
}
