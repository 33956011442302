import styled from "styled-components/macro";

export default styled.div`
margin-bottom: 40px;
    .title {
        color: #235D86;
    }

    .header-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 480px) {

            svg {
             font-size: 35px;
            } 
         }

        >div {
            display: flex;
            > .btn-link {
                color: #235D86;
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-right: 20px;
                font-size: 16px;

                > svg {
                    margin-right: 4px;
                }
            }

            > a {
                color: #235D86;
                font-weight: bold;
                display: flex;
                align-items: center;
                text-decoration: none;

                > span {
                    margin-right: 4px;

                }
            }
        }
    }

    .profile-detail {
        display: flex;


        >div {
            width: 70%;
            > div {
                display: inline-flex;

                > p.headname {
                    display: flex;
                    justify-content: flex-end;
                    min-width: 170px;
                    margin-right: 20px;
                }
            }
        }
    }


    @media only screen and (max-width: 480px) {
        .profile-detail {
            flex-direction: column;

            >div {
                width: 100%;
            }
        }
    }
`;