import {GET_PRACTICE_INFO_REQUEST, 
    GET_PRACTICE_INFO_SUCCESS, 
    GET_PRACTICE_INFO_ERROR } from '../actions/type';


const fetchPracticeInfo = (formData={},headers={}) => async (dispatch, getState, api) => {
    dispatch(fetchPracticeInfoRequest());

    return api.fetchPracticeInfo(formData,headers).then(data => {
        dispatch(fetchPracticeInfoSuccess(data));
    }).catch(err => {
        dispatch(fetchPracticeInfoError(err));
    })
}

export default fetchPracticeInfo;

const fetchPracticeInfoRequest = () => ({type: GET_PRACTICE_INFO_REQUEST});

const fetchPracticeInfoSuccess = (response) => ({
    type: GET_PRACTICE_INFO_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const fetchPracticeInfoError = (error) => ({
    type: GET_PRACTICE_INFO_ERROR,
    error,
    lastUpdated: Date.now(),
});