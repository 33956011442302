import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Styled from './styles';

const AdditionalInfoTabs = ({value, setValue, handleChange}) => {
    
    return (
        <Styled>
            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                <Tab label="EDUCATION" value="education" />
                <Tab label="PRACTICE INFO" value="practiceInfo" />
                <Tab label="LEGAL ACTIONS" value="legalActions" />
                <Tab label="PROFESSIONAL ACTIVITIES" value="professionalActivities" />
                <Tab label="STATEMENT" value="statement" />
            </Tabs>
        </Styled>
        
    )
}

export default AdditionalInfoTabs;