import React from 'react';
import Styled from './styles';
import {Link} from 'react-router-dom';

const TitleHeader = ({label, linkType, linkPath, lastUpdatedDate=undefined}) => {
    return <Styled>
        {linkType ? linkType === 'external' ? 
        (<strong><a target="_blank" rel="noreferrer" href={linkPath}>{label}</a></strong>) : 
        (<strong><Link to={linkPath}>{label}</Link></strong>) : 
        (<strong>{label}</strong>)}
        <p><small>Last Updated: {(typeof lastUpdatedDate !== "undefined") ? lastUpdatedDate : ""}</small></p>
    </Styled>
}

export default TitleHeader;