import './Styles.css';
import {useEffect} from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from '../../../../redux/actions';
import { convertTagsToJSX } from '../../../../utils/Utils';

const AccordionItem =()=>{
    const Accordion = withStyles({
        root: {
        backgroundColor: '#F1F1F1',
          border: 0,
          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
          },
        },
        expanded: {},
      })(MuiAccordion);
      
      const AccordionSummary = withStyles({
        expandIcon: {
            order: -1
        },
        root: {
          marginBottom: -1,
          minHeight: 56,
          '&$expanded': {
            minHeight: 56,
          },
        },
        content: {
          '&$expanded': {
            margin: '0.75rem 0',
          },
        },
        expanded: {
        },
      })(MuiAccordionSummary);

      const dispatch = useDispatch();

      const {data: faqData} = useSelector(({faqs}) => faqs);
      const logger = useSelector(({ logger }) => logger?.data);

      useEffect(() => {
        if(!logger?.sessionId) {
          dispatch(actions.fetchLogger());
        } else {
          dispatch(actions.fetchFaqs({
            sessionId: logger?.sessionId,
          }))
        }
        
      }, [dispatch, logger?.sessionId])

    return (
        <div className='accordion-container'>
            {faqData && faqData?.map((item) => { 
                return(
                    <Accordion> 
                        <AccordionSummary
                            expandIcon={<><AddIcon className="addIcon"/><CloseIcon className="closeIcon"/></>}
                            IconButtonProps={{edge: 'start'}}
                            id={item?.question.replace(/ /g,'-')}
                            className="accordion-summary"
                            >
                            <h2><Typography className='colorBlue font24px fontW400'>{convertTagsToJSX(item?.question)}</Typography></h2>
                        </AccordionSummary>
                        <AccordionDetails id={item?.question.replace(/ /g,'-')}>
                        <Typography className='colorBlack font18px fontW400'>
                            {convertTagsToJSX(item?.answer)}
                        </Typography>
                        </AccordionDetails>
                        
                    </Accordion>
                )})
            }
        </div>
    )
}
export default AccordionItem;