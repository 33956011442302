import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));


export default function ProgressLoader(props) {
  const {size,color}=props;
  const styles={
    loaderStyle:{
      color:color?color:'#235D86'
    }
  };
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress style={styles.loaderStyle} size={size}/>
    </div>
  );
}
