import styled from "styled-components/macro";

export default styled.div.attrs((props) => ({
  className: props.className,
}))`
  margin-top: 20px;
  &:first-of-type {
    margin-top: 10px;
  }

  .row {
    padding: 0 15px;

    &:nth-of-type(even) {
      background-color: #eaeae8;
    }

    &.table-header {
      > .col {
        font-weight: bold;
      }

      background-color: transparent;
    }

    display: flex;
    > .col {
      font-size: 0.85rem;
    }
  }

  @media only screen and (max-width: 480px) {
    > .mobile-row {
      padding: 5px 15px;
      &:nth-of-type(even) {
        background-color: #eaeae8;
      }
      margin-top: 20px;
      &:first-of-type {
        margin-top: 10px;
      }
      > .mobile-col {
        display: flex;
        font-size: 0.85rem;

        > .title {
          font-weight: bold;
        }

        > p {
          flex-basis: 50%;
        }
      }
    }
  }

  .no-content {
    background-color: #eaeae8;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    font-size: 0.85rem;
    padding: 0 15px;
  }
`;
