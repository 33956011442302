import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import TableHead from "@material-ui/core/TableHead";
import Styled from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";
import { Button } from "@material-ui/core";
import ProgressLoader from "../../../Common/Loading/ProgressLoader";
import { navigateWithDataHandler } from "../../../../utils/Utils";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#235D86",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 20,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const getFormParamsSorted = () => {
  const formParams = new URLSearchParams(window.location.search);
  const formKeys = [
    "subSpeciality",
    "speciality",
    "city",
    "county",
    "hospital",
    "licenseNumber",
    "cbMedicaid",
    "cbMedicare",
    "cbHiv",
  ];
  const sortedFormData = {};
  for (let i = 0; i < formKeys.length; i++) {
    const keyName = formKeys[i];
    sortedFormData[keyName] = formParams.get(keyName);
  }
  return sortedFormData;
};
function TablePaginationActions(props) {
  const {state} = useLocation();
  const navigate = useNavigate();
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const onClickBackBtn = () => {
    navigate(state?.prevPath+'#search', { state: { prevPageState:state?.prevPageState,updateState:true } });
  };
  return (
    <div className={classes.root + " pagination-container"}>
      <Button onClick={onClickBackBtn}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        &nbsp;Search Again
      </Button>

      {page > 0 && (
        <IconButton onClick={handleBackButtonClick} aria-label="previous page">
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <>
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;Previous Result Page
            </>
          )}
        </IconButton>
      )}
      {page < Math.ceil(count / rowsPerPage) - 1 && (
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <>
              Next Result Page&nbsp; <FontAwesomeIcon icon={faArrowRight} />
            </>
          )}
        </IconButton>
      )}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function SearchResultsData() {
  const { lastName, firstName } = useParams();
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data, loading,error } = useSelector(({ physicians }) => physicians);
  const PhysicianData = data;
  const logger = useSelector(({ logger }) => logger?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleLabelDisplayedRows = (event) => {
     

    return (
      <>
        {PhysicianData?.numberOfResults>0&&('of ')}<strong>{PhysicianData?.numberOfResults}</strong>{" "}
        <span className="physician-found-text">Physicians found</span>{" "}
        <span>
          Page
          <strong> {page + 1} </strong>
          of {PhysicianData?.numberOfResults>0?(Math.ceil(PhysicianData?.numberOfResults / rowsPerPage)):1}
        </span>
      </>
    );
  };

  useEffect(() => {
    const getResultData = () => {
      const formData = getFormParamsSorted();
      const params = {
        pageNumber: page + 1,
        numberOfResults: rowsPerPage,
        advancedSearch: "Y",
        specialty: "",
        noDataMsg: "",
        specialtyGroup: formData["speciality"],
        specialtySubGroup: formData["subSpeciality"],
        lastName: lastName?.trim(),
        firstName: firstName?.trim(),
        hospitalCode: "",
        countyFipsCode: formData["county"],
        city: formData["city"],
        print:"N",
        // countyName: formData["county"],
        specialityGroupSearch: "",
        specialitySubGroupSearch: "",
        medicadIndicator: formData["cbMedicaid"],
        medicareIndicator: formData["cbMedicare"],
        hivIndicator: formData["cbHiv"],
        hospitalName: formData["hospital"],
        licenseNumber: formData["licenseNumber"]
      };

      dispatch(
        actions.fetchPhysicians(params, {
          sessionId: logger?.sessionId,
        })
      );
    };
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      getResultData();
    }
  }, [page, rowsPerPage, dispatch, logger?.sessionId, lastName, firstName]);

  const practiceLocDisplay = (dataObj) => {
    const { practiceLocations } = dataObj;

    if (practiceLocations) {
      return practiceLocations.map((obj) => {
        if (obj?.city) {
          return (
            <>
              {obj?.city}, {obj?.state} <br></br>
            </>
          );
        }
        return false;
      });
    }
  };
  const checkWithStatusCode = (dataObj, field) => {
    const { statusCode, terminationText, dataMessage } = dataObj;

    if (statusCode === "A") {
      if (field === "pl") {
        return practiceLocDisplay(dataObj);
      }
      return medicalPracticeDisplay(dataObj);
    } else if (statusCode === "I") {
      if (field === "pl") {
        return terminationText;
      }
      return medicalPracticeDisplay(dataObj);
    } else {
      return dataMessage;
    }
  };
  const medicalPracticeDisplay = (dataObj) => {
    const { medicalPractice } = dataObj;
    return medicalPractice.map((value) => {
      return (
        <>
          {value}
          <br></br>
        </>
      );
    });
  };
  
  if (loading||(!PhysicianData&&!error)) {
    return (
      <Styled>
        <div className="section-container">
          <ProgressLoader size={60} />
        </div>
      </Styled>
    );
  }
  if(error){
    return (
      <Styled>
        <div className="section-container">
          {error?.message}
        </div>
      </Styled>
    );
  }
  
 const PhyInTables=PhysicianData?.physicians;
  return (
    <Styled>
      <div className="section-container">
        <div className="instruction">
          <strong>Click on a doctor’s name for more information.</strong>
        </div>

        <TableContainer component={Paper}>
          <Table className="pagination-table">
            <TableBody>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={PhysicianData?.numberOfResults>0?[
                    { label: "10 results", value: 10 },
                    { label: "20 results", value: 20 },
                    { label: "50 results", value: 50 },
                  ]:[]}
                  colSpan={3}
                  count={PhysicianData?.numberOfResults}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Displaying"
                  SelectProps={{
                    inputProps: { "aria-label": "Displaying" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={handleLabelDisplayedRows}
                />
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={`${classes.table} main-table`}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">PHYSICIAN NAME</StyledTableCell>
                <StyledTableCell align="left">
                  PRACTICE LOCATION
                </StyledTableCell>
                <StyledTableCell align="left">
                  FIELD OF MEDICINE
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PhyInTables?(PhyInTables.map((row, index) => (
                <StyledTableRow>
                  <StyledTableCell className="physician-name">
                    <Button onClick={navigateWithDataHandler({'physicianId':row.physicianID,'statusCode':row.statusCode},navigate,'/physician-profile')}>
                      {row.physicianFirstName} {row.physicianLastName}{" "}
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {checkWithStatusCode(row, "pl")}
                  </StyledTableCell>
                  <StyledTableCell className="medicalp" align="left">
                    {checkWithStatusCode(row, "mp")}
                  </StyledTableCell>
                </StyledTableRow>
              ))):(<StyledTableRow>
                <StyledTableCell className="physician-name" colSpan={3}>
                <strong>No Physicians were found that matched your search request.</strong>
                </StyledTableCell>
              </StyledTableRow>)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    { label: "10 results", value: 10 },
                    { label: "20 results", value: 20 },
                    { label: "50 results", value: 50 },
                  ]}
                  colSpan={3}
                  count={PhysicianData?.numberOfResults}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Displaying"
                  SelectProps={{
                    inputProps: { "aria-label": "Displaying" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={handleLabelDisplayedRows}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </Styled>
  );
}
