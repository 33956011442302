import React, { useEffect, useState } from "react";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styled from "./styles";
import { convertTagsToJSX } from "../../../utils/Utils";
import ReturnToPage from "../../Common/ReturnToPage";

const LanguageAssistance = () => {
  const [languageAssistance, setLanguageAssistance] = useState([]);
  const properties = {
    titleBlue: "LANGUAGE",
    titleBlack: "Assistance",
  };
  useEffect(() => {
    fetch("/json_data/languageAss.json").then((data) => {
      data.json().then((jsonData) => {
        setLanguageAssistance(jsonData);
      });
    });
  }, []);
  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          <div className="paddingTop20px"></div>
          {languageAssistance?.map(({ key, country, lText, eText }) => (
            <div key={key} className="lang-as fontW500 font18px">
              {lText && eText ? (
                <>
                  <p>{convertTagsToJSX(lText)}</p>
                  <p className="paddingTop10px">{convertTagsToJSX(eText)}</p>
                </>
              ) : (
                <p>
                  <img src={require(`./img/${country}.gif`)} alt={country} />
                </p>
              )}
              <div className="paddingTop20px"></div>
              <hr />
              <div className="paddingTop20px"></div>
            </div>
          ))}
          <div className="paddingTop20px">
            <ReturnToPage linkText={"Go Back to Previous Page"} />
          </div>
        </BodyContent>
      </Styled>
    </SubPage>
  );
};
export default ScrollToTop(LanguageAssistance);
