import Styled from "./styles";
import Disclaimer from "./Disclaimer";
import { Link } from "react-router-dom";
// import logo from "../../../assets/new-york-state-vector-logo-white.svg";

const Footer = () => {
  return (
    <Styled>
      <Disclaimer />
      <div className="footer">
        <div className="footer-container">
          <div>
            <Link to="/"> Home </Link> |<Link to="/#search"> Search </Link> |
            <Link to="/#physician-profile">
              {" "}
              Physicians: Update your profile{" "}
            </Link>{" "}
            |<Link to="/search-tips"> Search Tips </Link>
          </div>
          <div>
            <Link to="/dictionary"> Dictionary </Link> |
            <Link to="/contact-us"> Contact Us </Link> |
            <Link to="/feedback"> Give Us Your Feedback </Link> |
            <a
              href="https://www.health.ny.gov/about/privacy.htm"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Privacy Policy{" "}
            </a>
          </div>
          <div className="lang-links">
            <Link to="/language-assistance" title="Chinese language assistance"> 中文 </Link>
            <Link to="/language-assistance" title="Spanish language assistance"> Español </Link>
            <Link to="/language-assistance" title="Russian language assistance"> Русский </Link>
            <Link to="/language-assistance" title="Kreyol language assistance"> Kreyòl </Link>
          </div>
          <div>
            <a
              href="http://www.health.ny.gov/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Department of Health Home Page{" "}
            </a>{" "}
            |
            <a
              href="http://www.health.ny.gov/nysdoh/healthinfo/index.htm"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Center for Consumer Health Care Information{" "}
            </a>
          </div>
          <div>
            <a
              href="http://www.op.nysed.gov/opsearches.htm"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              New York State Education Office of the Professions On-line
              Verification Searches
            </a>
          </div>
          <div>
            <a
              href="https://profiles.health.ny.gov/hospital"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              New York State Hospital Profile{" "}
            </a>{" "}
            |
            <a
              href="http://iapps.courts.state.ny.us/webcivil/ecourtsMain"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              New York State Unified Court System{" "}
            </a>
          </div>
        </div>
      </div>
    </Styled>
  );
};
export default Footer;
