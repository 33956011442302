import styled from "styled-components/macro";

export default styled.div`

    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 60px;
    }

    .title {
        font-size: 24px;
        color: #235D86;
        font-weight: 600;
    }

    .description {
        font-size: 20px;
    }

`;