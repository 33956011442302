export const GET_DICTIONARY_LIST_REQUEST = 'GET_DICTIONARY_LIST_REQUEST';
export const GET_DICTIONARY_LIST_SUCCESS = 'GET_DICTIONARY_LIST_SUCCESS';
export const GET_DICTIONARY_LIST_ERROR = 'GET_DICTIONARY_LIST_ERROR';

export const GET_PHYSICIANS_LIST_REQUEST = 'GET_PHYSICIANS_LIST_REQUEST';
export const GET_PHYSICIANS_LIST_SUCCESS = 'GET_PHYSICIANS_LIST_SUCCESS';
export const GET_PHYSICIANS_LIST_ERROR = 'GET_PHYSICIANS_LIST_ERROR';

export const FEEDBACK_FORM_REQUEST = 'FEEDBACK_FORM_REQUEST';

export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR';

export const FEEDBACK_LIST_REQUEST = 'FEEDBACK_LIST_REQUEST';
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS';
export const FEEDBACK_LIST_ERROR = 'FEEDBACK_LIST_ERROR';

export const FEEDBACK_MASTER_REQUEST = 'FEEDBACK_MASTER_REQUEST';
export const FEEDBACK_MASTER_SUCCESS = 'FEEDBACK_MASTER_SUCCESS';
export const FEEDBACK_MASTER_ERROR = 'FEEDBACK_MASTER_ERROR';

export const GET_EDUCATION_INFO_REQUEST = 'GET_EDUCATION_INFO_REQUEST';
export const GET_EDUCATION_INFO_SUCCESS = 'GET_EDUCATION_INFO_SUCCESS';
export const GET_EDUCATION_INFO_ERROR = 'GET_EDUCATION_INFO_ERROR';

export const SET_PHYSICIAN_PROFILE_DATA = 'SET_PHYSICIAN_PROFILE_DATA'

export const GET_LEGAL_ACTION_INFO_REQUEST = 'GET_LEGAL_ACTION_INFO_REQUEST';
export const GET_LEGAL_ACTION_INFO_SUCCESS = 'GET_LEGAL_ACTION_INFO_SUCCESS';
export const GET_LEGAL_ACTION_INFO_ERROR = 'GET_LEGAL_ACTION_INFO_ERROR';

export const LOGGER_REQUEST = 'LOGGER_REQUEST';
export const LOGGER_SUCCESS = 'LOGGER_SUCCESS';
export const LOGGER_ERROR = 'LOGGER_ERROR';

export const GET_PRACTICE_INFO_REQUEST = 'GET_PRACTICE_INFO_REQUEST';
export const GET_PRACTICE_INFO_SUCCESS = 'GET_PRACTICE_INFO_SUCCESS';
export const GET_PRACTICE_INFO_ERROR = 'GET_PRACTICE_INFO_ERROR';

export const GET_PROFESSIONAL_ACTIVITIES_REQUEST = 'GET_PROFESSIONAL_ACTIVITIES_REQUEST';
export const GET_PROFESSIONAL_ACTIVITIES_SUCCESS = 'GET_PROFESSIONAL_ACTIVITIES_SUCCESS';
export const GET_PROFESSIONAL_ACTIVITIES_ERROR = 'GET_PROFESSIONAL_ACTIVITIES_ERROR';

export const GET_STATEMENTS_REQUEST = 'GET_STATEMENTS_REQUEST';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const GET_STATEMENTS_ERROR = 'GET_STATEMENTS_ERROR';

export const SET_SELECTED_ITEMS_TO_PRINT = 'SET_SELECTED_ITEMS_TO_PRINT';

export const GET_FAQS_REQUEST = 'GET_FAQS_REQUEST';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_ERROR = 'GET_FAQS_ERROR';

export const GET_ANALYTICS_URL_DATA_REQUEST = "GET_ANALYTICS_URL_DATA_REQUEST"
export const GET_ANALYTICS_URL_DATA_SUCCESS = "GET_ANALYTICS_URL_DATA_SUCCESS"
export const GET_ANALYTICS_URL_DATA_ERROR = "GET_ANALYTICS_URL_DATA_ERROR"
