import {GET_PROFESSIONAL_ACTIVITIES_REQUEST, 
    GET_PROFESSIONAL_ACTIVITIES_SUCCESS, 
    GET_PROFESSIONAL_ACTIVITIES_ERROR} from '../actions/type';

const initialState = {
    loading: null,
    error: null,
    data: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
    switch(action.type) {
        case GET_PROFESSIONAL_ACTIVITIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PROFESSIONAL_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data
            }
        case GET_PROFESSIONAL_ACTIVITIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}