import React from 'react';
import SubPage from '../../Common/SubPage';
import Styled from './styles';
import FeedbackForm from './FeedbackForm';
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import BodyContent from '../../Common/BodyContent';
const Feedback = () => {
    const properties={
        titleBlue:'GIVE US YOUR',
        titleBlack:'Feedback',
      }
    return (
            <SubPage properties={properties}>
                <Styled>
                <BodyContent>
                <FeedbackForm />
                </BodyContent>
                </Styled>
            </SubPage>
    )
}

export default ScrollToTop(Feedback);