import { combineReducers } from 'redux';
import dictionaries from './dictionaries';
import physicians from './physicians';
import feedback from './feedback';
import educationInfo from './educationInfo';
import physicianProfileInfo from './physicianProfileInfo';
import legalActionInfo from './legalActionInfo';
import logger from './logger';
import practiceInfo from './practiceInfo';
import professionalActivities from './professionalActivities';
import statementInfo from './statement';
import setSelectedItemsToPrint from './setSelectedItemsToPrint';
import faqs from './faqs';
import analytics from "./analytics";

const rootReducer = combineReducers({
    dictionaries,
    physicians,
    feedback,
    educationInfo,
    physicianProfileInfo,
    legalActionInfo,
    logger,
    practiceInfo,
    professionalActivities,
    statementInfo,
    setSelectedItemsToPrint,
    faqs,
    analytics
});

export default rootReducer;