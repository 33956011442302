import Styled from "./styles";
const SubPageHeading = ({ headingTitle, instruction }) => {
  return (
    <Styled>
        <h2 className="colorBlue font24px">{headingTitle}</h2>
        <span className="colorBlack font20px">
          {instruction}
        </span>
    </Styled>
  );
};
export default SubPageHeading;
