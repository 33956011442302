import {FEEDBACK_REQUEST, 
    FEEDBACK_SUCCESS, 
    FEEDBACK_ERROR,
    FEEDBACK_LIST_REQUEST, 
    FEEDBACK_LIST_SUCCESS, 
    FEEDBACK_LIST_ERROR,
    FEEDBACK_FORM_REQUEST, } from '../actions/type';


const saveFeedback = (feedbackFormData) => async (dispatch, getState, api) => {

    dispatch(feedbackRequest());

    return api.feedback(feedbackFormData).then(data => {
        dispatch(feedbackSuccess(data));
    }).catch(err => {
        dispatch(feedbackError(err));
    })
}

export default saveFeedback;

export const saveFeedbackForm = (feedbackFormData) => ({type: FEEDBACK_FORM_REQUEST, data: feedbackFormData});

export const getFeedbackList = (formData={},headers={}) => async (dispatch, getState, api) => {

    dispatch(feedbackListRequest());

    return api.feedbackList(formData,headers).then(data => {
        dispatch(feedbackListSuccess(data));
    }).catch(err => {
        dispatch(feedbackListError(err));
    })
}



const feedbackRequest = () => ({type: FEEDBACK_REQUEST});

const feedbackSuccess = (response) => ({
    type: FEEDBACK_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const feedbackError = (error) => ({
    type: FEEDBACK_ERROR,
    error,
    lastUpdated: Date.now(),
});

const feedbackListRequest = () => ({type: FEEDBACK_LIST_REQUEST});

const feedbackListSuccess = (response) => ({
    type: FEEDBACK_LIST_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const feedbackListError = (error) => ({
    type: FEEDBACK_LIST_ERROR,
    error,
    lastUpdated: Date.now(),
});