import {GET_PHYSICIANS_LIST_REQUEST, 
    GET_PHYSICIANS_LIST_SUCCESS, 
    GET_PHYSICIANS_LIST_ERROR} from '../actions/type';

const initialState = {
    loading: null,
    error: null,
    data: null,
    formData:null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
    switch(action.type) {
        case GET_PHYSICIANS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PHYSICIANS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.data,
                formData: action.formData
            }
        case GET_PHYSICIANS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}