import * as client from '../axios';
import endpoint from '../endpoint';

async function fetchDictionaries(formData={},headers={}) {
    const options = {
        params: {
          ...formData
        },
        headers:{
          ...headers
        }
      };
    const response = await client.get('/json_data/dictionaryList.json',options);
    return response.data;
}

export default endpoint(fetchDictionaries, 'fetchDictionaries');