import * as client from '../axios';
import endpoint from '../endpoint';

import { API_PATH } from '../../utils/Config';

async function fetchDataCommon(path,formData={},headers={},method='GET') {
    const options = {
        params: {
          ...formData
        },
        headers:{
          "content-type": "application/json",
          ...headers
        }
      };
      let response;
      if(method==='GET'){
         response = await client.get(`${API_PATH}${path}`,options);
      }else{
         response = await client.post(path,options);
      }
   
    return response.data;
}

export default endpoint(fetchDataCommon, 'fetchDataCommon');