import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";

const DoctorsStatement = ({ statement = {}, noDataMessage }) => {
  let updatedDataMessage = noDataMessage;

  if (
    !updatedDataMessage ||
    !(
      updatedDataMessage === "Profile under Development" ||
      updatedDataMessage === "Dr.'s survey not received"
    )
  ) {
    updatedDataMessage = "The physician did not supply this information";
  }
  return (
    <Styled>
      <TitleHeader label="Doctor's Statement (Optional)" lastUpdatedDate={statement?.informationDate}/>
      {/* <div className="statement-block"> */}
      {statement && statement.noteText && <p>{statement.noteText}</p>}
      {(!statement || (statement && !statement.noteText)) && (
        <p className="no-content">{updatedDataMessage}</p>
      )}
      {/* </div> */}
      <hr />
    </Styled>
  );
};

export default DoctorsStatement;
