import styled from "styled-components/macro";

export default styled.div`
    .sub-page-header-container {
      background-color: #f1f1f1;
      font-family: "Proxima Nova";
      letter-spacing: 0;
    }

    .sub-headers {
      padding: 30px 83px 35px 83px;
    }

    .title-blue {
      color: #235d86;
      font-size: 25px;
      font-weight: 800;
      font-family: 'Oswald' !important;
    }

    .title-black {
      font-size: 50px;
      color: #000000;
      font-weight: 800;
      line-height:61px;
  }
  @media only screen and (max-width: 991px) {
    .sub-headers {
      padding: 30px 50px 35px 50px;
    }
  }
`;
