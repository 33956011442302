import styled from "styled-components/macro";

export default styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #FACE00;
    align-items: center;
    margin-bottom: 60px;
    padding: 20px 20px 70px 20px;
    margin-top: 60px;

    & > div { 
        margin-top: 10px;
        text-align: center;
        font-size: 1.2em;

        .sub-header-text {
            font-family: Oswald !important;
            font-weight: 800;
            font-size: 1.56rem !important;
            color: #000000;
            margin-bottom: -10px;
        }
        
        .header-text {
            color: #235D86;
            font-size: 3.125rem !important;
            font-weight: 800;
        }
    }



.physician-button {
    color: #000000;
    width: 65%;
    font-size: 1.2rem;  
    font-weight: 700;
}

.button-container{
    margin-top: 20px;
}

.physician-button.MuiButton-outlined {
    border: 2px solid #000000;
    border-radius: 0;
}

.physician-button.MuiButton-root{
    font-weight: 700;
    font-size: 0.9em;
}

@media only screen and (max-width: 1228px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;