import React, {useEffect} from "react";
import Styled from "./styles";
import { convertTagsToJSX } from "../../../../../utils/Utils";
import { useLocation } from "react-router-dom";

const DictionaryItem = ({ id, title, description }) => {

  const location = useLocation();
        useEffect(() => {
            if (location.hash) {
              let elem = document.getElementById(location.hash.slice(1));
              if (elem) {
                setTimeout(() => {
                  elem.scrollIntoView({ behavior: "smooth" });
                }, 1)
                
              }
            }
          }, [id, location.hash])
 
  return (
    <Styled>
      <h2 id={id} className="title">
        {title}
      </h2>
      <p className="description">{convertTagsToJSX(description)}</p>
    </Styled>
  );
};

export default DictionaryItem;
