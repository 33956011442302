import {
  GET_PHYSICIANS_LIST_REQUEST,
  GET_PHYSICIANS_LIST_SUCCESS,
  GET_PHYSICIANS_LIST_ERROR,
} from "./type";

const fetchPhysiciansList =
  (formData={},headers={}) =>
  async (dispatch, getState, api) => {
    dispatch(fetchPhysiciansListRequest());

    return api
      .fetchPhysicians(formData,headers)
      .then((data) => {
        dispatch(fetchPhysiciansListSuccess(formData,data));
      })
      .catch((err) => {
        dispatch(fetchPhysiciansError(err));
      });
  };

export default fetchPhysiciansList;

const fetchPhysiciansListRequest = () => ({
  type: GET_PHYSICIANS_LIST_REQUEST,
});

const fetchPhysiciansListSuccess = (formData,response) => ({
  type: GET_PHYSICIANS_LIST_SUCCESS,
  data: response,
  formData:formData,
  lastUpdated: Date.now(),
});

const fetchPhysiciansError = (error) => ({
  type: GET_PHYSICIANS_LIST_ERROR,
  error,
  lastUpdated: Date.now(),
});
