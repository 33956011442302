import React from "react";
import Styled from "./styles";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import Profile from "./Profile";
import AdditionalInfo from "./AdditionalInfo";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import { useLocation } from "react-router-dom";
import InActiveProfile from "./Profile/InActiveProfile";
const PhysicianProfile = () => {
  const properties = {
    titleBlue: "PHYSICIAN",
    titleBlack: "Profile"
  };
  const { state } = useLocation();
  if (!state) {
    window.location.href = window.location.origin;
  }
  const { statusCode } = state;
  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          {statusCode === "I" ? (
            <InActiveProfile />
          ) : (
            <>
              <Profile />
              <AdditionalInfo />
            </>
          )}
         
        </BodyContent>
      </Styled>
    </SubPage>
  );
};

export default ScrollToTop(PhysicianProfile);
