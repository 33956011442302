import styled from "styled-components/macro";

/* 
  There are a lot of empty style.js files but we cannot delete them because some of these 
  fields need their own styles (like this one). If we delete some but not all, it will disrupt this flow of 
  CSS child selectors. Generic styles were moved to AdditionalInfo/styles.js.

  This file probably could be condensed since there seems to be some extra CSS not used but come back to if time permits

  -Andrew 10/7/2022
*/

export default styled.div`
  // Due to the JSX structure, this is needed to keep styling consistent like the rest
  > div > p {
    font-size: 0.85rem;
    padding: 0 15px;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  > p {
    margin-bottom: 5px;
  }

  > div > div > h5 {
    padding: 0 15px;
    margin-bottom: 10px;
  }

  > div > div > p {
    font-size: 0.85rem;
    padding: 0 15px;
    margin-bottom: 5px;
  }

  > div > div > p.sub-header {
    font-size: 0.9rem;
  }

  > div {
    margin-top: 0px;
  }

  .sub-header {
    margin-top: 10px;
  }

  .tablehed {
    font-weight: bold;
    font-size: 0.85rem;
    margin-top: 10px;
  }

  .table {
    margin-top: 0;
  }

  .table:first-of-type {
    margin-bottom: 20px;
  }
`;
