import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const Offices = ({practiceaddress=[], noDataMessage}) => {
    const columns = [
        {name: '', label: 'Office', width: '50'},
        {name: '', label: 'Doctors in practice', width: '50'},
    ]

    let updatedDataMessage = noDataMessage;

    if(!(updatedDataMessage === 'Profile under Development' || 
    updatedDataMessage === "Dr.'s survey not received")) {
        updatedDataMessage = 'The physician did not supply this information';
    } else if(!updatedDataMessage) {
        updatedDataMessage = 'None reported';
    }

    return (
        <Styled>
            <TitleHeader label="Offices (Optional)" lastUpdatedDate={practiceaddress[0]?.informationDate} />
            {practiceaddress && practiceaddress.length > 0 ? (
            <>
            <Table columns={columns} />
            {
                practiceaddress?.map((item) => (
                    <div className='address-table'>
                        <div className='office-address'>
                            {item?.name && <div>{item?.name}</div>}
                            {item?.addressLine1 && <div>{item?.addressLine1}</div>}
                            {item?.addressLine2 && <div>{item?.addressLine2}</div>}
                            {item?.addressLine3 && <div>{item?.addressLine3}</div>}
                            {item?.addressLine4 && <div>{item?.addressLine4}</div>}
                            {item?.state ? <div>{`${item?.city || ''}, ${item?.state} ${item.zipCode || ''}`}</div> : 
                            (item.countryName ? <div>{`${item?.city || ''}, ${item?.provinceName || ''}, ${item.countryName} ${item.zipCode || ''}`}</div> : 
                            <div>{`${item?.city || ''} ${item.zipCode || ''}`}</div>)
                            }
                            {item.countyName && <div>County: {item.countyName}</div>}
                            {item?.phoneNumber && <div>{item?.phoneNumber.length === 10 ? `(${item?.phoneNumber.slice(0,3)})${item?.phoneNumber.slice(3,6)}-${item?.phoneNumber.slice(6)}` : item?.phoneNumber}</div>}
                            {item?.disabledAccess && item?.disabledAccess === 'Y' && <div>Accessible to persons with disabilities: Yes</div>}
                            {item?.disabledAccess && item?.disabledAccess === 'N' && <div>Accessible to persons with disabilities: No</div>}
                            {!item?.disabledAccess && <div>Accessible to persons with disabilities: Not Reported</div>}
                        </div>
                        <div className='doctor-names'>
                            {item?.doctors?.map((doctor) => (
                                <div>{doctor?.name}</div>
                            ))}
                        </div>
                    </div>
                ))
            }
            
            </>
            ) : (<p className='no-content'>{updatedDataMessage}</p>)}
            <hr />
            
        </Styled>
    );
}

export default Offices;