import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import SubPageHeader from '../SubPageHeader';
import LowerBar from '../LowerBar';
const SubPage = ({properties, children}) => {

    return <>
        <Header />
        <SubPageHeader {...properties}/>
        {children}
        <LowerBar />
        <Footer />
    </>
}

export default SubPage;