import styled from "styled-components/macro";

export default styled.div`
    > .btn-link {
        color: #235D86;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-size: 16px;

        > svg {
            margin-right: 4px;
        }
    }

`;