import React, { useEffect } from "react";
import BodyContent from "../../Common/BodyContent";
import GoBackButton from "../PhysicianProfile/GoBackButton";
import { MdOutlineArrowBack } from "react-icons/md";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SelectedItemsToPrint from "./SelectedItemsToPrint";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styled from "./styles";
import { seoTool } from "../../../utils/Utils";

const PrinterFriendlyPage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  if (!state) {
    window.location.href = window.location.origin;
  }
  const { physicianId } = state;
  const educationInfo = useSelector(
    ({ educationInfo }) => educationInfo?.data?.data
  );
  const legalActionInfo = useSelector(
    ({ legalActionInfo }) => legalActionInfo?.data?.data
  );
  const practiceInfo = useSelector(
    ({ practiceInfo }) => practiceInfo?.data?.data
  );
  const professionalActivities = useSelector(
    ({ professionalActivities }) => professionalActivities?.data?.data
  );
  const statementInfo = useSelector(
    ({ statementInfo }) => statementInfo?.data?.data
  );
  const setSelectedItemsToPrint = useSelector(
    ({ setSelectedItemsToPrint }) => setSelectedItemsToPrint
  );
  const logger = useSelector(({ logger }) => logger?.data);
  const noDataMessage = useSelector(
    ({ physicianProfileInfo }) => physicianProfileInfo?.dataMessage
  );

  useEffect(() => {
    seoTool();
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      dispatch(
        actions.fetchEducationInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
      dispatch(
        actions.fetchLegalActionInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
      dispatch(
        actions.fetchPracticeInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
      dispatch(
        actions.fetchProfessionalActivities(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
      dispatch(
        actions.fetchStatementsInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
    }
  }, [dispatch, logger?.sessionId, physicianId]);

  return (
    <BodyContent>
      <Styled>
        <GoBackButton
          buttonContent={
            <>
              <MdOutlineArrowBack />
              <span>Go Back to Previous Page</span>
            </>
          }
        />
        <SelectedItemsToPrint
          setSelectedItemsToPrint={setSelectedItemsToPrint}
          educationInfo={educationInfo}
          practiceInfo={practiceInfo}
          professionalActivities={professionalActivities}
          legalActionInfo={legalActionInfo}
          statementInfo={statementInfo}
          noDataMessage={noDataMessage}
        />
      </Styled>
    </BodyContent>
  );
};

export default ScrollToTop(PrinterFriendlyPage);
