import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const HospitalFaliure = ({hospitaFaliureInfo=[], noDataMessage}) => {
    const columns = [
        {name: 'renewResignDate', label: 'Date'},
        {name: 'stateCode', label: 'State'},
        {name: 'facilityNameText', label: 'Facility'},
        {name: 'actionDescriptionText', label: 'Summary'}
    ];

    // const checkIfNoContent = (data) => {
    //     let noRenewMessage = '';
    //     if(!data.renewResignDate && !data.stateCode && !data.facilityNameText && !data.actionDescriptionText) {
    //         if(!data.responseIndc) {
    //             noRenewMessage = 'The physician did not supply this information';
    //         } else if(data.responseIndc === 'N') {
    //             noRenewMessage = 'None';
    //         } else {
    //             noRenewMessage = noDataMessage ? noDataMessage : 'None reported';
    //         }
    //         return [false, noRenewMessage];
    //     } else {
    //         return [true, noRenewMessage];
    //     }
    // }

    return (<Styled>
        <TitleHeader label="Hospital Failure to Renew Privileges" lastUpdatedDate={hospitaFaliureInfo[0]?.informationDate} />
        {hospitaFaliureInfo && hospitaFaliureInfo.length > 0 && hospitaFaliureInfo[0].informationDate ? (
            <>
            <Table columns={columns} data={hospitaFaliureInfo.map(data => {
                let noRenewMessage = '';
                if(!data.renewResignDate && !data.stateCode && !data.facilityNameText && !data.actionDescriptionText) {
                    if(!data.responseIndc) {
                        noRenewMessage = 'The physician did not supply this information';
                    } else if(data.responseIndc === 'N') {
                        noRenewMessage = 'None';
                    } else {
                        noRenewMessage = noDataMessage ? noDataMessage : 'None reported';
                    }
                }
                return {...data, renewResignDate: noRenewMessage}
            })} />
            </>
            
        ) : <p className='no-content'>{noDataMessage ? noDataMessage : 'None reported'}</p>}
        <hr />
    </Styled>);
}

export default HospitalFaliure;