import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckboxComponent = ({name, label, checked, indeterminate, handleChange}) => {
    return (
        <FormControlLabel
            label={label}
            name={name}
            sx={{ '.MuiSvgIcon-root': { fontSize: 22 } }}
            control={
            <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleChange}
                
            />
            }
        />
    )
}

export default CheckboxComponent;