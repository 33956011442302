import React from 'react';
import Styled from './styles';
import TitleHeader from '../../EducationInfo/TitleHeader';
import Table from '../../Table';

const Malpractice = ({malPracticeInfo=[], noDataMessage='None reported'}) => {
    const judgementColumns = [
        {name: 'closeDate', label: 'Date of Judgment or Award', width: '30'},
        {name: 'location', label: 'Zip Code or County of Event', width: '35'},
        {name: 'rating', label: 'Significance of Payment', width: '35'}
    ]
    const settlementColumns = [
        {name: 'closeDate', label: 'Disposition Date', width: '25'},
        {name: 'location', label: 'Zip Code or County of Event', width: '25'},
        {name: 'rating', label: 'Significance of Payment', width: '25'},
        {name: 'highLow', label: 'High/Low Agreement High | Low', width: '25'}
    ]

    const arbitrationJudgementData = [];
    const settlementData = [];

    malPracticeInfo.forEach(m => {
        if(m.malpracticeType === 'Arbitration' || m.malpracticeType === 'Judgement') {
            arbitrationJudgementData.push({
                closeDate: m.closeDate ? m.closeDate : (noDataMessage ? noDataMessage : 'None reported'),
                location: m.countyNameText ? m.countyNameText : m.zipCodeText,
                rating: m.rating ? m.rating : (noDataMessage ? noDataMessage : 'None reported')
            })
        }

        if(m.malpracticeType === 'Settlement') {
            settlementData.push({
                closeDate: m.closeDate ? m.closeDate : (noDataMessage ? noDataMessage : 'None reported'),
                location: m.countyNameText ? m.countyNameText : m.zipCodeText,
                rating: m.rating ? m.rating : (noDataMessage ? noDataMessage : 'None reported'),
                highLow: m.high && m.low ? `${m.high ? m.high : ' '} | ${m.low ? m.low : ' '}     ` : ''
            })
        }
    })

    return (<Styled>
        <TitleHeader label="Malpractice" lastUpdatedDate={malPracticeInfo[0]?.informationDate} />
        <div>
            {arbitrationJudgementData.length > 0 || settlementData.length > 0 ? (
                <>
                    <div>
                        <h5>
                            There
                            is important information below to help
                            you understand medical <a href="/">malpractice</a>.
                            This physician may have provided important additional information in the physician statement section of this profile.
                        </h5>
                        <p className='sub-header'><strong>Judgments and Arbitration Awards </strong></p>
                        <p><strong>Number of awards:</strong> {arbitrationJudgementData.length}</p>
                        <p class="tablehed">Payment Details</p>
                        {arbitrationJudgementData.length > 0 ?<Table columns={judgementColumns} data={arbitrationJudgementData} /> : <Table columns={judgementColumns} data={[{closeDate: noDataMessage, location: noDataMessage, rating: noDataMessage}]} />}
                        
                    </div>
                    <div>
                        <p className='sub-header'><strong>Settlements</strong></p>
                        <p>
                            Settlement payments will appear in this profile only if the 
                            total number of settlements made within the past ten years is equal to 
                            three or more. Settlement of a claim may occur for a variety of reasons,
                            which does not necessarily reflect negatively on the professional competence
                            or conduct of the physician. A payment in settlement of a medical malpractice
                            action or claim does not necessarily mean that a medical malpractice has occurred.
                            When a settlement payment is made, the patient agrees to accept some money and stop 
                            the legal action--without going to court.
                        </p>
                        <p>
                            If only one or two settlements appear, it means that the Department of Health
                            determined that the settlement(s) are relevant to patient decision making.
                        </p>
                        <p>
                            We encourage you to talk with the doctor about the information in this report. 
                        </p>
                        <p>High/Low Agreements</p>
                        <p>
                            Increasingly more and more popular in civil litigation, plaintiffs and defendants,
                            prior to going to trial, will enter into "high-low agreements." 
                        </p>
                        <p>
                            The agreement sets a maximum amount (the "<b>high</b>" amount) that a defendant will pay
                            to the plaintiff, if the judgment is <b>against the defendant</b>. In return for agreeing
                            to this cap on the damages award, the agreement provides for a minimum amount
                            (the "<b>low</b>" amount) that the defendant will pay to the plaintiff, if the judgment is
                            <b> against the plaintiff</b>.
                        </p>
                        <p>
                            The jury is not told of the agreement and only issues the verdict, which is the 
                            sole judgment of the court. The award is made pursuant to the terms of the 
                            high-low agreement and, is therefore, considered to be a settlement agreement.
                        </p>
                        <p class="data"><strong>Number of payments:</strong> {settlementData.length}</p>
                        <p class="tablehed">Payment Details</p>
                        {settlementData.length > 0 ?<Table columns={settlementColumns} data={settlementData} /> : <Table columns={settlementColumns} data={[{closeDate: noDataMessage, location: noDataMessage, rating: noDataMessage, highLow: noDataMessage}]} />}
                    </div>
                </>
            
            ) : <p className='no-content'>{noDataMessage ? noDataMessage : 'None reported'}</p>}
        </div>
        <hr />
        <p><strong>Note:</strong> Malpractice information continues to be collected and reviewed. You should check periodically to see if information has been added or updated.</p>
    </Styled>);
}

export default Malpractice;