import React, { useEffect } from "react";
import Styled from "./styles";
import * as actions from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Malpractice from "./Malpractice";
import LiscenseeActions from "./LiscenseeActions";
import OutOfStateActions from "./OutOfStateActions";
import CurrentLimitations from "./CurrentLimitations";
import HospitalPreveligeRestriction from "./HospitalPreveligeRestriction";
import HospitalFaliure from "./HospitalFaliure";
import CriminalConvictions from "./CriminalConvictions";
import { useLocation } from "react-router-dom";

const LegalActions = () => {
  const dispatch = useDispatch();
  const logger = useSelector(({ logger }) => logger?.data);
  const legalActionInfo = useSelector(
    ({ legalActionInfo }) => legalActionInfo?.data?.data
  );
  const noDataMessage = useSelector(({physicianProfileInfo}) => physicianProfileInfo?.dataMessage);
  const physicianInfo = useSelector(({physicianProfileInfo}) => physicianProfileInfo);
  const {state} = useLocation();
  const { physicianId } =state;

  useEffect(() => {
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      dispatch(
        actions.fetchLegalActionInfo(
          { physicianId: physicianId },
          { sessionId: logger?.sessionId }
        )
      );
    }
  }, [dispatch, logger?.sessionId, physicianId]);

  return (
    <Styled>
      <Malpractice malPracticeInfo={legalActionInfo?.malpractice} noDataMessage={noDataMessage} />
      <LiscenseeActions physicianInfo={physicianInfo} />
      <OutOfStateActions outStateInfo={legalActionInfo?.outstate} noDataMessage={noDataMessage} />
      <CurrentLimitations
        currentLimitationsInfo={legalActionInfo?.limitations} noDataMessage={noDataMessage}
      />
      <HospitalPreveligeRestriction
        hospitalPreveligeInfo={legalActionInfo?.restriction} noDataMessage={noDataMessage}
      />
      <HospitalFaliure hospitaFaliureInfo={legalActionInfo?.resign} noDataMessage={noDataMessage} />
      <CriminalConvictions
        criminalConvictionInfo={legalActionInfo?.conviction} noDataMessage={noDataMessage}
      />
    </Styled>
  );
};

export default LegalActions;
