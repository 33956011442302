import * as client from '../axios';
import endpoint from '../endpoint';
import { API_PROFILE_PATH } from '../../utils/Config';

async function fetchStatementInfo(formData={},headers={}) {
    const options = {
        params: {
          ...formData
        },
        headers:{
          ...headers
        }
      };
      const response = await client.get(`${API_PROFILE_PATH}${formData.physicianId}?sections=STATEMENT`,options);
    return response.data;
}

export default endpoint(fetchStatementInfo, 'fetchStatementInfo');