import React from 'react';
import { useNavigate } from 'react-router-dom';
import Styled from './styles';



const GoBackButton = ({buttonContent}) => {
    const navigate = useNavigate();

    const goBackToPreviousPage = () => {
        navigate(-1);
    }

    return <Styled>
        <button type='button' className='btn-link physician-profile-btn' onClick={goBackToPreviousPage}>{buttonContent}</button>
    </Styled>

}

export default GoBackButton;