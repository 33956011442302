import React, {useEffect} from 'react';
import Styled from './styles';
import FieldOfMedicine from './FieldOfMedicine';
import HIVServices from './HIVServices';
import Offices from './Offices';
import LanguagesAvailable from './LanguagesAvailable';
import InsuranceProgram  from './InsuranceProgram';
import HealthPlans from './HealthPlans';
import HealthPrivileges from './HealthPrivileges';
import * as actions from '../../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";

const PracticeInfo = () => {
    const dispatch = useDispatch();

    const {state} = useLocation();
  const { physicianId } =state;
    const practiceInfo = useSelector(({practiceInfo}) => practiceInfo?.data?.data);
    const logger = useSelector(({ logger }) => logger?.data);
    const noDataMessage = useSelector(({physicianProfileInfo}) => physicianProfileInfo?.dataMessage);
    useEffect(() => {
        if (!logger?.sessionId) {
          dispatch(actions.fetchLogger());
        } else {
          dispatch(
            actions.fetchPracticeInfo(
              { physicianId: physicianId },
              { sessionId: logger?.sessionId }
            )
          );
        }
      }, [dispatch, logger?.sessionId, physicianId]);
    return (
        <Styled>
            <FieldOfMedicine medicalPractice={practiceInfo?.medicalPractice} noDataMessage={noDataMessage} />
            <HIVServices hiv={practiceInfo?.hiv} noDataMessage={noDataMessage} />
            <Offices practiceaddress={practiceInfo?.practiceaddress} noDataMessage={noDataMessage} />
            <LanguagesAvailable translationService={practiceInfo?.translationService} noDataMessage={noDataMessage} />
            <InsuranceProgram insuranceProgram={practiceInfo?.insuranceProgram} noDataMessage={noDataMessage} />
            <HealthPlans healthPlanContract={practiceInfo?.healthPlanContract} noDataMessage={noDataMessage} />
            <HealthPrivileges admittingPrivilege={practiceInfo?.admittingPrivilege} noDataMessage={noDataMessage} />
        </Styled>
    );
}

export default PracticeInfo;