import { get } from "../axios";
import endpoint from "../endpoint";
import { API_PATH } from '../../utils/Config';

async function fetchFaqs(headers) {
  let response;
  const options = {
    headers:{
      'content-type':'application/json',
      ...headers
    }
  };
    response = await get(`${API_PATH}/faqs/list`,options);
  
  return response?.data;
}

export default endpoint(fetchFaqs, "fetchFaqs");
