import {GET_STATEMENTS_REQUEST, 
    GET_STATEMENTS_SUCCESS, 
    GET_STATEMENTS_ERROR } from '../actions/type';


const fetchStatementsInfo = (formData={},headers={}) => async (dispatch, getState, api) => {
    dispatch(fetchStatementsRequest());

    return api.fetchStatementInfo(formData,headers).then(data => {
        dispatch(fetchStatementsSuccess(data));
    }).catch(err => {
        dispatch(fetchStatementsError(err));
    })
}

export default fetchStatementsInfo;

const fetchStatementsRequest = () => ({type: GET_STATEMENTS_REQUEST});

const fetchStatementsSuccess = (response) => ({
    type: GET_STATEMENTS_SUCCESS, 
    data: response,
    lastUpdated: Date.now(),
});

const fetchStatementsError = (error) => ({
    type: GET_STATEMENTS_ERROR,
    error,
    lastUpdated: Date.now(),
});