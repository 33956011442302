import {GET_FAQS_REQUEST, GET_FAQS_SUCCESS, GET_FAQS_ERROR} from './type';

const fetchFaqs = (headers={}) => (dispatch, getState, api) => {
    dispatch(fetchFaqsRequest());

    return api.fetchFaqs(headers).then(data => {
        dispatch(fetchFaqsSuccess(data));
    }).catch(err => {
        dispatch(fetchFaqsError(err))
    });
}

export default fetchFaqs;

const fetchFaqsRequest = () => ({
    type: GET_FAQS_REQUEST
});

const fetchFaqsSuccess = (response) => ({
    type: GET_FAQS_SUCCESS,
    data: response.data,
    lastUpdated: Date.now(),
});

const fetchFaqsError = (error) => ({
    type: GET_FAQS_ERROR,
    error,
    lastUpdated: Date.now(),
});