import styled from "styled-components/macro";

export default styled.div`
.physician-search{
    background-color: #F1F1F1;
    text-align: center;
}

.physician-wrapper {
    max-width: 800px;
    margin: 0 46px;
    @media only screen and (max-width: 480px) {
        margin: 0 8px;
    }

}
.search-physician-title{
    background-color: #235D86;
    padding: 65px 0;
    display: grid;
}
.physician-search div.search-for-a{
    color: #FFFFFF;
    font-size: 25px;
    font-weight: 800;
    font-family: 'Oswald' !important;
}
.physician-search div.physician{
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 800;
    line-height: 61px;
}
.right-angle{
    width: 0;
	height: 0;
	border-top: 114px solid transparent;
	border-left: 50px solid #235D86;
	border-bottom: 114px solid transparent;
}
.inline-block{
    display: flex;
}
.search-container{
    padding: 26px 10px;
    text-align: left;

    > .title {
        color: #235D86;
    }
}
.search-container span{
    font-size: 20px;
    font-weight: 800;
}
.search-container p{
    font-size: 16px;
    font-weight: 200;
}
.input-text{
    width: 92%;
    background-color: #FFFFFF;
}
.search-btn{
    padding: 12px 0 !important;
    margin: 0;
    border-radius: 0 !important;
    border: 2px solid #000000 !important;
    color: #000000 !important;
    font-weight: 700 !important;
    width: 90% !important;
}
.search-container a,.search-form a {
    color: #235D86;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
}
.padding10{
    padding: 10px;
}
@media only screen and (max-width: 991px) {

    .input-text{
        margin: 10px 0 !important;
    }
    .search-btn{
       width: 92% !important;
       margin: 10px 0 0 0 !important;
    }
    .search-form{
        padding: 0 0 10px 0 !important;
    }
   
}  
`;

