import React from 'react';
import SubPage from '../../Common/SubPage';
import BodyContent from '../../Common/BodyContent';
import HeaderImage from './HeaderImage';
import DictionaryList from './DictionaryList';
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styled from './styles';

const Dictionary = () => {
    const properties={
        titleBlack:'Dictionary',
      }
      
    return (
            <SubPage properties={properties}>
                <Styled>
                    <HeaderImage />
                    <BodyContent><DictionaryList /></BodyContent>
                </Styled>
            </SubPage>
    )
}

export default ScrollToTop(Dictionary);