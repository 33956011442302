import React, { useState } from "react";
import AdditionalInfoTabs from "./Tabs";
import EducationalInfo from "./EducationInfo";
import LegalActions from "./LegalActions";
import PracticeInfo from "./PracticeInfo";
import ProfessionalActivities from "./ProfessionalActivities";
import Statement from "./Statement";
import Styled from "./styles";

const AdditionalInfo = () => {
  const [value, setValue] = useState("education");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Styled>
      <AdditionalInfoTabs
        value={value}
        setValue={setValue}
        handleChange={handleChange}
      />
      {value === "education" && <EducationalInfo />}
      {value === "legalActions" && <LegalActions />}
      {value === "practiceInfo" && <PracticeInfo />}
      {value === "professionalActivities" && <ProfessionalActivities />}
      {value === "statement" && <Statement />}
    </Styled>
  );
};

export default AdditionalInfo;
