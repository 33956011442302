import SearchResultsData from "./SearchResultsData";
import SubPage from "../../Common/SubPage";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
const Physicians = () => {
  const properties = {
    titleBlack: "Results",
    titleBlue: "SEARCH",
  };
  return (
    <SubPage properties={properties}>
      <SearchResultsData />
    </SubPage>
  );
};
export default ScrollToTop(Physicians);
