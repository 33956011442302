import React from "react";
import { Button } from "@material-ui/core";
import SubPageHeading from "../../../../../Common/SubPageHeading";

const HospitalLinks = ({ hospitalList,county,handlerNavigation }) => {
 
  return (
    <>
    <SubPageHeading headingTitle={`Hospitals in ${county?'County':'New York'}`} instruction={`Click on a hospital name to select it.`} />
      <div className="paddingTop20px"></div>
      {hospitalList?.map((hospitalObj) => (
        <>
          <Button
            className="colorBlue font16px textUnderlined padding0px"
            onClick={(e) => handlerNavigation(hospitalObj)}
          >
            {hospitalObj?.hospitalName}
          </Button>
          <br></br>
        </>
      ))}
    </>
  );
};
export default HospitalLinks;
