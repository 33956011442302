import React from "react";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import AdvancedSearchForm from "./AdvancedSearchForm";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styled from "./styles";
const AdvancedSearch = () => {
  const properties = {
    titleBlue: "ADVANCED",
    titleBlack: "Search",
  };

  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          <div className="form-heading">
           
            <AdvancedSearchForm />
          </div>
        </BodyContent>
      </Styled>
    </SubPage>
  );
};
export default ScrollToTop(AdvancedSearch);
