import React, { useEffect, useState } from "react";
// import nyStateMap from "./Maps/mapcities500.jpg";  
import nyStateMap from "./Maps/ny_county_map_new.png"; 
import nyCityMap from "./Maps/newyorkcity.gif";
import nyIslandMap from "./Maps/longisland.gif";
import { useNavigate, useLocation } from "react-router-dom";
import SubPage from "../../../Common/SubPage";
import BodyContent from "../../../Common/BodyContent";
import Styled from "./styles";
import ScrollToTop from "../../../Common/HOC/ScrollToTop";
import SubPageHeading from "../../../Common/SubPageHeading";
import ReturnToPage from "../../../Common/ReturnToPage";

const MapSelect = () => {
  const [coordsData, setCoordsData] = useState([]);
  const [activeNymcMap, setActiveNymcMap] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { pageType, prevPageState } = state;

  useEffect(() => {
    fetch("/json_data/countyMap.json")
      .then((response) => response.json())
      .then((data) => {
        setCoordsData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateData = (dataObj) => {
    const { CountyFipsCodeParam } = dataObj;
    if (CountyFipsCodeParam === "NYMAC") {
      setActiveNymcMap(true);
    } else {
      if (pageType === "county") {
        navigate("/advanced-search", {
          state: {
            prevPageState: {
              ...prevPageState,
              selectedCounty: CountyFipsCodeParam,
            },
            updateState: true,
          },
        });
      } else if (pageType === "hospital") {
        navigate("/advanced-search/hospitals", {
          state: { prevPageState: prevPageState, dataObj },
        });
      }
    }
  };

  const createClickableMaps = (img, imgAlt, mapName, dataSet) => {
    return (
      <>
        <img src={img} alt={imgAlt} useMap={`#${mapName}`} />{" "}
        <map name={mapName}>
          {dataSet?.length > 0 &&
            dataSet?.map((value) => (
              <area
                shape="poly"
                coords={value?.coords}
                alt={value?.alt}
                style={{ cursor: "pointer" }}
                onClick={() => updateData(value)}
              />
            ))}
        </map>
      </>
    );
  };

  const { nyState, nyCity, nyIsland } = coordsData;
  const properties = {
    titleBlue: "ADVANCED",
    titleBlack: "Search",
  };

  let headingTitle = "";
  let instruction = "";
  if (!activeNymcMap) {
    headingTitle = "New York State";
  } else {
    headingTitle = "New York Metropolitan Area";
  }
  if (pageType === "hospital") {
    instruction =
      "Click on a county to see a list of hospitals within that county. Point to a county to see its name.";
  } else {
    instruction =
      "Click on a county or borough to select and return that county to the search page .";
  }

  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          <SubPageHeading
            headingTitle={headingTitle}
            instruction={instruction}
          />
          <div className="paddingTop20px"></div>
          {!activeNymcMap &&
            createClickableMaps(nyStateMap, "countyMap", "workmap", nyState)}
          {activeNymcMap && (
            <>
              {createClickableMaps(
                nyCityMap,
                "cityCountyMap",
                "countycitymap",
                nyCity
              )}
              {createClickableMaps(
                nyIslandMap,
                "islandCountyMap",
                "countyislandmap",
                nyIsland
              )}
            </>
          )}
          <div className="paddingTop30px"></div>
          <ReturnToPage
            linkPath={"/advanced-search"}
            linkText={"Return to Search"}
            stateData={{ prevPageState: prevPageState, updateState: true }}
          />
        </BodyContent>
      </Styled>
    </SubPage>
  );
};

export default ScrollToTop(MapSelect);
