import styled from "styled-components/macro";

export default styled.div`
margin-bottom: 50px;
.parent-container {
    margin-bottom: 10px;
    .MuiTypography-root {
        color: #235D86;
        font-weight: bold;
        font-size: 20px;
    }

    .child-container {
        padding-left: 25px;
        display: flex;
        flex-direction: column;
        .MuiTypography-root {
            color: unset;
            font-weight: unset;
            font-size: unset;
        }
       
    }
}
`;