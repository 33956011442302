import styled from "styled-components/macro";
import img from "./Img/hero_img.png";
import imgMobile from "./Img/hero_img_mobile.png";

export default styled.div`
  .banner-container {
    width: 100%;
    height: 401px;
    position: relative;
  }

  .banner-box {
    z-index: -1000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${img});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .left-banner-text {
    width: 510px;
    padding: 31px 52px 18px 52px;
    background: linear-gradient(90deg, white 80%, transparent);
  }

  .ny-state-text {
    color: #000000;
    font-size: 3.125rem;
    font-weight: 800;
    line-height: 3.375rem;
  }

  .welcome-text {
    font-size: 25px;
    color: #00678a;
    font-weight: 800;
    font-family: "Oswald" !important;
  }

  .left-banner-text p {
    z-index: 100;
    padding-top: 15px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }

  @media only screen and (min-width: 992px) {
    .left-banner-text {
      height: 401px;
    }
  }

  @media only screen and (max-width: 480px) {
    .left-banner-text {
      width: auto;
      padding: 20px;
    }

    .banner-container {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }
    .hero-image-banner {
      height: auto;
    }
    .banner-box {
      height: 120px;
      position: relative;
      margin: 0;
      padding: 0;
      background-image: url(${imgMobile});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .ny-state-text {
      font-size: 2.5rem;
    }
  }
`;
