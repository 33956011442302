import {
    GET_ANALYTICS_URL_DATA_REQUEST,
    GET_ANALYTICS_URL_DATA_SUCCESS,
    GET_ANALYTICS_URL_DATA_ERROR
  } from "../actions/type";
  
  const initialState = {
    loading: null,
    error: null,
    data: null,
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_ANALYTICS_URL_DATA_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_ANALYTICS_URL_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      case GET_ANALYTICS_URL_DATA_ERROR:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };