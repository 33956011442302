import "./Styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const BackToTop = () => {

    const [hidden, setHidden] = useState(true);

    window.addEventListener("scroll", () => {
        let {scrollTop} = document.documentElement;

        if(scrollTop > 500) {
            setHidden(false);
        }
        else {
            setHidden(true);
        }
    })

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (<>
        {!hidden && <button
            className="back-to-top-btn"
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={faAngleUp} className="back-to-top-btn-icon" />
            <br />
            <span>Back <br />to Top</span>
        </button>}
    </>);
}

export default BackToTop;