import React, { useEffect, useState } from "react";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styled from "./styles";
const SearchTips = () => {
  const [searchTips, setSearchTips] = useState([]);
  const properties = {
    titleBlack: "Search Tips",
  };
  useEffect(() => {
    fetch("/json_data/searchTips.json").then((data) => {
      data.json().then((jsonData) => {
        setSearchTips(jsonData);
      });
    });
  }, []);
  return (
    <SubPage properties={properties}>
      <Styled>
        <BodyContent>
          <p className="info-text">
            To get the best results, use these tips for your search. If you have
            tried these tips and can not find a particular doctor, please call
            the <strong>Physician Profile Help Desk</strong> at{" "}
            <strong>1-888-338-6999</strong> for assistance.
          </p>
          <div className="search-tip-container">
            {searchTips.map(({ key, search, description }) => (
              <div key={key} className="search-tip-list-div">
                <h2><div className="search-term">{search}</div></h2>
                <div className="search-sol">
                  <ul>
                    {description.map((value) => (
                      <li>{value}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </BodyContent>
      </Styled>
    </SubPage>
  );
};
export default ScrollToTop(SearchTips);
