import React from 'react';
import CheckboxComponent from './Checkbox';
import Styled from './styles';

const CheckboxContainer = ({ selectedItems, setSelectedItems, checkboxStructure}) => {

    const handleChange = (checked, item, parent) => {
        const updatedSelection = {...selectedItems, [item.name]: checked};
        item.childrens && item.childrens.forEach(c => {
            updatedSelection[c.name] = checked;
        })

        if(parent && parent.childrens.every(p => updatedSelection[p.name])) {
            updatedSelection[parent.name] = true;
        } else if(parent && !parent.childrens.every(p => updatedSelection[p.name])) {
            updatedSelection[parent.name] = false;
        }

        setSelectedItems({...updatedSelection})
    }

    const isIndeterminate = (items) => {
        let t = [];
        items && items.forEach(item => {
            if(!t.includes(selectedItems[item.name])) {
                t.push(selectedItems[item.name])
            }
        })
        return t.length > 1;
    }

    return (
        <Styled>
            {checkboxStructure.map(item => (
                <div className='parent-container'>
                    <CheckboxComponent key={item.name} name={item.name} label={item.label} checked={!!selectedItems[item.name]} 
                    indeterminate={isIndeterminate(item.childrens)} handleChange={(e) => handleChange(e.target.checked, item)} />

                    <div className='child-container'>
                        {item.childrens && item.childrens.map(children => (
                            <CheckboxComponent key={children.name} name={children.name} label={children.label} checked={!!selectedItems[children.name]} 
                            indeterminate={false} handleChange={(e) => handleChange(e.target.checked, children, item)} />
                        ))}
                    </div>
                </div>
                
            ))}
        </Styled>
    )
}

export default CheckboxContainer;