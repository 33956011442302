import React from 'react';
import Styled from './styles';
import TitleHeader from '../TitleHeader';
import Table from '../../Table';

const ProfessionalMembership = ({profMember=[], noDataMessage}) => {
    const columns = [
        {name: 'membershipOrganization', label: 'Organizations'}
    ]

    let updatedDataMessage = noDataMessage;

    if(!updatedDataMessage || !(updatedDataMessage === 'Profile under Development' || 
    updatedDataMessage === "Dr.'s survey not received")) {
        updatedDataMessage = 'The physician did not supply this information';
    }

    return (
        <Styled>
            <TitleHeader label="Professional Memberships (Optional)" linkType="internal" linkPath="/dictionary#17" lastUpdatedDate={profMember[0]?.informationDate}  />
            {
               profMember && profMember.length > 0 ? (
                <Table columns={columns} data={profMember} />
               ) : (<p className='no-content'>{updatedDataMessage}</p>)
               
            }
            <hr />
            {profMember && profMember.length > 0 && (<p>
                <strong>Note:</strong> Professional Memberships information continues to be collected and reviewed. You should check periodically to see if information has been added or updated. 
            </p>)}
            
        </Styled>
    );
}

export default ProfessionalMembership;