import React from "react";
import Styled from "./styles";
import TitleHeader from "../../EducationInfo/TitleHeader";
import sub from "date-fns/sub";
import { format } from "date-fns";

const LiscenseeActions = ({ physicianInfo, printMessage = false }) => {
  let lastName = physicianInfo?.lastName || "";
  let liscenseNumber = physicianInfo?.licenseNumber || "";
  let cutOff = format(sub(new Date(), { years: 10 }), "M/d/yyyy");

  return (
    <Styled>
      <TitleHeader label="NY Licensee Actions" />
      {!printMessage && (
        <p>
          The Department of Health Office of Professional Medical Conduct (OPMC)
          is responsible for investigating allegations of misconduct against
          physicians. The State Board for Professional Medical Conduct is
          authorized under Public Health Law to take action on a doctor's
          medical license if the doctor is found to be guilty of Professional
          Misconduct. If you would like to see if there have been any license
          actions taken against this doctor over the past 10 years, you may
          click here:{" "}
          <a
            target="opmc_window"
            href={`https://apps.health.ny.gov/pubdoh/professionals/doctors/conduct/factions/ByPhysician?SearchView&Query=(FIELD+PhysicianName=${lastName}+AND+FIELD+WebLicense=${liscenseNumber}+AND+FIELD+EffectiveDateFrom=${cutOff})`}
          >
            OPMC
          </a>
          . This will take you to the OPMC website. If there have not been any
          license actions against this doctor in New York over the past ten
          years, the OPMC screen will tell you that. When you want to return to
          this Physician Profile you must click on the X in the upper right
          corner of the OPMC window.
        </p>
      )}
      {printMessage && (
        <p className="lactions">
          Any actions taken against a physician's license to practice medicine
          in New York State are not included in this printout. Printed copies of
          disciplinary actions are available by following the "OPMC" link on the
          Legal Actions section of the website.
        </p>
      )}

      <hr />
    </Styled>
  );
};
export default LiscenseeActions;
