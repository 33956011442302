import React, { useState, useEffect } from "react";
import SubPage from "../../Common/SubPage";
import { MdOutlineArrowBack } from "react-icons/md";
import BodyContent from "../../Common/BodyContent";
import GoBackButton from "../PhysicianProfile/GoBackButton";
import CheckboxContainer from "./CheckboxContainer";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import Styled from "./styles";
import { getEffectiveDateFrom } from "../../../utils/Utils";

const SelectToPrint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationType = useNavigationType();
  const setSelectedItemsToPrint = useSelector(
    ({ setSelectedItemsToPrint }) => setSelectedItemsToPrint
  );
  const itemStructure = [
    {
      name: "officeInfoOnly",
      label: "Office Information Only",
    },
    {
      name: "allInfo",
      label: "All information about the physician",
    },
    {
      name: "educationSection",
      label: "All of Education Section",
      childrens: [
        {
          name: "medicalSchool",
          label: "Medical School",
        },
        {
          name: "graduateMedEdu",
          label: "Graduate Medical Education",
        },
        {
          name: "boardCertification",
          label: "Board Certifications",
        },
        {
          name: "professionalMembership",
          label: "Professional Membership",
        },
      ],
    },
    {
      name: "practiceInfoSection",
      label: "All of Practice Info Section",
      childrens: [
        {
          name: "fieldOfPractice",
          label: "Field of Medicine",
        },
        {
          name: "hivServices",
          label: "HIV Services",
        },
        {
          name: "offices",
          label: "Offices",
        },
        {
          name: "translationServices",
          label: "Languages Available",
        },
        {
          name: "govtInsuranceProg",
          label: "Medicaid and Other Government Insurance Programs",
        },
        {
          name: "healthPlans",
          label: "Health Plans",
        },
        {
          name: "hospitalPriveleges",
          label: "Hospital Privileges",
        },
      ],
    },
    {
      name: "legalActionsSection",
      label: "All of Legal Actions Section",
      childrens: [
        {
          name: "malpractice",
          label: "Malpractice",
        },
        {
          name: "liscenseeActions",
          label: "NY Licensee Actions",
        },
        {
          name: "outOfStateActions",
          label: "Out-of-State Actions",
        },
        {
          name: "currentLimitations",
          label: "Current Limitations or Conditions",
        },
        {
          name: "hospitalRestictions",
          label: "Hospital Privilege Restrictions",
        },
        {
          name: "hospitalFailure",
          label: "Hospital Failure to Renew Privileges",
        },
        {
          name: "criminalConvictions",
          label: "Criminal Convictions",
        },
      ],
    },
    {
      name: "professionalActivitiesSection",
      label: "All of Professional Activities Section",
      childrens: [
        {
          name: "academicAppointments",
          label: "Teaching Responsibilities",
        },
        {
          name: "communityServiceActivities",
          label: "Professional and Community Service Activities",
        },
        {
          name: "publications",
          label: "Publications",
        },
      ],
    },
    {
      name: "statement",
      label: "Statement",
    },
  ];
  const { licenseNumber, lastName } = useSelector(
    ({ physicianProfileInfo }) => physicianProfileInfo
  );
  const [selectedItems, setSelectedItems] = useState({});
  const { state } = useLocation();
  if (!state) {
    window.location.href = window.location.origin;
  }
  const { physicianId } = state;
  const properties = {
    titleBlue: "SELECT ITEMS TO",
    titleBlack: "Print",
  };
  const goToPrinterFriendlyPage = () => {
    dispatch(actions.setSelectedItemsToPrint(selectedItems));
    navigate("/printer-friendly", { state: { physicianId: physicianId } });
  };

  useEffect(() => {
    if (navigationType === "POP") {
      setSelectedItems({ ...setSelectedItemsToPrint });
    }
  }, [setSelectedItemsToPrint, navigationType]);

  return (
    <SubPage properties={properties}>
      <BodyContent>
        <Styled>
          <GoBackButton
            buttonContent={
              <>
                <MdOutlineArrowBack />
                <span>Go Back to Previous Page</span>
              </>
            }
          />
          <div className="content">
            <p>
              Check the box next to the information you want to print. Print it
              out by clicking the "
              <span className="colored-text">
                <strong>Printer Friendly Page</strong>
              </span>
              " button.
            </p>
            <p>
              Any actions taken against a physician's license to practice
              medicine in New York State are not included in this printout. You
              may click here (
              <a
                href={`https://apps.health.ny.gov/pubdoh/professionals/doctors/conduct/factions/ByPhysician?SearchView&Query=(FIELD+PhysicianName=${lastName}+AND+FIELD+WebLicense=${licenseNumber}+AND+FIELD+EffectiveDateFrom=${getEffectiveDateFrom()})`}
                target="opmc_window"
              >
                OPMC site
              </a>
              ) for copies of disciplinary actions found on the{" "}
              <strong>Legal Actions</strong> section of the website.
            </p>
          </div>
          <CheckboxContainer
            checkboxStructure={itemStructure}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
          <button
            className="btn printer-friendly-btn"
            onClick={goToPrinterFriendlyPage}
          >
            PRINTER FRIENDLY PAGE
          </button>
        </Styled>
      </BodyContent>
    </SubPage>
  );
};

export default ScrollToTop(SelectToPrint);
