import React, { useEffect, useState } from "react";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import Styled from "./styles";
import BannerImg from "./BannerImg";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import { fetchDataCommon } from "../../../api/endpoints";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions";
const Contact = () => {
  const properties = {
    titleBlack: "Contact Us",
  };
  const dispatch = useDispatch();
  const logger = useSelector(({ logger }) => logger?.data);
  const [contactUsData, setContactUsData] = useState();
  useEffect(() => {
    const getContactUsApiData = async (parmaData = {}) => {
      const response = await fetchDataCommon("/contactUs", parmaData, {
        sessionId: logger?.sessionId,
      });
      if (!response?.data) {
        throw new Error("Data coud not be fetched!");
      } else {
        setContactUsData(response?.data);
      }
    };
    if (!logger?.sessionId) {
      dispatch(actions.fetchLogger());
    } else {
      getContactUsApiData();
    }
  },[dispatch,logger?.sessionId]);
  return (
    <SubPage properties={properties}>
      <BannerImg />
      <Styled>
        <BodyContent>
          <div className="call-us">
            If you have questions, call us at{" "}
            <a href={`tel:${contactUsData?.phone}`}>{contactUsData?.phone}.</a>
            <br></br>
            The call is free.
          </div>
          <div className="call-time">
            Call Monday to Friday, {contactUsData?.oHours} to {contactUsData?.cHours}, Eastern Time.
          </div>
        </BodyContent>
      </Styled>
    </SubPage>
  );
};
export default ScrollToTop(Contact);
